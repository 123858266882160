import React, { useEffect, useState } from 'react';
import Footer from '../Footer';
import Header from '../Header';
import Sidebar from '../Sidebar';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useParams } from 'react-router-dom';
import  axiosInstance  from '../../axiosConfig';
import Dashboard from '../dashboard/Dashboard';
import Spinner from '../spinner.gif';


function LawnEdit() {
  const { id } = useParams();
  const [name, setName] = useState('');
  const [fora, setFora] = useState(''); // Change variable name
  const [type, setType] = useState('');
  const [address, setAddress] = useState('');
  const [contact, setContact] = useState('');
  const [capacity, setCapacity] = useState('');
  const [parking, setParking] = useState('');
  const [kitchen, setKitchen] = useState('');
  const [details, setDetails] = useState('');
  const [image, setImage] = useState(null);
  const [video, setVideo] = useState(null);
  const [userid, setUserid] = useState('');

  const [formErrors, setFormErrors] = useState({}); // Initialize form error state
  const [loading, setLoading] = useState(false);
  const token = localStorage.getItem('uid');

  function handleImageChange(e) {
    setImage(e.target.files[0]);
  }


  

  const config = {
    headers: { 'Content-Type': 'multipart/form-data' ,'Authorization':"Bearer "+token}
  };
  


  function fetchAddOnData() {
    axios
      .get(`https://api.bookmyfunctionhall.com/api/Lawn/${id}`,config)
      .then((res) => {
        const data = res.data;
        setName(data.name);
        setFora(data.for);
        setType(data.type);
        setAddress(data.address);
        setContact(data.contact);
        setCapacity(data.capacity);
        setParking(data.parking);
        setKitchen(data.kitchen);
        setDetails(data.details);
        setVideo(data.video);
        setUserid(data.userid);
        setImage(data.image);
      })
      .catch((error) => {
        //console.log('Error', error);
      });
  }




  
  function validateForm() {
    const errors = {};



    if (!type) {
      errors.type = 'type is required ';
    }

    if (!video) {
      errors.video = 'video is required ';
    }

    if (!name || !/^[a-zA-Z\s]+$/.test(name)) {
      errors.name = 'Name is required and should contain only letters';
    }

    if (!contact || !/^\d{10}$/.test(contact)) {
      errors.contact = 'Contact is required and should be a 10-digit number';
    }

    if (!address) {
      errors.address = 'Address is required';
    }

  
    if (!fora) {
      errors.fora = 'Description is required';
    }
    if (!image) {
      errors.image = 'image is required';
    }
  
  if (!capacity) {
    errors.capacity = 'capacity is required';
  }




    setFormErrors(errors);

    return Object.keys(errors).length === 0;
  }




  function updateAddOn(e) {
    e.preventDefault();

      
    if (validateForm()) {

      setLoading(true);

    const formData = new FormData();
    formData.append('_method', 'put');
    formData.append('name', name);
    formData.append('for', fora);
    formData.append('details', details);
    formData.append('type', type);
    formData.append('address', address);
    formData.append('contact', contact);
    formData.append('capacity', capacity);
    formData.append('parking', parking);
    formData.append('kitchen', kitchen);
    formData.append('image', image); // Use 'image' instead of 'images'
    formData.append('video', video); // Use 'video' instead of 'video'
    formData.append('userid', userid);
    
    axios
      .post(`https://api.bookmyfunctionhall.com/api/Lawn/${id}`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' ,'Authorization':"Bearer "+token}
      })
      .then((resp) => {
        const data = resp.data;
        if (data.status === 'success') {
          //console.log('Data Updated');
          toast.success('Data Updated!!!', { autoClose: 2000 });
          window.location.href = '/lawnindex';
        } else {
          //console.log('Error data:', resp.data);
          toast.error('Error occurred while updating data', { autoClose: 2000 });
         // window.location.href = '/lawnIndex';
        }
      })
      .catch((error) => {
        //console.log('Errorrr:', error);
        toast.error('An error occurred while making the request', { autoClose: 2000 });
      });
  }
  }
  useEffect(() => {
    fetchAddOnData();
    const idd = localStorage.getItem('id');
    if (idd) {
      setUserid(idd); 
    }
  }, []);

  
  return (
    <React.Fragment>
       <ToastContainer />
      <div className="layout-wrapper layout-content-navbar">
        <div className="layout-container">
          {/* <Sidebar /> */}
          <Dashboard/>
          <div className="layout-page">
            <Header />
            <div className="content-wrapper">
              <div className="container-xxl flex-grow-1 container-p-y">
                <h4 className="fw-bold py-3 mb-4">
                  <span className="text-muted fw-light">Lawn / Edit</span> 
                </h4>
                <div className="row">
                  <div className="col-xl">
                    <div className="card mb-4 col-md-6">
                      <div className="card-header d-flex justify-content-between align-items-center">
                        <h5 className="mb-0">Lawn Edit</h5>
                      </div>
                      <div className="card-body">
                 






                      <form>








                      <div className="mb-3">
     
     <div className="input-group input-group-merge">
      
       <input
         type="hidden"
         name="userid"
         className="form-control"
         id="basic-icon-default-fullname"
         placeholder="John Doe"
         aria-label="John Doe"
         aria-describedby="basic-icon-default-fullname2"
         onChange={(event) => setUserid(event.target.value)}
         value={userid} // Set the initial value to the "userid" state
         readOnly
       />
     </div>
   </div>
                        <div className="mb-3">
                          <label className="form-label" for="basic-icon-default-fullname">Name</label>
                          <div className="input-group input-group-merge">
                            <span id="basic-icon-default-fullname2" className="input-group-text"
                              ><i className="bx bx-user"></i
                            ></span>
                            <input
                              type="text"
                              name="name"
                              className={`form-control ${formErrors.name ? 'is-invalid' : ''}`}
                              id="basic-icon-default-fullname"
                              placeholder="John Doe"
                              aria-label="John Doe"
                              aria-describedby="basic-icon-default-fullname2"
                              onChange={name=>setName(name.target.value)} value={name}
                            />
                             {formErrors.name && (
                                <div className="invalid-feedback">{formErrors.name}</div>
                              )}

                          </div>
                        </div>




                    
                        <div className="mb-3">
                          <label className="form-label" for="basic-icon-default-fullname">For</label>
                          <div className="input-group input-group-merge">
                            <span id="basic-icon-default-fullname2" className="input-group-text"
                              ><i className="bx bx-user"></i
                            ></span>
                            <input
                              type="text"
                              name="for"
                              className={`form-control ${formErrors.fora ? 'is-invalid' : ''}`}
                              id="basic-icon-default-fullname"
                              placeholder="John Doe"
                              aria-label="John Doe"
                              aria-describedby="basic-icon-default-fullname2"
                              onChange={fora=>setFora(fora.target.value)} value={fora}
                            />
                             {formErrors.fora && (
                                <div className="invalid-feedback">{formErrors.afor}</div>
                              )}

                          </div>
                        </div>








                        


  
                        <div className="mb-3">
                          <label className="form-label" for="basic-icon-default-fullname">type</label>
                          <div className="input-group input-group-merge">
                            <span id="basic-icon-default-fullname2" className="input-group-text"
                              ><i className="bx bx-user"></i
                            ></span>
                            <input
                              type="text"
                              name="type"
                              className={`form-control ${formErrors.type ? 'is-invalid' : ''}`}
                              id="basic-icon-default-fullname"
                              placeholder="John Doe"
                              aria-label="John Doe"
                              aria-describedby="basic-icon-default-fullname2"
                              onChange={type=>setType(type.target.value)} value={type}
                            />
                             {formErrors.type && (
                                <div className="invalid-feedback">{formErrors.type}</div>
                              )}

                          </div>
                        </div>









                  







                        <div className="mb-3">
                          <label className="form-label" for="basic-icon-default-fullname">parking</label>
                          <div className="input-group input-group-merge">
                            <span id="basic-icon-default-fullname2" className="input-group-text"
                              ><i className="bx bx-user"></i
                            ></span>
                            <input
                              type="text"
                              name="parking"
                              className={`form-control ${formErrors.parkingcap ? 'is-invalid' : ''}`}
                              id="basic-icon-default-fullname"
                              placeholder="John Doe"
                              aria-label="John Doe"
                              aria-describedby="basic-icon-default-fullname2"
                              onChange={parking=>setParking(parking.target.value)} value={parking}
                            />
                             {formErrors.parkingcap && (
                                <div className="invalid-feedback">{formErrors.parkingcap}</div>
                              )}


                          </div>
                        </div>





                        <div className="mb-3">
                          <label className="form-label" for="basic-icon-default-fullname">address</label>
                          <div className="input-group input-group-merge">
                            <span id="basic-icon-default-fullname2" className="input-group-text"
                              ><i className="bx bx-user"></i
                            ></span>
                            <input
                              type="text"
                              name="address"
                              className={`form-control ${formErrors.address ? 'is-invalid' : ''}`}
                              id="basic-icon-default-fullname"
                              placeholder="John Doe"
                              aria-label="John Doe"
                              aria-describedby="basic-icon-default-fullname2"
                              onChange={address=>setAddress(address.target.value)} value={address}
                            />
                               {formErrors.address && (
                                <div className="invalid-feedback">{formErrors.address}</div>
                              )}

                          </div>
                        </div>


                        <div className="mb-3">
                          <label className="form-label" for="basic-icon-default-fullname">capacity</label>
                          <div className="input-group input-group-merge">
                            <span id="basic-icon-default-fullname2" className="input-group-text"
                              ><i className="bx bx-user"></i
                            ></span>
                            <input
                              type="text"
                              name="capacity"
                              className={`form-control ${formErrors.capacity ? 'is-invalid' : ''}`}
                              id="basic-icon-default-fullname"
                              placeholder="John Doe"
                              aria-label="John Doe"
                              aria-describedby="basic-icon-default-fullname2"
                              onChange={capacity=>setCapacity(capacity.target.value)} value={capacity}
                            />
                            {formErrors.capacity && (
                                <div className="invalid-feedback">{formErrors.capacity}</div>
                              )}

                          </div>
                        </div>


                     


  
                        <div className="mb-3">
                          <label className="form-label" for="basic-icon-default-fullname">kitchen</label>
                          <div className="input-group input-group-merge">
                            <span id="basic-icon-default-fullname2" className="input-group-text"
                              ><i className="bx bx-user"></i
                            ></span>
                            <input
                              type="text"
                              name="kitchen"
                              className="form-control"
                              id="basic-icon-default-fullname"
                              placeholder="Type Available or Not Available"
                              aria-label="Type Available or Not Available"
                              aria-describedby="basic-icon-default-fullname2"
                              onChange={kitchen=>setKitchen(kitchen.target.value)} value={kitchen}
                            />

                          </div>
                        </div>   

  

                        <div className="mb-3">
                          <label className="form-label" for="basic-icon-default-fullname">contact</label>
                          <div className="input-group input-group-merge">
                            <span id="basic-icon-default-fullname2" className="input-group-text"
                              ><i className="bx bx-user"></i
                            ></span>
                            <input
                              type="text"
                              name="contact"
                              className="form-control"
                              id="basic-icon-default-fullname"
                              placeholder="Type Available or Not Available"
                              aria-label="Type Available or Not Available"
                              aria-describedby="basic-icon-default-fullname2"
                              onChange={contact=>setContact(contact.target.value)} value={contact}
                            />

                          </div>
                        </div>     









                             

                        




                        <div className="mb-3">
                          <label className="form-label" for="basic-icon-default-fullname">details</label>
                          <div className="input-group input-group-merge">
                            <span id="basic-icon-default-fullname2" className="input-group-text"
                              ><i className="bx bx-user"></i
                            ></span>
                            <input
                              type="text"
                              name="details"
                              className="form-control"
                              id="basic-icon-default-fullname"
                              placeholder=""
                              aria-label="John Doe"
                              aria-describedby="basic-icon-default-fullname2"
                              onChange={details=>setDetails(details.target.value)} value={details}
                            />

                          </div>
                        </div>     



                    

                        <div className="mb-3">
                          <label className="form-label" for="basic-icon-default-fullname">video</label>
                          <div className="input-group input-group-merge">
                            <span id="basic-icon-default-fullname2" className="input-group-text"
                              ><i className="bx bx-user"></i
                            ></span>
                            <input
                              type="text"
                              name="video"
                              className={`form-control ${formErrors.contact ? 'is-invalid' : ''}`}
                              id="basic-icon-default-fullname"
                              placeholder="John Doe"
                              aria-label="John Doe"
                              aria-describedby="basic-icon-default-fullname2"
                              onChange={video=>setVideo(video.target.value)} value={video}
                            />
                                {formErrors.contact && (
                                <div className="invalid-feedback">{formErrors.contact}</div>
                              )}


                          </div>
                        </div>     













                
                
                        <div className="mb-3">
                          <label className="form-label" for="basic-icon-default-email">Image</label>
                          <div className="input-group input-group-merge">
                            <span className="input-group-text"><i className="bx bx-envelope"></i></span>
                            <input
                              type="file"
                              name="image"
                              id="image"
                              className={`form-control ${formErrors.image ? 'is-invalid' : ''}`}

                              accept="image/*" 
                              onChange={handleImageChange}
                            />
                            {formErrors.image && (
                                <div className="invalid-feedback">{formErrors.image}</div>
                              )}
                            
                          </div>
                          
                        </div>





                        <button type="submit" onClick={updateAddOn} className="btn btn" style={{ backgroundColor: "rgb(127, 1, 75)", color: "#fff" }} disabled={loading}>
                  {loading ? (
                    <img src={Spinner} alt="spinner" style={{ width:"30px" }} />
                  ) : (
                    'Update'
                  )}
                </button>

                     
                      </form>




                      
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Footer />
              <div className="content-backdrop fade"></div>
            </div>
          </div>
        </div>
        <div className="layout-overlay layout-menu-toggle"></div>
      </div>

      <div className="buy-now">
        <a
          href="https://themeselection.com/products/sneat-bootstrap-html-admin-template/"
          target="_blank"
          className="btn btn-danger btn-buy-now"
        >
          Upgrade to Pro
        </a>
      </div>
    </React.Fragment>
  );
}

export default LawnEdit;

