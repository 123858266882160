import React, { useEffect } from 'react'
 import { useNavigate } from 'react-router-dom';

function Sidebar() {
  // const navigate = useNavigate();
  // const token = localStorage.getItem('uid');
  // const usertype = localStorage.getItem('usertype');
  // const name = localStorage.getItem('name');
  // const email = localStorage.getItem('email');

  // console.log(name);
  // useEffect(() => {
  //   if (!token) {
  //     navigate('/logintoken');
  //   }
  //   else if (usertype === 'admin') {
  //     navigate('/dashboard');
  //     window.location.href="/dashboard"
  //   }


  //   else if (usertype === 'vendor') {
  //     navigate('/vendor');
  //     window.location.href="/vendor"
  //   } else {
  //     console.error("Invalid usertype:", usertype);
  //   }
  // }, [token, usertype, navigate]);


  return (
    <React.Fragment>
          <aside
  id="layout-menu"
  className="layout-menu menu-vertical menu bg-menu-theme"
>
  <div className="app-brand demo">
    <a href="/admin-dashboard" className="app-brand-link">
      <span className="app-brand-logo demo">
      <img src="/logo-dash.png" alt="" style={{ width:"180px"}} />
    
       
      </span>
    
    </a>
    <a
  href="javascript:void(0);"
  classname="layout-menu-toggle menu-link text-large ms-auto d-block d-xl-none"
>
      
    </a>
  </div>
  <div className="menu-inner-shadow" />
  <ul className="menu-inner py-1">
    <li className="menu-item">
      <a href="/" className="menu-link">
        <i className="menu-icon tf-icons bx bx-home-circle" />
        <div data-i18n="Analytics">Admin Dashboard</div>
      </a>
    </li>
    <li className="menu-item">
      {/* <a href="javascript:void(0);" className="menu-link menu-toggle">
        <i className="menu-icon tf-icons bx bx-layout" />
        <div data-i18n="Layouts">Layouts</div>
      </a> */}
      <ul className="menu-sub">
        <li className="menu-item">
          <a href="layouts-without-menu.html" className="menu-link">
            <div data-i18n="Without menu">Without menu</div>
          </a>
        </li>
        <li className="menu-item">
          <a href="layouts-without-navbar.html" className="menu-link">
            <div data-i18n="Without navbar">Without navbar</div>
          </a>
        </li>
        <li className="menu-item">
          <a href="layouts-container.html" className="menu-link">
            <div data-i18n="Container">Container</div>
          </a>
        </li>
        <li className="menu-item">
          <a href="layouts-fluid.html" className="menu-link">
            <div data-i18n="Fluid">Fluid</div>
          </a>
        </li>
        <li className="menu-item">
          <a href="layouts-blank.html" className="menu-link">
            <div data-i18n="Blank">Blank</div>
          </a>
        </li>
      </ul>
    </li>
    <li className="menu-header small text-uppercase">
      <span className="menu-header-text">Admin Columns</span>
    </li>




    
<li className="menu-item">
      <a href="/enquiryvendor" className="menu-link menu-toggle">
        <i className="menu-icon tf-icons bx bx-dock-top" />
        <div data-i18n="Account Settings">Booking</div>
      </a>
      <ul className="menu-sub">
        <li className="menu-item">
          <a href="/enquiryvendor" className="menu-link">
            <div data-i18n="Account">Booking</div>
          </a>
        </li>
        <li className="menu-item">
          <a
            href="pages-account-settings-notifications.html"
            className="menu-link"
          >
            <div data-i18n="Notifications">Notifications</div>
          </a>
        </li>
        <li className="menu-item">
          <a
            href="pages-account-settings-connections.html"
            className="menu-link"
          >
            <div data-i18n="Connections">Connections</div>
          </a>
        </li>
      </ul>
    </li>

    
    <li className="menu-item">
      <a href="/adminindex" className="menu-link menu-toggle">
        <i className="menu-icon tf-icons bx bx-dock-top" />
        <div data-i18n="Account Settings"> ADD Vendor / Admin</div>
      </a>
      <ul className="menu-sub">
        <li className="menu-item">
          <a href="pages-account-settings-account.html" className="menu-link">
            <div data-i18n="Account">Account</div>
          </a>
        </li>
        <li className="menu-item">
          <a
            href="pages-account-settings-notifications.html"
            className="menu-link"
          >
            <div data-i18n="Notifications">Notifications</div>
          </a>
        </li>
        <li className="menu-item">
          <a
            href="pages-account-settings-connections.html"
            className="menu-link"
          >
            <div data-i18n="Connections">Connections</div>
          </a>
        </li>
      </ul>
    </li>


    {/* <li className="menu-item">
      <a href="/addadmin" className="menu-link menu-toggle">
        <i className="menu-icon tf-icons bx bx-dock-top" />
        <div data-i18n="Account Settings">Admin ADD Admin</div>
      </a>
      <ul className="menu-sub">
        <li className="menu-item">
          <a href="pages-account-settings-account.html" className="menu-link">
            <div data-i18n="Account">Account</div>
          </a>
        </li>
        <li className="menu-item">
          <a
            href="pages-account-settings-notifications.html"
            className="menu-link"
          >
            <div data-i18n="Notifications">Notifications</div>
          </a>
        </li>
        <li className="menu-item">
          <a
            href="pages-account-settings-connections.html"
            className="menu-link"
          >
            <div data-i18n="Connections">Connections</div>
          </a>
        </li>
      </ul>
    </li>

 */}

    <li className="menu-item">
      <a href="/addonIndex" className="menu-link menu-toggle">
        <i className="menu-icon tf-icons bx bx-dock-top" />
        <div data-i18n="Account Settings"> AddOn</div>
      </a>
      <ul className="menu-sub">
        <li className="menu-item">
          <a href="/addonIndex" className="menu-link">
            <div data-i18n="Account">AddOn</div>
          </a>
        </li>
        <li className="menu-item">
          <a
            href="pages-account-settings-notifications.html"
            className="menu-link"
          >
            <div data-i18n="Notifications">Notifications</div>
          </a>
        </li>
        <li className="menu-item">
          <a
            href="pages-account-settings-connections.html"
            className="menu-link"
          >
            <div data-i18n="Connections">Connections</div>
          </a>
        </li>
      </ul>
    </li>





    <li className="menu-item">
      <a href="/sliderindex" className="menu-link menu-toggle">
        <i className="menu-icon tf-icons bx bx-dock-top" />
        <div data-i18n="Account Settings"> Slider</div>
      </a>
      <ul className="menu-sub">
        <li className="menu-item">
          <a href="/sliderindex" className="menu-link">
            <div data-i18n="Account">AddOn</div>
          </a>
        </li>
        <li className="menu-item">
          <a
            href="pages-account-settings-notifications.html"
            className="menu-link"
          >
            <div data-i18n="Notifications">Notifications</div>
          </a>
        </li>
        <li className="menu-item">
          <a
            href="pages-account-settings-connections.html"
            className="menu-link"
          >
            <div data-i18n="Connections">Connections</div>
          </a>
        </li>
      </ul>
    </li>





    <li className="menu-item">
      <a href="/AdvertiseIndex" className="menu-link menu-toggle">
        <i className="menu-icon tf-icons bx bx-dock-top" />
        <div data-i18n="Account Settings"> Advertise</div>
      </a>
      <ul className="menu-sub">
        <li className="menu-item">
          <a href="/AdvertiseIndex" className="menu-link">
            <div data-i18n="Account">Advertise</div>
          </a>
        </li>
        <li className="menu-item">
          <a
            href="pages-account-settings-notifications.html"
            className="menu-link"
          >
            <div data-i18n="Notifications">Notifications</div>
          </a>
        </li>
        <li className="menu-item">
          <a
            href="pages-account-settings-connections.html"
            className="menu-link"
          >
            <div data-i18n="Connections">Connections</div>
          </a>
        </li>
      </ul>
    </li>





    <li className="menu-item">
      <a href="/bandindex" className="menu-link menu-toggle">
        <i className="menu-icon tf-icons bx bx-dock-top" />
        <div data-i18n="Account Settings"> Band</div>
      </a>
      <ul className="menu-sub">
        <li className="menu-item">
          <a href="/bandindex" className="menu-link">
            <div data-i18n="Account">Advertise</div>
          </a>
        </li>
        <li className="menu-item">
          <a
            href="pages-account-settings-notifications.html"
            className="menu-link"
          >
            <div data-i18n="Notifications">Notifications</div>
          </a>
        </li>
        <li className="menu-item">
          <a
            href="pages-account-settings-connections.html"
            className="menu-link"
          >
            <div data-i18n="Connections">Connections</div>
          </a>
        </li>
      </ul>
    </li>









    <li className="menu-item">
      <a href="/blogindex" className="menu-link menu-toggle">
        <i className="menu-icon tf-icons bx bx-dock-top" />
        <div data-i18n="Account Settings"> Blog</div>
      </a>
      <ul className="menu-sub">
        <li className="menu-item">
          <a href="/bandindex" className="menu-link">
            <div data-i18n="Account">Blog</div>
          </a>
        </li>
        <li className="menu-item">
          <a
            href="pages-account-settings-notifications.html"
            className="menu-link"
          >
            <div data-i18n="Notifications">Notifications</div>
          </a>
        </li>
        <li className="menu-item">
          <a
            href="pages-account-settings-connections.html"
            className="menu-link"
          >
            <div data-i18n="Connections">Connections</div>
          </a>
        </li>
      </ul>
    </li>




    <li className="menu-item">
      <a href="/categoryindex" className="menu-link menu-toggle">
        <i className="menu-icon tf-icons bx bx-dock-top" />
        <div data-i18n="Account Settings"> Category</div>
      </a>
      <ul className="menu-sub">
        <li className="menu-item">
          <a href="/categoryindex" className="menu-link">
            <div data-i18n="Account">Category </div>
          </a>
        </li>
        <li className="menu-item">
          <a
            href="pages-account-settings-notifications.html"
            className="menu-link"
          >
            <div data-i18n="Notifications">Notifications</div>
          </a>
        </li>
        <li className="menu-item">
          <a
            href="pages-account-settings-connections.html"
            className="menu-link"
          >
            <div data-i18n="Connections">Connections</div>
          </a>
        </li>
      </ul>
    </li>



    <li className="menu-item">
      <a href="/cateringindex" className="menu-link menu-toggle">
        <i className="menu-icon tf-icons bx bx-dock-top" />
        <div data-i18n="Account Settings"> Catering</div>
      </a>
      <ul className="menu-sub">
        <li className="menu-item">
          <a href="/cateringindex" className="menu-link">
            <div data-i18n="Account">Catering</div>
          </a>
        </li>
        <li className="menu-item">
          <a
            href="pages-account-settings-notifications.html"
            className="menu-link"
          >
            <div data-i18n="Notifications">Notifications</div>
          </a>
        </li>
        <li className="menu-item">
          <a
            href="pages-account-settings-connections.html"
            className="menu-link"
          >
            <div data-i18n="Connections">Connections</div>
          </a>
        </li>
      </ul>
    </li>






    <li className="menu-item">
      <a href="/chefindex" className="menu-link menu-toggle">
        <i className="menu-icon tf-icons bx bx-dock-top" />
        <div data-i18n="Account Settings"> Chef</div>
      </a>
      <ul className="menu-sub">
        <li className="menu-item">
          <a href="/chefindex" className="menu-link">
            <div data-i18n="Account">Chef</div>
          </a>
        </li>
        <li className="menu-item">
          <a
            href="pages-account-settings-notifications.html"
            className="menu-link"
          >
            <div data-i18n="Notifications">Notifications</div>
          </a>
        </li>
        <li className="menu-item">
          <a
            href="pages-account-settings-connections.html"
            className="menu-link"
          >
            <div data-i18n="Connections">Connections</div>
          </a>
        </li>
      </ul>
    </li>



    <li className="menu-item">
      <a href="/contactindex" className="menu-link menu-toggle">
        <i className="menu-icon tf-icons bx bx-dock-top" />
        <div data-i18n="Account Settings"> Contact</div>
      </a>
      <ul className="menu-sub">
        <li className="menu-item">
          <a href="/contactindex" className="menu-link">
            <div data-i18n="Account">Contact</div>
          </a>
        </li>
        <li className="menu-item">
          <a
            href="pages-account-settings-notifications.html"
            className="menu-link"
          >
            <div data-i18n="Notifications">Notifications</div>
          </a>
        </li>
        <li className="menu-item">
          <a
            href="pages-account-settings-connections.html"
            className="menu-link"
          >
            <div data-i18n="Connections">Connections</div>
          </a>
        </li>
      </ul>
    </li>





    <li className="menu-item">
      <a href="/crackerindex" className="menu-link menu-toggle">
        <i className="menu-icon tf-icons bx bx-dock-top" />
        <div data-i18n="Account Settings"> Cracker</div>
      </a>
      <ul className="menu-sub">
        <li className="menu-item">
          <a href="/crackerindex" className="menu-link">
            <div data-i18n="Account">Cracker</div>
          </a>
        </li>
        <li className="menu-item">
          <a
            href="pages-account-settings-notifications.html"
            className="menu-link"
          >
            <div data-i18n="Notifications">Notifications</div>
          </a>
        </li>
        <li className="menu-item">
          <a
            href="pages-account-settings-connections.html"
            className="menu-link"
          >
            <div data-i18n="Connections">Connections</div>
          </a>
        </li>
      </ul>
    </li>



    <li className="menu-item">
      <a href="/decorationindex" className="menu-link menu-toggle">
        <i className="menu-icon tf-icons bx bx-dock-top" />
        <div data-i18n="Account Settings"> Decoration</div>
      </a>
      <ul className="menu-sub">
        <li className="menu-item">
          <a href="/decorationindex" className="menu-link">
            <div data-i18n="Account">Decoration</div>
          </a>
        </li>
        <li className="menu-item">
          <a
            href="pages-account-settings-notifications.html"
            className="menu-link"
          >
            <div data-i18n="Notifications">Notifications</div>
          </a>
        </li>
        <li className="menu-item">
          <a
            href="pages-account-settings-connections.html"
            className="menu-link"
          >
            <div data-i18n="Connections">Connections</div>
          </a>
        </li>
      </ul>
    </li>





    <li className="menu-item">
      <a href="/eventmgmtindex" className="menu-link menu-toggle">
        <i className="menu-icon tf-icons bx bx-dock-top" />
        <div data-i18n="Account Settings"> Eventmgmt</div>
      </a>
      <ul className="menu-sub">
        <li className="menu-item">
          <a href="/eventmgmtindex" className="menu-link">
            <div data-i18n="Account">Eventmgmt</div>
          </a>
        </li>
        <li className="menu-item">
          <a
            href="pages-account-settings-notifications.html"
            className="menu-link"
          >
            <div data-i18n="Notifications">Notifications</div>
          </a>
        </li>
        <li className="menu-item">
          <a
            href="pages-account-settings-connections.html"
            className="menu-link"
          >
            <div data-i18n="Connections">Connections</div>
          </a>
        </li>
      </ul>
    </li>
















    <li className="menu-item">
      <a href="/hallIndex" className="menu-link menu-toggle">
        <i className="menu-icon tf-icons bx bx-dock-top" />
        <div data-i18n="Account Settings"> Hall</div>
      </a>
      <ul className="menu-sub">
        <li className="menu-item">
          <a href="/hallIndex" className="menu-link">
            <div data-i18n="Account">Hall</div>
          </a>
        </li>
        <li className="menu-item">
          <a
            href="pages-account-settings-notifications.html"
            className="menu-link"
          >
            <div data-i18n="Notifications">Notifications</div>
          </a>
        </li>
        <li className="menu-item">
          <a
            href="pages-account-settings-connections.html"
            className="menu-link"
          >
            <div data-i18n="Connections">Connections</div>
          </a>
        </li>
      </ul>
    </li>






    <li className="menu-item">
      <a href="/lawnindex" className="menu-link menu-toggle">
        <i className="menu-icon tf-icons bx bx-dock-top" />
        <div data-i18n="Account Settings"> Lawn</div>
      </a>
      <ul className="menu-sub">
        <li className="menu-item">
          <a href="/lawnindex" className="menu-link">
            <div data-i18n="Account">Lawn</div>
          </a>
        </li>
        <li className="menu-item">
          <a
            href="pages-account-settings-notifications.html"
            className="menu-link"
          >
            <div data-i18n="Notifications">Notifications</div>
          </a>
        </li>
        <li className="menu-item">
          <a
            href="pages-account-settings-connections.html"
            className="menu-link"
          >
            <div data-i18n="Connections">Connections</div>
          </a>
        </li>
      </ul>
    </li>











    <li className="menu-item">
      <a href="/lightingindex" className="menu-link menu-toggle">
        <i className="menu-icon tf-icons bx bx-dock-top" />
        <div data-i18n="Account Settings"> Lighting</div>
      </a>
      <ul className="menu-sub">
        <li className="menu-item">
          <a href="/lightingindex" className="menu-link">
            <div data-i18n="Account">Lighting</div>
          </a>
        </li>
        <li className="menu-item">
          <a
            href="pages-account-settings-notifications.html"
            className="menu-link"
          >
            <div data-i18n="Notifications">Notifications</div>
          </a>
        </li>
        <li className="menu-item">
          <a
            href="pages-account-settings-connections.html"
            className="menu-link"
          >
            <div data-i18n="Connections">Connections</div>
          </a>
        </li>
      </ul>
    </li>




    <li className="menu-item">
      <a href="/makeupindex" className="menu-link menu-toggle">
        <i className="menu-icon tf-icons bx bx-dock-top" />
        <div data-i18n="Account Settings"> Makeup</div>
      </a>
      <ul className="menu-sub">
        <li className="menu-item">
          <a href="/makeupindex" className="menu-link">
            <div data-i18n="Account">Makeup</div>
          </a>
        </li>
        <li className="menu-item">
          <a
            href="pages-account-settings-notifications.html"
            className="menu-link"
          >
            <div data-i18n="Notifications">Notifications</div>
          </a>
        </li>
        <li className="menu-item">
          <a
            href="pages-account-settings-connections.html"
            className="menu-link"
          >
            <div data-i18n="Connections">Connections</div>
          </a>
        </li>
      </ul>
    </li>






    <li className="menu-item">
      <a href="/marketingindex" className="menu-link menu-toggle">
        <i className="menu-icon tf-icons bx bx-dock-top" />
        <div data-i18n="Account Settings"> Marketing</div>
      </a>
      <ul className="menu-sub">
        <li className="menu-item">
          <a href="/marketingindex" className="menu-link">
            <div data-i18n="Account">Marketing</div>
          </a>
        </li>
        <li className="menu-item">
          <a
            href="pages-account-settings-notifications.html"
            className="menu-link"
          >
            <div data-i18n="Notifications">Notifications</div>
          </a>
        </li>
        <li className="menu-item">
          <a
            href="pages-account-settings-connections.html"
            className="menu-link"
          >
            <div data-i18n="Connections">Connections</div>
          </a>
        </li>
      </ul>
    </li>








    <li className="menu-item">
      <a href="/photoindex" className="menu-link menu-toggle">
        <i className="menu-icon tf-icons bx bx-dock-top" />
        <div data-i18n="Account Settings"> Photography</div>
      </a>
      <ul className="menu-sub">
        <li className="menu-item">
          <a href="/photoindex" className="menu-link">
            <div data-i18n="Account">Photography</div>
          </a>
        </li>
        <li className="menu-item">
          <a
            href="pages-account-settings-notifications.html"
            className="menu-link"
          >
            <div data-i18n="Notifications">Notifications</div>
          </a>
        </li>
        <li className="menu-item">
          <a
            href="pages-account-settings-connections.html"
            className="menu-link"
          >
            <div data-i18n="Connections">Connections</div>
          </a>
        </li>
      </ul>
    </li>






    <li className="menu-item">
      <a href="/tagindex" className="menu-link menu-toggle">
        <i className="menu-icon tf-icons bx bx-dock-top" />
        <div data-i18n="Account Settings"> Tag</div>
      </a>
      <ul className="menu-sub">
        <li className="menu-item">
          <a href="/tagindex" className="menu-link">
            <div data-i18n="Account">Tag</div>
          </a>
        </li>
        <li className="menu-item">
          <a
            href="pages-account-settings-notifications.html"
            className="menu-link"
          >
            <div data-i18n="Notifications">Notifications</div>
          </a>
        </li>
        <li className="menu-item">
          <a
            href="pages-account-settings-connections.html"
            className="menu-link"
          >
            <div data-i18n="Connections">Connections</div>
          </a>
        </li>
      </ul>
    </li>




    <li className="menu-item">
      <a href="/tentindex" className="menu-link menu-toggle">
        <i className="menu-icon tf-icons bx bx-dock-top" />
        <div data-i18n="Account Settings"> Tent</div>
      </a>
      <ul className="menu-sub">
        <li className="menu-item">
          <a href="/tentindex" className="menu-link">
            <div data-i18n="Account">Tent</div>
          </a>
        </li>
        <li className="menu-item">
          <a
            href="pages-account-settings-notifications.html"
            className="menu-link"
          >
            <div data-i18n="Notifications">Notifications</div>
          </a>
        </li>
        <li className="menu-item">
          <a
            href="pages-account-settings-connections.html"
            className="menu-link"
          >
            <div data-i18n="Connections">Connections</div>
          </a>
        </li>
      </ul>
    </li>



    <li className="menu-item">
      <a href="/videoindex" className="menu-link menu-toggle">
        <i className="menu-icon tf-icons bx bx-dock-top" />
        <div data-i18n="Account Settings"> Videography</div>
      </a>
      <ul className="menu-sub">
        <li className="menu-item">
          <a href="/videoindex" className="menu-link">
            <div data-i18n="Account">Videography</div>
          </a>
        </li>
        <li className="menu-item">
          <a
            href="pages-account-settings-notifications.html"
            className="menu-link"
          >
            <div data-i18n="Notifications">Notifications</div>
          </a>
        </li>
        <li className="menu-item">
          <a
            href="pages-account-settings-connections.html"
            className="menu-link"
          >
            <div data-i18n="Connections">Connections</div>
          </a>
        </li>
      </ul>
    </li>



    <li className="menu-item">
      <a href="/waterindex" className="menu-link menu-toggle">
        <i className="menu-icon tf-icons bx bx-dock-top" />
        <div data-i18n="Account Settings"> Water</div>
      </a>
      <ul className="menu-sub">
        <li className="menu-item">
          <a href="/waterindex" className="menu-link">
            <div data-i18n="Account">Water</div>
          </a>
        </li>
        <li className="menu-item">
          <a
            href="pages-account-settings-notifications.html"
            className="menu-link"
          >
            <div data-i18n="Notifications">Notifications</div>
          </a>
        </li>
        <li className="menu-item">
          <a
            href="pages-account-settings-connections.html"
            className="menu-link"
          >
            <div data-i18n="Connections">Connections</div>
          </a>
        </li>
      </ul>
    </li>



    <li className="menu-item">
      <a href="/profileindex" className="menu-link menu-toggle">
        <i className="menu-icon tf-icons bx bx-dock-top" />
        <div data-i18n="Account Settings"> Profile</div>
      </a>
      <ul className="menu-sub">
        <li className="menu-item">
          <a href="/profileindex" className="menu-link">
            <div data-i18n="Account">Profile </div>
          </a>
        </li>
        <li className="menu-item">
          <a
            href="pages-account-settings-notifications.html"
            className="menu-link"
          >
            <div data-i18n="Notifications">Notifications</div>
          </a>
        </li>
        <li className="menu-item">
          <a
            href="pages-account-settings-connections.html"
            className="menu-link"
          >
            <div data-i18n="Connections">Connections</div>
          </a>
        </li>
      </ul>
    </li>






        <li className="menu-item">
          <a href="/testimonialindex" className="menu-link menu-toggle">
            <i className="menu-icon tf-icons bx bx-dock-top" />
            <div data-i18n="Account Settings"> Testimonial</div>
          </a>
          <ul className="menu-sub">
            <li className="menu-item">
              <a href="/testimonialindex" className="menu-link">
                <div data-i18n="Account">Testimonial</div>
              </a>
            </li>
            <li className="menu-item">
              <a
                href="pages-account-settings-notifications.html"
                className="menu-link"
              >
                <div data-i18n="Notifications">Notifications</div>
              </a>
            </li>
            <li className="menu-item">
              <a
                href="pages-account-settings-connections.html"
                className="menu-link"
              >
                <div data-i18n="Connections">Connections</div>
              </a>
            </li>
          </ul>
        </li>





  </ul>
</aside>

    </React.Fragment>
  )
}

export default Sidebar
