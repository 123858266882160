import React, { useEffect, useState } from 'react';
import Footer from '../Footer';
import Header from '../Header';
import Sidebar from '../Sidebar';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useParams } from 'react-router-dom';
import Dashboard from '../dashboard/Dashboard';
import axiosInstance from '../../axiosConfig';
import Spinner from '../spinner.gif';
import axios from 'axios';

function EditHall(props) {
  const { id } = useParams();   
  const [hname, setHname] = useState('');
  const [afor, setAfor] = useState('');
  const [type, setType] = useState('');
  const [rent, setRent] = useState('');
  const [area, setArea] = useState('');
  const [address, setAddress] = useState('');
  const [intakecap, setIntakecap] = useState('');
  const [parkingcap, setParkingcap] = useState('');
  const [kitchen, setKitchen] = useState('');
  const [kitchen_material, setKitchen_material] = useState('');
  const [timings, setTimings] = useState('');
  const [images, setImages] = useState([]);
  const [video, setVideo] = useState(null);
  const [contact, setContact] = useState('');
  const [watersupply, setWatersupply] = useState('');
  const [washers, setWashers] = useState('');
  const [waiters, setWaiters] = useState('');
  const [status, setStatus] = useState('');
  const [price, setPrice] = useState('');
  const [capacity, setCapacity] = useState('');
  const [Showdata, setShowdata] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [userid, setUserid] = useState('');


  const [formErrors, setFormErrors] = useState({}); // Initialize form error state

  const [loading, setLoading] = useState(false);
  const token = localStorage.getItem('uid');
  
  
  const config = {
    headers: { 'Content-Type': 'multipart/form-data' ,'Authorization':"Bearer "+token}
  };

  
  function validateForm() {
    const errors = {};



    if (!type) {
      errors.type = 'type is required ';
    }

    if (!hname || !/^[a-zA-Z\s]+$/.test(hname)) {
      errors.hname = 'hname is required and should contain only letters';
    }

    if (!contact || !/^\d{10}$/.test(contact)) {
      errors.contact = 'Contact is required and should be a 10-digit number';
    }

    if (!address) {
      errors.address = 'Address is required';
    }

    if (!address) {
      errors.address = 'address is required';
    }

    if (!area) {
      errors.area = 'area is required';
    }

    if (!images) {
      errors.image = 'image is required';
    }

    if (!video) {
      errors.video = 'video is required';
    }

    if (!area) {
      errors.area = 'area is required';
    }
    if (!afor) {
      errors.afor = 'description is required';
    }


    if (!rent) {
      errors.rent = 'rent is required';
    } else if (!/^\d+$/.test(rent)) {
      errors.rent = 'Please enter only numeric characters for packages';
    }
    if (!intakecap) {
      errors.intakecap = 'intakecap is required';
    } else if (!/^\d+$/.test(intakecap)) {
      errors.intakecap = 'Please enter only numeric characters for packages';
    }


    if (!capacity) {
      errors.capacity = 'capacity is required';
    } else if (!/^\d+$/.test(capacity)) {
      errors.capacity = 'Please enter only numeric characters for packages';
    }

    if (!price) {
      errors.price = 'price is required';
    } else if (!/^\d+$/.test(price)) {
      errors.price = 'Please enter only numeric characters for packages';
    }

    



    setFormErrors(errors);

    return Object.keys(errors).length === 0;
  }

  function fetchHallData() {
    axios
      .get(`https://api.bookmyfunctionhall.com/api/Hall/${id}`,config)
      .then((res) => {
        const data = res.data;
        setHname(data.hname);
        setAfor(data.for);
        setType(data.type);
        setRent(data.rent);
        setArea(data.area);
        setAddress(data.address);
        setIntakecap(data.intakecap);
        setParkingcap(data.parkingcap);
        setKitchen(data.kitchen);
        setKitchen_material(data.kitchen_material);
        setTimings(data.timings);
        setContact(data.contact);
        setWatersupply(data.watersupply);
        setWashers(data.washers);
        setWaiters(data.waiters);
        setStatus(data.status);
        setPrice(data.price);
        setCapacity(data.capacity);
        setVideo(data.video);
        setSelectedCategories(JSON.parse(data.category));
        setUserid(data.userid);
      })
      .catch((error) => {
        //console.log('Error', error);
      });
  }

//  console.log('selected categories:');
//   co nsole.log(selectedCategories);

  function handleChange(e) {
    if (e.target.name === 'images') {
      const imageFiles = e.target.files;
      const imageFilesArray = Array.from(imageFiles);
      setImages([...images, ...imageFilesArray]);
    } 
  }

  function handleCategoryChange(e) {
    const selectedCategory = e.target.value;
    const updatedCategories = selectedCategories.includes(selectedCategory)
      ? selectedCategories.filter((category) => category !== selectedCategory)
      : [...selectedCategories, selectedCategory];
    setSelectedCategories(updatedCategories);
   // console.log('updatedCategories:')
   // console.log(updatedCategories)
  }
  

  function updateHall(e) {
    e.preventDefault();
    // if (!hname || selectedCategories.length === 0) {
    //   toast.error('Please fill in all required fields.', { autoClose: 2000 });
    //   return;
    // }


    
     
 if (validateForm()) {

  setLoading(true);




    // const categoriesArray = Array.isArray(selectedCategories) ? selectedCategories : [selectedCategories];
    let arstr=[];
    const data = new FormData();
    data.append('_method', 'put');
    data.append('hname', hname);
    data.append('for', afor);
    data.append('type', type);
    data.append('rent', rent);
    data.append('area', area);
    data.append('address', address);
    data.append('intakecap', intakecap);
    data.append('parkingcap', parkingcap);
    data.append('kitchen', kitchen);
    data.append('kitchen_material', kitchen_material);
    data.append('timings', timings);
    images.forEach((image, index) => {
      data.append(`images[${index}]`, image);
    });
    data.append('video', video);
    data.append('contact', contact);
    data.append('watersupply', watersupply);
    data.append('washers', washers);
    data.append('waiters', waiters);
    data.append('status', status);
    data.append('price', price);
    data.append('capacity', capacity);
    data.append('userid', userid);
   // data.append('categories[]', selectedCategories);
    selectedCategories.forEach((category) => {
       // Changed 'category' to 'categories'
       arstr=[...arstr,category];
    });
    data.append('categories', JSON.stringify(arstr));
    //console.log(selectedCategories);



    axiosInstance
      .post(`https://api.bookmyfunctionhall.com/api/Hall/${id}`, data, {
        _method: 'PUT',
        headers: { 'Content-Type': 'multipart/form-data' },
      })
      .then((resp) => {
        if (resp && resp.data && resp.data.status === 'success') {
         // console.log(selectedCategories);
         // console.log("test");
         // console.log(resp.data);
          toast.success('Data Updated!!!', { autoClose: 2000 });
          window.location.href = '/hallindex';
        } else {
         // console.log('Error data:', resp.data);
          toast.success('Data Updated', { autoClose: 2000 });
          window.location.href = '/hallindex';
        }
      })
      .catch((error) => {
       // console.log('Errorrr:', error);
        toast.success('Data Updated', { autoClose: 2000 });
        window.location.href = '/hallindex';
      })
      .finally(() => {
        setLoading(false); 
      });
  }


}
  function fetchData() {
    axios
      .get('https://api.bookmyfunctionhall.com/api/categoryfrontend',config)
      .then((res) => {
        const data = res.data;
        //console.log(data);
        setShowdata(data);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  useEffect(() => {
    fetchData();
    fetchHallData();
    const idd = localStorage.getItem('id');
    if(idd)
    {
      setUserid(idd);
    }
  }, []);


  
  // console.log(Showdata);

  return (
    <React.Fragment>
      <ToastContainer />
      <div className="layout-wrapper layout-content-navbar">
        <div className="layout-container">
          {/* <Sidebar /> */}
          <Dashboard/>
          <div className="layout-page">
            <Header />
            <div className="content-wrapper">
              <div className="container-xxl flex-grow-1 container-p-y">
                <h4 className="fw-bold py-3 mb-4">
                  <span className="text-muted fw-light">Edit Hall</span>
                </h4>
                <div className="row">
                  <div className="col-xl">
                    <div className="card mb-4 col-md-6">
                      <div className="card-header d-flex justify-content-between align-items-center">
                      </div>
                      <div className="card-body">
                      <form onSubmit={updateHall} encType='multipart/form-data' >



 
                      <div className="mb-3">
     
     <div className="input-group input-group-merge">
      
       <input
         type="hidden"
         name="userid"
         className="form-control"
         id="basic-icon-default-fullname"
         placeholder="Type Available or Not Available"
         aria-label="Type Available or Not Available"
         aria-describedby="basic-icon-default-fullname2"
         onChange={(event) => setUserid(event.target.value)}
         value={userid} // Set the initial value to the "userid" state
         readOnly
       />
     </div>
   </div>
                      <div className="mb-3">
                          <label className="form-label" for="basic-icon-default-fullname">Hall Name</label>
                          <div className="input-group input-group-merge">
                            <span id="basic-icon-default-fullname2" className="input-group-text"
                              ><i className="bx bx-user"></i
                            ></span>
                            <input
                              type="text"
                              name="hname"
                              className={`form-control ${formErrors.hname ? 'is-invalid' : ''}`}
                              id="basic-icon-default-fullname"
                              placeholder="John Doe"
                              aria-label="John Doe"
                              aria-describedby="basic-icon-default-fullname2"
                              onChange={hname=>setHname(hname.target.value)} value={hname}
                            />
                            {formErrors.hname && (
                                <div className="invalid-feedback">{formErrors.hname}</div>
                              )}

                          </div>
                        </div>

                        <div className="mb-3">
                          <label className="form-label" for="basic-icon-default-fullname">For</label>
                          <div className="input-group input-group-merge">
                            <span id="basic-icon-default-fullname2" className="input-group-text"
                              ><i className="bx bx-user"></i
                            ></span>
                            <input
                              type="text"
                              name="for"
                              className={`form-control ${formErrors.afor ? 'is-invalid' : ''}`}
                              id="basic-icon-default-fullname"
                              placeholder="John Doe"
                              aria-label="John Doe"
                              aria-describedby="basic-icon-default-fullname2"
                              onChange={afor=>setAfor(afor.target.value)} value={afor}
                            />
                             {formErrors.afor && (
                                <div className="invalid-feedback">{formErrors.afor}</div>
                              )}

                          </div>
                        </div>

                        <div className="mb-3">
                          <label className="form-label" for="basic-icon-default-fullname">type</label>
                          <div className="input-group input-group-merge">
                            <span id="basic-icon-default-fullname2" className="input-group-text"
                              ><i className="bx bx-user"></i
                            ></span>
                            <input
                              type="text"
                              name="type"
                              className={`form-control ${formErrors.type ? 'is-invalid' : ''}`}
                              id="basic-icon-default-fullname"
                              placeholder="Type - Wedding,Party,BirthDay Hall"
                              aria-label="John Doe"
                              aria-describedby="basic-icon-default-fullname2"
                              onChange={type=>setType(type.target.value)} value={type}
                            />
                             {formErrors.type && (
                                <div className="invalid-feedback">{formErrors.type}</div>
                              )}
                          </div>
                        </div>

                        <div className="mb-3">
                          <label className="form-label" for="basic-icon-default-fullname">Rent</label>
                          <div className="input-group input-group-merge">
                            <span id="basic-icon-default-fullname2" className="input-group-text"
                              ><i className="bx bx-user"></i
                            ></span>
                            <input
                              type="text"
                              name="rent"
                              className={`form-control ${formErrors.rent ? 'is-invalid' : ''}`}
                              id="basic-icon-default-fullname"
                              placeholder="John Doe"
                              aria-label="John Doe"
                              aria-describedby="basic-icon-default-fullname2"
                              onChange={rent=>setRent(rent.target.value)} value={rent}
                            />
                              {formErrors.rent && (
                                <div className="invalid-feedback">{formErrors.rent}</div>
                              )}

                          </div>
                        </div>

                        <div className="mb-3">
                          <label className="form-label" for="basic-icon-default-fullname">Area</label>
                          <div className="input-group input-group-merge">
                            <span id="basic-icon-default-fullname2" className="input-group-text"
                              ><i className="bx bx-user"></i
                            ></span>
                            <input
                              type="text"
                              name="area"
                              className={`form-control ${formErrors.area ? 'is-invalid' : ''}`}
                              id="basic-icon-default-fullname"
                              placeholder="John Doe"
                              aria-label="John Doe"
                              aria-describedby="basic-icon-default-fullname2"
                              onChange={area=>setArea(area.target.value)} value={area}
                            />
                              {formErrors.area && (
                                <div className="invalid-feedback">{formErrors.area}</div>
                              )}


                          </div>
                        </div>

                        <div className="mb-3">
                          <label className="form-label" for="basic-icon-default-email">Address</label>
                          <div className="input-group input-group-merge">
                            <span className="input-group-text"><i className="bx bx-envelope"></i></span>
                            <input
                              type="text"
                              name="address"
                              id="basic-icon-default-email"
                              className={`form-control ${formErrors.address ? 'is-invalid' : ''}`}

                              placeholder="john.doe"
                              aria-label="john.doe"
                              aria-describedby="basic-icon-default-email2"
                              onChange={address=>setAddress(address.target.value)} value={address}
                            />
                            {formErrors.address && (
                                <div className="invalid-feedback">{formErrors.address}</div>
                              )}

                            
                          </div>
                          
                        </div>

                        <div className="mb-3">
                          <label className="form-label" for="basic-icon-default-fullname">intakecap</label>
                          <div className="input-group input-group-merge">
                            <span id="basic-icon-default-fullname2" className="input-group-text"
                              ><i className="bx bx-user"></i
                            ></span>
                            <input
                              type="text"
                              name="intakecap"
                              className={`form-control ${formErrors.intakecap ? 'is-invalid' : ''}`}
                              id="basic-icon-default-fullname"
                              placeholder="John Doe"
                              aria-label="John Doe"
                              aria-describedby="basic-icon-default-fullname2"
                              onChange={intakecap=>setIntakecap(intakecap.target.value)} value={intakecap}
                            />
                             {formErrors.intakecap && (
                                <div className="invalid-feedback">{formErrors.intakecap}</div>
                              )}

                          </div>
                        </div>

                        <div className="mb-3">
                          <label className="form-label" for="basic-icon-default-fullname">parkingcap</label>
                          <div className="input-group input-group-merge">
                            <span id="basic-icon-default-fullname2" className="input-group-text"
                              ><i className="bx bx-user"></i
                            ></span>
                            <input
                              type="text"
                              name="parkingcap"
                              className={`form-control ${formErrors.afor ? 'is-invalid' : ''}`}
                              id="basic-icon-default-fullname"
                              placeholder="John Doe"
                              aria-label="John Doe"
                              aria-describedby="basic-icon-default-fullname2"
                              onChange={parkingcap=>setParkingcap(parkingcap.target.value)} value={parkingcap}
                            />
                               {formErrors.parkingcap && (
                                <div className="invalid-feedback">{formErrors.parkingcap}</div>
                              )}

                          </div>
                        </div>

                        <div className="mb-3">
                          <label className="form-label" for="basic-icon-default-fullname">kitchen</label>
                          <div className="input-group input-group-merge">
                            <span id="basic-icon-default-fullname2" className="input-group-text"
                              ><i className="bx bx-user"></i
                            ></span>
                            <input
                              type="text"
                              name="kitchen"
                              className="form-control"
                              id="basic-icon-default-fullname"
                              placeholder="John Doe"
                              aria-label="John Doe"
                              aria-describedby="basic-icon-default-fullname2"
                              onChange={kitchen=>setKitchen(kitchen.target.value)} value={kitchen}
                            />

                          </div>
                        </div>

                        <div className="mb-3">
                          <label className="form-label" for="basic-icon-default-fullname">kitchen_material</label>
                          <div className="input-group input-group-merge">
                            <span id="basic-icon-default-fullname2" className="input-group-text"
                              ><i className="bx bx-user"></i
                            ></span>
                            <input
                              type="text"
                              name="kitchen_material"
                              className="form-control"
                              id="basic-icon-default-fullname"
                              placeholder="John Doe"
                              aria-label="John Doe"
                              aria-describedby="basic-icon-default-fullname2"
                              onChange={kitchen_material=>setKitchen_material(kitchen_material.target.value)} value={kitchen_material}
                            />

                          </div>
                        </div>

                        <div className="mb-3">
                          <label className="form-label" for="basic-icon-default-fullname">timings</label>
                          <div className="input-group input-group-merge">
                            <span id="basic-icon-default-fullname2" className="input-group-text"
                              ><i className="bx bx-user"></i
                            ></span>
                            <input
                              type="text"
                              name="timings"
                              className="form-control"
                              id="basic-icon-default-fullname"
                              placeholder="John Doe"
                              aria-label="John Doe"
                              aria-describedby="basic-icon-default-fullname2"
                              onChange={timings=>setTimings(timings.target.value)} value={timings}
                            />

                          </div>
                        </div>
                        
                        
                        <div className="mb-3">
                          <label className="form-label" for="basic-icon-default-email">Images</label>
                          <div className="input-group input-group-merge">
                            <span className="input-group-text"><i className="bx bx-envelope"></i></span>
                            <input
                              type="file"
                              name="images"
                              accept="images/*"
                              id="basic-icon-default-email"
                              className={`form-control ${formErrors.image ? 'is-invalid' : ''}`}

                              multiple
                              placeholder="john.doe"
                              aria-label="john.doe"
                              aria-describedby="basic-icon-default-email2"
                              onChange={handleChange}                              
                            />
                             {formErrors.image && (
                                <div className="invalid-feedback">{formErrors.image}</div>
                              )}
                            
                          </div>
                          
                        </div>

                    
                        
                        <div className="mb-3">
                          <label className="form-label" for="basic-icon-default-email">video</label>
                          <div className="input-group input-group-merge">
                            <span className="input-group-text"><i className="bx bx-envelope"></i></span>
                            {/* <input
                              type="file"
                              name="video"
                              accept="video/*"
                              id="basic-icon-default-email"
                              className="form-control"
                              placeholder="john.doe"
                              aria-label="john.doe"
                              aria-describedby="basic-icon-default-email2"
                              onChange={handleChange}

                            /> */}

                          <input
                              type="text"
                              name="video"
                              className={`form-control ${formErrors.video ? 'is-invalid' : ''}`}
                              id="basic-icon-default-fullname"
                              placeholder="John Doe"
                              aria-label="John Doe"
                              aria-describedby="basic-icon-default-fullname2"
                              onChange={video=>setVideo(video.target.value)} value={video}
                            />
                              {formErrors.video && (
                                <div className="invalid-feedback">{formErrors.video}</div>
                              )}



                            
                          </div>
                          
                        </div>

                        <div className="mb-3">
                          <label className="form-label" for="basic-icon-default-fullname">contact</label>
                          <div className="input-group input-group-merge">
                            <span id="basic-icon-default-fullname2" className="input-group-text"
                              ><i className="bx bx-user"></i
                            ></span>
                            <input
                              type="text"
                              name="contact"
                              className={`form-control ${formErrors.contact ? 'is-invalid' : ''}`}

                              id="basic-icon-default-fullname"
                              placeholder="John Doe"
                              aria-label="John Doe"
                              aria-describedby="basic-icon-default-fullname2"
                              onChange={contact=>setContact(contact.target.value)} value={contact}
                            />
                            
                            {formErrors.contact && (
                                <div className="invalid-feedback">{formErrors.contact}</div>
                              )}

                          </div>
                        </div>

                        <div className="mb-3">
                          <label className="form-label" for="basic-icon-default-fullname">watersupply</label>
                          <div className="input-group input-group-merge">
                            <span id="basic-icon-default-fullname2" className="input-group-text"
                              ><i className="bx bx-user"></i
                            ></span>
                            <input
                              type="text"
                              name="watersupply"
                              className="form-control"
                              id="basic-icon-default-fullname"
                              placeholder="Type Available or Not Available"
                              aria-label="John Doe"
                              aria-describedby="basic-icon-default-fullname2"
                              onChange={watersupply=>setWatersupply(watersupply.target.value)} value={watersupply}
                            />

                          </div>
                        </div>

                        <div className="mb-3">
                          <label className="form-label" for="basic-icon-default-fullname">washers</label>
                          <div className="input-group input-group-merge">
                            <span id="basic-icon-default-fullname2" className="input-group-text"
                              ><i className="bx bx-user"></i
                            ></span>
                            <input
                              type="text"
                              name="washers"
                              className="form-control"
                              id="basic-icon-default-fullname"
                              placeholder="Type Available or Not Available"
                              aria-label="Type Available or Not Available"
                              aria-describedby="basic-icon-default-fullname2"
                              onChange={washers=>setWashers(washers.target.value)} value={washers}
                            />

                          </div>
                        </div>

                        <div className="mb-3">
                          <label className="form-label" for="basic-icon-default-fullname">waiters</label>
                          <div className="input-group input-group-merge">
                            <span id="basic-icon-default-fullname2" className="input-group-text"
                              ><i className="bx bx-user"></i
                            ></span>
                            <input
                              type="text"
                              name="waiters"
                              className="form-control"
                              id="basic-icon-default-fullname"
                              placeholder="Type Available or Not Available"
                              aria-label="Type Available or Not Available"
                              aria-describedby="basic-icon-default-fullname2"
                              onChange={waiters=>setWaiters(waiters.target.value)} value={waiters}
                            />

                          </div>
                        </div>

                        <div className="mb-3">
                          {/* <label className="form-label" for="basic-icon-default-fullname">status</label>
                          <div className="input-group input-group-merge">
                            <span id="basic-icon-default-fullname2" className="input-group-text"
                              ><i className="bx bx-user"></i
                            ></span>
                            <input
                              type="text"
                              name="status"
                              className="form-control"
                              id="basic-icon-default-fullname"
                              placeholder="Type Available or Not Available"
                              aria-label="Type Available or Not Available"
                              aria-describedby="basic-icon-default-fullname2"
                              onChange={status=>setStatus(status.target.value)} value={status}
                            />

                          </div> */}
                          
                          <div class="form-group">
                              <label for="">Status</label>
                              <select class="custom-select form-control" name="" id="" onChange={status=>setStatus(status.target.value)} value={status}>
                                <option value="active" >Active</option>
                                <option value="inactive">Inactive</option>

                              </select>
                            </div>
                        </div>



                        <div className="mb-3">
                          <label className="form-label" for="basic-icon-default-fullname">Category</label>
                          <div className="input-group input-group-merge">                      
                             {Showdata.map((row) => {
                            return (
                              <div key={row.id}>
                                <input type="checkbox" name='category'  style={{marginLeft:"15px"}}  value={row.name} checked={selectedCategories.includes(row.name)} onChange={handleCategoryChange}/><span>{row.name}</span>
                              </div>
                              );
                          })}

                          </div>
                        </div>

                        
                      

                    

                        

                        <div className="mb-3">
                          <label className="form-label" for="basic-icon-default-email">price</label>
                          <div className="input-group input-group-merge">
                            <span className="input-group-text"><i className="bx bx-envelope"></i></span>
                            <input
                              type="text"
                              name="price"
                              id="basic-icon-default-email"
                              className={`form-control ${formErrors.price ? 'is-invalid' : ''}`}

                              placeholder="john.doe"
                              aria-label="john.doe"
                              aria-describedby="basic-icon-default-email2"
                              onChange={price=>setPrice(price.target.value)} value={price}

                            />
                               
                               {formErrors.price && (
                                <div className="invalid-feedback">{formErrors.price}</div>
                              )}
                            
                            
                          </div>
                          
                        </div>




                        <div className="mb-3">
                          <label className="form-label" for="basic-icon-default-email">capacity</label>
                          <div className="input-group input-group-merge">
                            <span className="input-group-text"><i className="bx bx-envelope"></i></span>
                            <input
                              type="text"
                              name="capacity"
                              id="basic-icon-default-email"
                              className={`form-control ${formErrors.capacity ? 'is-invalid' : ''}`}
                              placeholder="john.doe"
                              aria-label="john.doe"
                              aria-describedby="basic-icon-default-email2"
                              onChange={capacity=>setCapacity(capacity.target.value)} value={capacity}

                            />
                             {formErrors.capacity && (
                                <div className="invalid-feedback">{formErrors.capacity}</div>
                              )}
                            
                          </div>
                          
                        </div>

                       

                        <button onClick={updateHall}  type="submit" className="btn btn" style={{ backgroundColor: "rgb(127, 1, 75)", color: "#fff" }} disabled={loading}>
                  {loading ? (
                    <img src={Spinner} alt="spinner" style={{ width:"30px" }} />
                  ) : (
                    'Update'
                  )}
                  </button>

                  


                        {/* <button type="submit" className="btn btn-primary" >Send</button> */}
                      </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Footer />
              <div className="content-backdrop fade"></div>
            </div>
          </div>
        </div>
        <div className="layout-overlay layout-menu-toggle"></div>
      </div>

      <div className="buy-now">
        <a
          href="https://themeselection.com/products/sneat-bootstrap-html-admin-template/"
          target="_blank"
          className="btn btn-danger btn-buy-now"
        >
          Upgrade to Pro
        </a>
      </div>
    </React.Fragment>
  );
}

export default EditHall;
