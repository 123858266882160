import React, { useState,useEffect } from 'react';
import Footer from '../Footer';
import Header from '../Header';
import Sidebar from '../Sidebar';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import  axiosInstance  from '../../axiosConfig';
import Dashboard from '../dashboard/Dashboard';
import Spinner from '../spinner.gif';

function MarketingAdd() {
  const [client, setClient] = useState(''); // Renamed 'client' to 'client'
  const [email, setEmail] = useState('');
   const [contact, setContact] = useState('');
  const [adtitle, setAdtitle] = useState('');
  const [description, setDescription] = useState('');
  const [length, setLength] = useState('');
  
  const [contract, setContract] = useState('');
  const [status, setStatus] = useState('');
  const [image, setImages] = useState(null);
  const [video, setvideo] = useState(null); 
  const [userid, setUserid] = useState('');

  const [formErrors, setFormErrors] = useState({}); // Initialize form error state
  const [loading, setLoading] = useState(false);
  const token = localStorage.getItem('uid');

  function handleChange(e) {
    const { name, value, files } = e.target;
    if (name === 'client') {
      setClient(value);
    }
    else if (name === 'email') {
      setEmail(value);
    }  
    else if (name === 'adtitle') {
      setAdtitle(value);
    }
    else if (name === 'description') {
      setDescription(value);
    }    else if (name === 'length') {
      setLength(value);
    }  else if (name === 'adtitle') {
      setAdtitle(value);
    }  else if (name === 'contract') {
      setContract(value);
    }  else if (name === 'status') {
      setStatus(value);
    }   else if (name === 'contact') {
      setContact(value);
    }  
    
           
    
    else if (name === 'image') {
      setImages(files[0]);
    }   else if (name === 'video') {
      setvideo(value);
    }  
    
  }

  function validateForm() {
    const errors = {};
  
    if (!client || !/^[a-zA-Z\s]+$/.test(client)) {
      errors.client = 'Client is required and should contain only letters';
    }
  
  
    if (!adtitle || !/^[a-zA-Z\s]+$/.test(adtitle)) {
      errors.adtitle = 'Adtitle is required and should contain only letters';
    }
  
    if (!email || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
      errors.email = 'Email is required and should be a valid email address';
    }

if (!contact || !/^\d{10}$/.test(contact)) {
  errors.contact = 'Contact is required and should be a 10-digit number';
}

if (!contract) {
      errors.contract = 'Contract is required';
    }
  
if (!length) {
      errors.length = 'Length is required';
    }

if (!description) {
      errors.description = 'Description is required';
    }
 
  
   
    if (!image) {
      errors.image = 'Image is required';
    }
   
    if (!video) {
      errors.video = 'Video is required';
    }
 
    setFormErrors(errors);
  
    return Object.keys(errors).length === 0;
  }

  function handleSubmit(e) {
    e.preventDefault();
    if (validateForm()) {

      setLoading(true);
    const data = new FormData();
    data.append('client', client); // Use the correct variable name here (it was mistakenly named "client" in the provided code)
    data.append('adtitle', adtitle);
    data.append('email', email);
    data.append('adtitle', adtitle);
    data.append('status', status);
    data.append('description', description);
    data.append('length', length);
    data.append('contact', contact);
    data.append('image', image); // Use the correct variable name here (it was mistakenly named "video" in the provided code)
    data.append('contract', contract);
    data.append('video', video); // Use the correct variable name here (it was mistakenly named "image" in the provided code)
    data.append('userid', userid); 
    axios
      .post('https://api.bookmyfunctionhall.com/api/Marketing', data, {
        headers: { 'Content-Type': 'multipart/form-data' ,'Authorization':"Bearer "+token}
      })
      .then((resp) => {
        if (resp.data && resp.data[0].status === 'success') {
          //console.log('Data Added');
          toast.success('Data Added!!!');
          window.location.href = '/marketingindex';
        } else {
          //console.log('Error');
          toast.error('Error occurred while adding data');
        }
      })
      .catch((error) => {
        //console.log('Error', error);
        toast.error('An error occurred while making the request');
      });
    }
}
  



 
useEffect(() => {
  const idd = localStorage.getItem('id');
  if (idd) {
    setUserid(idd); 
  }
}, []); 


  return (
    <React.Fragment>
      <ToastContainer />
      <div className="layout-wrapper layout-content-navbar">
        <div className="layout-container">
          {/* <Sidebar /> */}
          <Dashboard/>
          <div className="layout-page">
            <Header />
            <div className="content-wrapper">
              <div className="container-xxl flex-grow-1 container-p-y">
                <h4 className="fw-bold py-3 mb-4">
                  <span className="text-muted fw-light">Marketing / Add</span>
                </h4>
                <div className="row">
                  <div className="col-xl">
                    <div className="card mb-4 col-md-6">
                      <div className="card-header d-flex justify-content-between align-items-center">
                        <h5 className="mb-0">Add</h5>
                      </div>
                      <div className="card-body">






                      <form>









                        


                      <div className="mb-3">
     
     <div className="input-group input-group-merge">
      
       <input
         type="hidden"
         name="userid"
         className="form-control"
         id="basic-icon-default-fullname"
         placeholder="John Doe"
         aria-label="John Doe"
         aria-describedby="basic-icon-default-fullname2"
         onChange={(event) => setUserid(event.target.value)}
         value={userid} // Set the initial value to the "userid" state
         readOnly
       />
     </div>
   </div>

     
   <div className="mb-3">
                            <label className="form-label" htmlFor="basic-icon-default-fullname">Client</label>
                            <div className="input-group input-group-merge">
                              <span className="input-group-text"><i className="bx bx-user"></i></span>
                              <input
                                type="text"
                                name="client"
                                className={`form-control ${formErrors.client ? 'is-invalid' : ''}`}
                                id="basic-icon-default-fullname"
                                placeholder="Client Name"
                                aria-label="Client Name"
                                aria-describedby="basic-icon-default-fullname2"
                                onChange={client=>setClient(client.target.value)} value={client}
                            
                              />
                                 {formErrors.client && (
                                <div className="invalid-feedback">{formErrors.client}</div>
                              )}
                            </div>
                          </div>



  
                          <div className="mb-3">
                            <label className="form-label" htmlFor="basic-icon-default-fullname">contact</label>
                            <div className="input-group input-group-merge">
                              <span className="input-group-text"><i className="bx bx-user"></i></span>
                              <input
                                type="text"
                                name="contact"
                                className={`form-control ${formErrors.contact ? 'is-invalid' : ''}`}
                                id="basic-icon-default-fullname"
                                placeholder="contact Name"
                                aria-label="contact Name"
                                aria-describedby="basic-icon-default-fullname2"
                                onChange={contact=>setContact(contact.target.value)} value={contact}
                               
                              />
                                {formErrors.contact && (
                                <div className="invalid-feedback">{formErrors.contact}</div>
                              )}
                            </div>
                          </div>



                          <div className="mb-3">
                            <label className="form-label" htmlFor="basic-icon-default-fullname">email</label>
                            <div className="input-group input-group-merge">
                              <span className="input-group-text"><i className="bx bx-user"></i></span>
                              <input
                                type="text"
                                name="email"
                                className={`form-control ${formErrors.email ? 'is-invalid' : ''}`}
                                id="basic-icon-default-fullname"
                                placeholder="email Name"
                                aria-label="email Name"
                                aria-describedby="basic-icon-default-fullname2"
                                onChange={email=>setEmail(email.target.value)} value={email}

                              />
                                  {formErrors.email && (
                                <div className="invalid-feedback">{formErrors.email}</div>
                              )}

                            </div>
                          </div>



                          <div className="mb-3">
                            <label className="form-label" htmlFor="basic-icon-default-fullname">adtitle</label>
                            <div className="input-group input-group-merge">
                              <span className="input-group-text"><i className="bx bx-user"></i></span>
                              <input
                                type="text"
                                name="adtitle"
                                className={`form-control ${formErrors.adtitle ? 'is-invalid' : ''}`}
                                id="basic-icon-default-fullname"
                                placeholder="adtitle Name"
                                aria-label="adtitle Name"
                                aria-describedby="basic-icon-default-fullname2"
                                onChange={adtitle=>setAdtitle(adtitle.target.value)} value={adtitle}

                              />
                                {formErrors.adtitle && (
                                <div className="invalid-feedback">{formErrors.adtitle}</div>
                              )}
                            </div>
                          </div>





  
                          <div className="mb-3">
                            <label className="form-label" htmlFor="basic-icon-default-fullname">description</label>
                            <div className="input-group input-group-merge">
                              <span className="input-group-text"><i className="bx bx-user"></i></span>
                              <input
                                type="text"
                                name="description"
                                className={`form-control ${formErrors.description ? 'is-invalid' : ''}`}
                                id="basic-icon-default-fullname"
                                placeholder="description Name"
                                aria-label="description Name"
                                aria-describedby="basic-icon-default-fullname2"
                                onChange={description=>setDescription(description.target.value)} value={description}

                              />
                                  {formErrors.description && (
                                <div className="invalid-feedback">{formErrors.description}</div>
                              )}

                            </div>
                          </div>



                          <div className="mb-3">
                            <label className="form-label" htmlFor="basic-icon-default-fullname">video</label>
                            <div className="input-group input-group-merge">
                              <span className="input-group-text"><i className="bx bx-user"></i></span>
                              <input
                                type="text"
                                name="video"
                                className={`form-control ${formErrors.video ? 'is-invalid' : ''}`}
                                id="basic-icon-default-fullname"
                                placeholder="video Name"
                                aria-label="video Name"
                                aria-describedby="basic-icon-default-fullname2"
                                onChange={video=>setvideo(video.target.value)} value={video}

                              />
                                {formErrors.video && (
                                <div className="invalid-feedback">{formErrors.video}</div>
                              )}
                            </div>
                          </div>




                          <div className="mb-3">
                            <label className="form-label" htmlFor="basic-icon-default-fullname">length</label>
                            <div className="input-group input-group-merge">
                              <span className="input-group-text"><i className="bx bx-user"></i></span>
                              <input
                                type="text"
                                name="length"
                                className={`form-control ${formErrors.length ? 'is-invalid' : ''}`}
                                id="basic-icon-default-fullname"
                                placeholder="length Name"
                                aria-label="length Name"
                                aria-describedby="basic-icon-default-fullname2"
                                onChange={length=>setLength(length.target.value)} value={length}

                              />
                                   {formErrors.length && (
                                <div className="invalid-feedback">{formErrors.length}</div>
                              )}
                            </div>
                          </div>



                          <div className="mb-3">
                            <label className="form-label" htmlFor="basic-icon-default-fullname">contract</label>
                            <div className="input-group input-group-merge">
                              <span className="input-group-text"><i className="bx bx-user"></i></span>
                              <input
                                type="text"
                                name="contract"
                                className={`form-control ${formErrors.contract ? 'is-invalid' : ''}`}
                                id="basic-icon-default-fullname"
                                placeholder="contract Name"
                                aria-label="contract Name"
                                aria-describedby="basic-icon-default-fullname2"
                                onChange={contract=>setContract(contract.target.value)} value={contract}

                              />
                                   {formErrors.contract && (
                                <div className="invalid-feedback">{formErrors.contract}</div>
                              )}
                            </div>
                          </div>




                          <div className="mb-3">
                          <label className="form-label" for="basic-icon-default-fullname">Status</label>
                          <select class="form-control" name="status" onChange={status=>setStatus(status.target.value)} value={status}>
                                             <option value="active">Active</option>
                                             <option value="Inactive">Inactive</option>
                                           </select>
                        </div>     

  


                          <div className="mb-3">
                            <label className="form-label" htmlFor="basic-icon-default-images">Image</label>
                            <div className="input-group input-group-merge">
                              <span className="input-group-text"><i className="bx bx-envelope"></i></span>
                              <input
                                type="file"
                                name="image"
                                id="basic-icon-default-images"
                                className={`form-control ${formErrors.image ? 'is-invalid' : ''}`}
                                placeholder="Image File"
                                aria-label="Image File"
                                aria-describedby="basic-icon-default-images2"
                                onChange={handleChange}
                              />
                                {formErrors.image && (
                                <div className="invalid-feedback">{formErrors.image}</div>
                              )}
                            </div>
                            <div className="form-text">You can use letters, numbers & periods</div>
                          </div>
                          <button type="submit" onClick={handleSubmit} className="btn btn" style={{ backgroundColor: "rgb(127, 1, 75)", color: "#fff" }} disabled={loading}>
                  {loading ? (
                    <img src={Spinner} alt="spinner" style={{ width:"30px" }} />
                  ) : (
                    'Submit'
                  )}
                  </button>


                      </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Footer />
              <div className="content-backdrop fade"></div>
            </div>
          </div>
        </div>
        <div className="layout-overlay layout-menu-toggle"></div>
      </div>

      <div className="buy-now">
        <a
          href="https://themeselection.com/products/sneat-bootstrap-html-admin-template/"
          target="_blank"
          className="btn btn-danger btn-buy-now"
        >
          Upgrade to Pro
        </a>
      </div>
    </React.Fragment>
  );
}

export default MarketingAdd;
