import React, {  useState } from 'react'
import Footer from '../Footer';
import Header from '../Header';
import Sidebar from '../Sidebar';
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axiosInstance from '../../axiosConfig';
import Spinner from '../spinner.gif';



function PhotoAdd() {
    const[name,setName]=useState('');
    const[status,setStatus] = useState('');
   
    const [formErrors, setFormErrors] = useState({}); // Initialize form error state
    const [loading, setLoading] = useState(false);

    function validateForm() {
      const errors = {};
    
      if (!name || !/^[a-zA-Z\s]+$/.test(name)) {
        errors.name = 'Name is required and should contain only letters';
      }
    
      if (!status) {
            errors.status = 'Status is required';
          }
        
      
    
      setFormErrors(errors);
    
      return Object.keys(errors).length === 0;
    }
    function handleSubmit()
    {

      
    if (validateForm()) {

      setLoading(true);
              const data={
            "name":name,            
            "status":status,
            
        };

        //console.log(data);
        axiosInstance.post('https://api.bookmyfunctionhall.com/api/Tag', data)
  .then((resp) => {
    const data = resp.data;
    //console.log(resp);
    if (data[0].status === "success") {
      //console.log("Data Added");
      toast.success('Data Added!!!');
      window.location.href = '/tagindex';
    } else {
      toast.error('Sorry, an error occurred while adding data.');
      //console.log("Error");
    }
  })
  .catch((error) => {
    console.error("Request failed:", error);
    toast.error('Sorry, an error occurred while sending the request.');
  });

  



    }

        
    }












  return (
    <React.Fragment>
       <ToastContainer />
      <div className="layout-wrapper layout-content-navbar">
        <div className="layout-container">
          <Sidebar />
          <div className="layout-page">
            <Header />
            <div className="content-wrapper">
              <div className="container-xxl flex-grow-1 container-p-y">
                <h4 className="fw-bold py-3 mb-4">
                  <span className="text-muted fw-light">Tag add/</span> 
                </h4>
                <div className="row">
                  <div className="col-xl">
                    <div className="card mb-4 col-md-6">
                      <div className="card-header d-flex justify-content-between align-items-center">

                        <small className="text-muted float-end"></small>
                      </div>
                      <div className="card-body">
                      <form >
                      <div className="mb-3">
                          <label className="form-label" for="basic-icon-default-fullname">Full Name</label>
                          <div className="input-group input-group-merge">
                            <span id="basic-icon-default-fullname2" className="input-group-text">
                              <i className="bx bx-user"></i>
                            </span>
                            <input
                              type="text"
                              name="name"
                              className={`form-control ${formErrors.name ? 'is-invalid' : ''}`}
                              id="basic-icon-default-fullname"
                              placeholder="John Doe"
                              aria-label="John Doe"
                              aria-describedby="basic-icon-default-fullname2"
                              onChange={name => setName(name.target.value)} value={name}
                            />
                              {formErrors.name && (
                                <div className="invalid-feedback">{formErrors.name}</div>
                              )}
                          </div>
                        </div>



                        <div className="mb-3">
                          <label className="form-label" for="basic-icon-default-fullname">Status</label>
                          <select class="form-control" name="status" onChange={status=>setStatus(status.target.value)} value={status}>
                                             <option value="active">Active</option>
                                             <option value="Inactive">Inactive</option>
                                           </select>
                        </div>     





                        <button type="submit" onClick={handleSubmit} className="btn btn" style={{ backgroundColor: "rgb(127, 1, 75)", color: "#fff" }} disabled={loading}>
                  {loading ? (
                    <img src={Spinner} alt="spinner" style={{ width:"30px" }} />
                  ) : (
                    'Submit'
                  )}
                  </button>
                      </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Footer />
              <div className="content-backdrop fade"></div>
            </div>
          </div>
        </div>
        <div className="layout-overlay layout-menu-toggle"></div>
      </div>

      <div className="buy-now">
        <a
          href="https://themeselection.com/products/sneat-bootstrap-html-admin-template/"
          target="_blank"
          className="btn btn-danger btn-buy-now"
        >
          Upgrade to Pro
        </a>
      </div>
    </React.Fragment>
  );
}

export default PhotoAdd;
