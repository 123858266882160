import React, { useEffect, useState } from 'react';
import Footer from '../Footer';
import Header from '../Header';
import Sidebar from '../Sidebar';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../../axiosConfig';
import Dashboard from '../dashboard/Dashboard';

 
function EnquiryIndex() {
  const [Showdata, setShowdata] = useState([]);
  const navigate = useNavigate();

  // Function to fetch user-specific data
  function fetchData() { 
    const token = localStorage.getItem('uid');
    const idd = localStorage.getItem('id');
    const usertype = localStorage.getItem('usertype');

    //console.log(idd); 

    if (!token) {  
      window.location.href = '/logintoken';
      return;
    }

    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };



  if (usertype === 'vendor') {  
    axios
      .get(`https://api.bookmyfunctionhall.com/api/bandvendorfetch/${idd}`, config)
      .then((res) => {
        const data = res.data;
        //console.log(data);
        setShowdata(data);
      })
      .catch((error) => {
        console.error(error);
      });
  } else {
    axios.get(`https://api.bookmyfunctionhall.com/api/enquiry`, config)
    .then((res) => {
      const responseData = res.data;
      //console.log(responseData);
      setShowdata(responseData);
    })
    .catch((error) => {
      console.error("API request error:", error);
    });


    


  }
}
  useEffect(() => {
    fetchData(); // Fetch user-specific data when the component mounts
  }, []);
  

  const destroyToken = () => {
    localStorage.removeItem('uid');
    // Redirect to login route or perform any other desired action
    navigate('/logintoken'); // Redirecting to the login page after destroying the token
  };




  









  
  function deletedata(id) {
    axiosInstance.delete('https://api.bookmyfunctionhall.com/api/enquiry/' + id)
      .then((res) => {
        const data = res.data;
        //console.log(data);
        if (data) {
          //console.log('Data Deleted');
          toast.success('Data Deleted!!!', {
            position: 'top-right',
            autoClose: 5000, // Display success toast for 5 seconds
            hideProgressBar: true,
            newestOnTop: false,
            closeOnClick: true,
            rtl: false,
            pauseOnFocusLoss: true,
            draggable: true,
            pauseOnHover: true,
          });
          window.location.href = '/bandindex';
        } else {
          //console.log('Sorry');
          toast.error('Failed to delete data', { autoClose: 4000 });

        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  return (
    <React.Fragment>
      <div className="layout-wrapper layout-content-navbar">
        <div className="layout-container">
          {/* <Sidebar /> */}
          <Dashboard/>
          <div className="layout-page">
            <Header />
            <div className="content-wrapper">
              <div className="container-xxl flex-grow-1 container-p-y">

                <div class="card">
                  

                  <h5 class="card-header">Band Table</h5>
                  <div class="card-body">
                    <div class="table-responsive text-nowrap">
                      <table class="table table-bordered">
                        <thead>
                          <tr>
                            <th>Name</th>
                            <th>Event_Date</th>
                            <th>mobile</th>
                            <th>guest_count</th>
                            <th>plate_cost</th>
                            <th>address</th>
                            <th>food</th>
                            <th>msg</th>
                            <th>event_time</th>
                            <th>email</th>
                            <th>hall_name</th>
                         
                            <th>Operation</th>
                          </tr>
                        </thead>
                        <tbody>
                          {Showdata.map((row) => {
                            return (
                              <tr key={row.id}>
                                <td>{row.name}</td>
                                <td>{row.event_date}</td>
                                <td>{row.mobile}</td>
                                <td>{row.guest_count}</td>
                                <td>{row.plate_cost}</td>
                                <td>{row.address}</td>
                                <td>{row.food}</td>
                                <td>{row.msg}</td>
                                <td>{row.event_time}</td>
                                <td>{row.email}</td>
                                <td>{row.hall_id}</td>
                               
                                <td>
                                  <div class="dropdown">
                                    <button
                                      type="button"
                                      class="btn p-0 dropdown-toggle hide-arrow"
                                      data-bs-toggle="dropdown"
                                    >
                                      <i class="bx bx-dots-vertical-rounded"></i>
                                    </button>
                                    <div class="dropdown-menu">
                                    {/* <a  class="dropdown-item" href="javascript:void(0);" href={'/bandEdit/'+row.id}>Edit</a> */}

                                      <a class="dropdown-item" href="javascript:void(0);" onClick={() => deletedata(row.id)}>
                                        <i class="bx bx-trash me-1"></i> Delete
                                      </a>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>

              </div>
              <Footer />
              <div className="content-backdrop fade"></div>
            </div>
          </div>
        </div>
        <div className="layout-overlay layout-menu-toggle"></div>
      </div>

      <div className="buy-now">
        <a
          href="https://themeselection.com/products/sneat-bootstrap-html-admin-template/"
          target="_blank"
          className="btn btn-danger btn-buy-now"
        >
          Upgrade to Pro
        </a>
      </div>
    </React.Fragment>
  );
}

export default EnquiryIndex;
