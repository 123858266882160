import React, { useEffect } from 'react';
import Sidebar from '../Sidebar';
import VendorSidebar from '../VendorSidebar';

function Dashboard() {
  const token = localStorage.getItem('uid');
  const usertype = localStorage.getItem('usertype');

  useEffect(() => {
    /* if (!token) {
      window.location.href = '/logintoken';
      return;
    }

    if (usertype === 'admin') {
      return
    } else if (usertype === 'vendor') { 
      window.location.href = '/vendor'; 
    } */
  }, [token, usertype]);

  if(usertype == 'admin'){
    return <Sidebar/>

  }
  else{
    return <VendorSidebar/>
  }
  
}

export default Dashboard;
