import React, { useState,useEffect } from 'react';
import Footer from '../Footer';
import Header from '../Header';
import Sidebar from '../Sidebar';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import  axiosInstance  from '../../axiosConfig';
import Dashboard from '../dashboard/Dashboard';
import Spinner from '../spinner.gif';

function MakeupAdd() {
  const [bname, setBname] = useState('');
  const [address, setAddress] = useState('');
  const [contact, setContact] = useState('');
  const [experience, setExperience] = useState('');
  const [image, setImage] = useState(null); // Use null for initial value
  const [link, setLink] = useState('');
  const [details, setDetails] = useState('');
  const [fora, setFora] = useState('');
  const [userid, setUserid] = useState('');

  const [formErrors, setFormErrors] = useState({}); // Initialize form error state
  const [loading, setLoading] = useState(false);

  const token = localStorage.getItem('uid');



  function handleChange(e) {
    if (e.target.name === 'image') {
      setImage(e.target.files[0]);
    } else if (e.target.name === 'bname') {
      setBname(e.target.value);
    }  else if (e.target.name === 'contact') {
      setContact(e.target.value);
    } else if (e.target.name === 'experience') {
      setExperience(e.target.value);
    } else if (e.target.name === 'address') {
      setAddress(e.target.value);
    } else if (e.target.name === 'link') {
      setLink(e.target.value);
    }else if (e.target.name === 'details') {
      setDetails(e.target.value);
    }else if (e.target.name === 'for') {
      setFora(e.target.value);
    }else if (e.target.name === 'userid') {
      setUserid(e.target.value);
    }
  }

  function validateForm() {
    const errors = {};
  
    
    if (!bname || !/^[a-zA-Z\s]+$/.test(bname)) {
      errors.bname = 'Name is required and should contain only letters';
    }
  
    
   
if (!contact || !/^\d{10}$/.test(contact)) {
  errors.contact = 'Contact is required and should be a 10-digit number';
}

if (!address) {
      errors.address = 'Address is required';
    }
  
    
if (!experience) {
      errors.experience = 'Experience is required';
    }
  
    
if (!details) {
      errors.details = 'Details is required';
    }
  
   
    
if (!link) {
      errors.link = 'Link is required';
    }
  
   
    
if (!fora) {
      errors.fora = 'Fora is required';
    }
  
   
    
    if (!image) {
      errors.image = 'Image is required';
    }
  
    
  
    setFormErrors(errors);
  
    return Object.keys(errors).length === 0;
  }


  function handleSubmit(e) {
    e.preventDefault();
    if (validateForm()) {

      setLoading(true);
    const data = new FormData();
    data.append('bname', bname);
    data.append('for', fora);
    data.append('address', address);
    data.append('details', details);
    data.append('contact', contact);
    data.append('experience', experience);
    data.append('image', image);
    data.append('link', link);
    data.append('userid', userid);
  
    axios
      .post('https://api.bookmyfunctionhall.com/api/Makeup',  data, {
        headers: { 'Content-Type': 'multipart/form-data' ,'Authorization':"Bearer "+token},
      })
      .then((resp) => {
        if (resp.data && resp.data[0].status === 'success') {
          toast.success('Data Added!!!');
          window.location.href = '/makeupindex';
        } else {
          toast.error('Error occurred while adding data');
        }
      })
      .catch((error) => {
        toast.error('An error occurred while making the request');
      });
    }
  }
  


 
  useEffect(() => {
    const idd = localStorage.getItem('id');
    if (idd) {
      setUserid(idd); 
    } 
  }, []); 



  return (
    <React.Fragment>
       <ToastContainer />
      <div className="layout-wrapper layout-content-navbar">
        <div className="layout-container">
          {/* <Sidebar /> */}
          <Dashboard/>
          <div className="layout-page">
            <Header />
            <div className="content-wrapper">
              <div className="container-xxl flex-grow-1 container-p-y">
                <h4 className="fw-bold py-3 mb-4">
                  <span className="text-muted fw-light">Makeup /Add</span> 
                </h4>
                <div className="row">
                  <div className="col-xl">
                    <div className="card mb-4 col-md-6">
                      <div className="card-header d-flex justify-content-between align-items-center">
                        <h5 className="mb-0">Add</h5>
                      </div>
                      <div className="card-body">
                      <form>





                        
                      <div className="mb-3">
     
     <div className="input-group input-group-merge">
      
       <input
         type="hidden"
         name="userid"
         className="form-control"
         id="basic-icon-default-fullname"
         placeholder="John Doe"
         aria-label="John Doe"
         aria-describedby="basic-icon-default-fullname2"
         onChange={(event) => setUserid(event.target.value)}
         value={userid} // Set the initial value to the "userid" state
         readOnly
       />
     </div>
   </div>




   <div className="mb-3">
                          <label className="form-label" for="basic-icon-default-fullname">Makeup Person Name</label>
                          <div className="input-group input-group-merge">
                            <span id="basic-icon-default-fullname2" className="input-group-text">
                              <i className="bx bx-user"></i>
                            </span>
                            <input
                              type="text"
                              name="bname"
                              className={`form-control ${formErrors.bname ? 'is-invalid' : ''}`}
                              id="basic-icon-default-fullname"
                              placeholder="John Doe"
                              aria-label="John Doe"
                              aria-describedby="basic-icon-default-fullname2"
                              onChange={name => setBname(name.target.value)} value={bname}
                            />
                             {formErrors.bname && (
                                <div className="invalid-feedback">{formErrors.bname}</div>
                              )}

                          </div>
                        </div>


<div className="mb-3">
  <label className="form-label" for="basic-icon-default-fullname">Description</label>
  <div className="input-group input-group-merge">
    <span id="basic-icon-default-fullname2" className="input-group-text"
      ><i className="bx bx-user"></i
    ></span>
    <input
      type="text"
      name="for"
      className={`form-control ${formErrors.fora ? 'is-invalid' : ''}`}
      id="basic-icon-default-fullname"
      placeholder="John Doe"
      aria-label="John Doe"
      aria-describedby="basic-icon-default-fullname2"
      onChange={fora=>setFora(fora.target.value)} value={fora}
    />
 {formErrors.fora && (
                                <div className="invalid-feedback">{formErrors.fora}</div>
                              )}
  </div>
</div>




<div className="mb-3">
  <label className="form-label" for="basic-icon-default-fullname">Details</label>
  <div className="input-group input-group-merge">
    <span id="basic-icon-default-fullname2" className="input-group-text"
      ><i className="bx bx-user"></i
    ></span>
    <input
      type="text"
      name="details"
      className={`form-control ${formErrors.details ? 'is-invalid' : ''}`}
      id="basic-icon-default-fullname"
      placeholder="John Doe"
      aria-label="John Doe"
      aria-describedby="basic-icon-default-fullname2"
      onChange={details=>setDetails(details.target.value)} value={details}
    />
 {formErrors.details && (
                                <div className="invalid-feedback">{formErrors.details}</div>
                              )}
  </div>
</div>



<div className="mb-3">
  <label className="form-label" for="basic-icon-default-company">contact</label>
  <div className="input-group input-group-merge">
    <span id="basic-icon-default-company2" className="input-group-text"
      ><i className="bx bx-buildings"></i
    ></span>
    <input
      type="text"
      name='contact'
      id="basic-icon-default-company"
      className={`form-control ${formErrors.contact ? 'is-invalid' : ''}`}
      placeholder="ACME Inc."
      aria-label="ACME Inc."
      aria-describedby="basic-icon-default-company2"
      onChange={contact=>setContact(contact.target.value)} value={contact}
    />
     {formErrors.contact && (
                                <div className="invalid-feedback">{formErrors.contact}</div>
                              )}
  </div>

</div>

<div className="mb-3">
  <label className="form-label" for="basic-icon-default-email">address</label>
  <div className="input-group input-group-merge">
    <span className="input-group-text"><i className="bx bx-envelope"></i></span>
    <input
      type="text"
      name="address"
      id="basic-icon-default-email"
      className={`form-control ${formErrors.address ? 'is-invalid' : ''}`}
      placeholder="john.doe"
      aria-label="john.doe"
      aria-describedby="basic-icon-default-email2"
      onChange={address=>setAddress(address.target.value)} value={address}
    />
 {formErrors.address && (
                                <div className="invalid-feedback">{formErrors.address}</div>
                              )}

   
  </div>
  <div className="form-text">You can use letters, numbers & periods</div>
</div>

<div className="mb-3">
  <label className="form-label" for="basic-icon-default-email">experience</label>
  <div className="input-group input-group-merge">
    <span className="input-group-text"><i className="bx bx-envelope"></i></span>
    <input
      type="text"
      name="experience"
      id="basic-icon-default-email"
      className={`form-control ${formErrors.experience ? 'is-invalid' : ''}`}
      placeholder="john.doe"
      aria-label="john.doe"
      aria-describedby="basic-icon-default-email2"
      onChange={experience=>setExperience(experience.target.value)} value={experience}

    />
   {formErrors.experience && (
                                <div className="invalid-feedback">{formErrors.experience}</div>
                              )}
  </div>
  <div className="form-text">You can use letters, numbers & periods</div>
</div>



<div className="mb-3">
  <label className="form-label" for="basic-icon-default-email">Image</label>
  <div className="input-group input-group-merge">
    <span className="input-group-text"><i className="bx bx-envelope"></i></span>
    <input
      type="file"
      name="image"
      id="basic-icon-default-email"
      className={`form-control ${formErrors.image ? 'is-invalid' : ''}`}
      placeholder="john.doe"
      aria-label="john.doe"
      aria-describedby="basic-icon-default-email2"
      onChange={handleChange}

    />
    {formErrors.image && (
                                <div className="invalid-feedback">{formErrors.image}</div>
                              )}
  </div>
  <div className="form-text">You can use letters, numbers & periods</div>
</div>


<div className="mb-3">
  <label className="form-label" for="basic-icon-default-email">link</label>
  <div className="input-group input-group-merge">
    <span className="input-group-text"><i className="bx bx-envelope"></i></span>
    <input
      type="text"
      name="link"
      id="basic-icon-default-email"
      className={`form-control ${formErrors.link ? 'is-invalid' : ''}`}
      placeholder="john.doe"
      aria-label="john.doe"
      aria-describedby="basic-icon-default-email2"
      onChange={link=>setLink(link.target.value)} value={link}

    />
   {formErrors.link && (
                                <div className="invalid-feedback">{formErrors.link}</div>
                              )}
  </div>
  <div className="form-text">You can use letters, numbers & periods</div>
</div>




                        <button onClick={handleSubmit}  type="submit" className="btn btn" style={{ backgroundColor: "rgb(127, 1, 75)", color: "#fff" }} disabled={loading}>
                  {loading ? (
                    <img src={Spinner} alt="spinner" style={{ width:"30px" }} />
                  ) : (
                    'Submit'
                  )}
                  </button>
                     
                      </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Footer />
              <div className="content-backdrop fade"></div>
            </div>
          </div>
        </div>
        <div className="layout-overlay layout-menu-toggle"></div>
      </div>

      <div className="buy-now">
        <a
          href="https://themeselection.com/products/sneat-bootstrap-html-admin-template/"
          target="_blank"
          className="btn btn-danger btn-buy-now"
        >
          Upgrade to Pro
        </a>
      </div>
    </React.Fragment>
  );
}

export default MakeupAdd;
