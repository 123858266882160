import React, { useState, useEffect } from 'react';
import Footer from '../Footer';
import Header from '../Header';
import Sidebar from '../Sidebar';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useParams } from 'react-router-dom';
import Dashboard from '../dashboard/Dashboard';
import Spinner from '../spinner.gif';

function LightingEdit() {
  const [name, setName] = useState('');
  const [type, setType] = useState('');
  const [address, setAddress] = useState('');
  const [contact, setContact] = useState('');
  const [details, setDetails] = useState('');
  const [userid, setUserid] = useState('');

  const { id } = useParams();
  const [formErrors, setFormErrors] = useState({});
  const [loading, setLoading] = useState(false);

  
  const token = localStorage.getItem('uid');

  const config = {      
    headers: { 'Content-Type': 'multipart/form-data' ,'Authorization':"Bearer "+token}
};

  function updatedata() {
    axios
      .get('https://api.bookmyfunctionhall.com/api/Lighting/' + id,config)
      .then((res) => {
        const data = res.data;
        setName(data.name);
        setType(data.type);
        setAddress(data.address);
        setContact(data.contact);
        setDetails(data.details);
        setUserid(data.userid);
      })
      .catch((error) => {
        console.error('Error', error);
      });
  }

 
  function validateForm() {
    const errors = {};
  
    
    if (!name || !/^[a-zA-Z\s]+$/.test(name)) {
      errors.name = 'name is required and should contain only letters';
    }
  
    
    if (!type || !/^[a-zA-Z\s]+$/.test(type)) {
      errors.type = 'type is required and should contain only letters';
    }
  
   
if (!contact || !/^\d{10}$/.test(contact)) {
  errors.contact = 'Contact is required and should be a 10-digit number';
}

if (!address) {
      errors.address = 'Address is required';
    }
  
    
if (!details) {
  errors.details = 'details is required';
}

    
    setFormErrors(errors);
  
    return Object.keys(errors).length === 0;
  }
  
  function updateCategory(e) {
    e.preventDefault();

    if (validateForm()) {
      setLoading(true);
      const formData = new FormData();

      formData.append('_method', 'put');
      formData.append('name', name);
      formData.append('contact', contact);
      formData.append('address', address);
      formData.append('details', details);
      formData.append('userid', userid);
      formData.append('type', type);

      axios
        .post(`https://api.bookmyfunctionhall.com/api/Lighting/${id}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${token}`,
          },
        })
        .then((resp) => {
          const data = resp.data;
          if (data.status === 'success') {
            toast.success('Data Updated!!!', { autoClose: 2000 });
            window.location.href = '/lightingindex';
          } else {
            //toast.error('Error occurred while updating data', { autoClose: 2000 });

            toast.success('Data Updated!!!', { autoClose: 2000 });
            window.location.href = '/lightingindex';
          }
        })
        .catch((error) => {
          console.error('Error:', error);
          toast.error('An error occurred while making the request', { autoClose: 2000 });
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }

  useEffect(() => {
    updatedata();
    const idd = localStorage.getItem('id');
    if (idd) {
      setUserid(idd);
    }
  }, []);

  return (
    <React.Fragment>
    <ToastContainer />
    <div className="layout-wrapper layout-content-navbar">
      <div className="layout-container">
        {/* <Sidebar /> */}
        <Dashboard/>
        <div className="layout-page">
          <Header />
          <div className="content-wrapper">
            <div className="container-xxl flex-grow-1 container-p-y">
              <h4 className="fw-bold py-3 mb-4">
                <span className="text-muted fw-light">Lighting /</span>Edits
              </h4>
              <div className="row">
                <div className="col-xl">
                  <div className="card mb-4 col-md-6">
                    <div className="card-header d-flex justify-content-between align-items-center">
                      <h5 className="mb-0">Lighting</h5>
                    </div>
                    <div className="card-body">
                      <form onSubmit={updateCategory}>



                      <div className="mb-3">
     
     <div className="input-group input-group-merge">
      
       <input
         type="hidden"
         name="userid"
         className="form-control"
         id="basic-icon-default-fullname"
         placeholder="John Doe"
         aria-label="John Doe"
         aria-describedby="basic-icon-default-fullname2"
         onChange={(event) => setUserid(event.target.value)}
         value={userid} // Set the initial value to the "userid" state
         readOnly
       />
     </div>
   </div>
                        <div className="mb-3">
                          <label className="form-label" for="basic-icon-default-fullname">Full Name</label>
                          <div className="input-group input-group-merge">
                            <span id="basic-icon-default-fullname2" className="input-group-text">
                              <i className="bx bx-user"></i>
                            </span>
                            <input
                              type="text"
                              name="name"
                              className={`form-control ${formErrors.name ? 'is-invalid' : ''}`}
                              id="basic-icon-default-fullname"
                              placeholder="John Doe"
                              aria-label="John Doe"
                              aria-describedby="basic-icon-default-fullname2"
                              onChange={name => setName(name.target.value)} value={name}
                            />
                             {formErrors.name && (
                                <div className="invalid-feedback">{formErrors.name}</div>
                              )}

                          </div>
                        </div>
                        <div className="mb-3">
                          <label className="form-label" for="basic-icon-default-company">type</label>
                          <div className="input-group input-group-merge">
                            <span id="basic-icon-default-company2" className="input-group-text"
                              ><i className="bx bx-buildings"></i
                            ></span>
                            <input
                              type="text"
                              name='type'
                              id="basic-icon-default-company"
                            className={`form-control ${formErrors.type ? 'is-invalid' : ''}`}

                              placeholder="ACME Inc."
                              aria-label="ACME Inc."
                              aria-describedby="basic-icon-default-company2"
                              onChange={type=>setType(type.target.value)} value={type}
                            />
                             {formErrors.type && (
                                <div className="invalid-feedback">{formErrors.type}</div>
                              )}
                          </div>

                        </div>
                        <div className="mb-3">
                          <label className="form-label" for="basic-icon-default-type">address</label>
                          <div className="input-group input-group-merge">
                            <span className="input-group-text"><i className="bx bx-envelope"></i></span>
                            <input
                              type="text"
                              name="address"
                              id="basic-icon-default-type"
                                  className={`form-control ${formErrors.address ? 'is-invalid' : ''}`}

                              placeholder="john.doe"
                              aria-label="john.doe"
                              aria-describedby="basic-icon-default-type2"
                              onChange={address=>setAddress(address.target.value)} value={address}
                            />
                             {formErrors.address && (
                                <div className="invalid-feedback">{formErrors.address}</div>
                              )}


                            
                          </div>
                          
                        </div>

                        <div className="mb-3">
                          <label className="form-label" for="basic-icon-default-type">contact</label>
                          <div className="input-group input-group-merge">
                            <span className="input-group-text"><i className="bx bx-envelope"></i></span>
                            <input
                              type="text"
                              name="contact"
                              id="basic-icon-default-type"
                                 className={`form-control ${formErrors.contact ? 'is-invalid' : ''}`}

                              placeholder="john.doe"
                              aria-label="john.doe"
                              aria-describedby="basic-icon-default-type2"
                              onChange={contact=>setContact(contact.target.value)} value={contact}

                            />

                                  {formErrors.contact && (
                                <div className="invalid-feedback">{formErrors.contact}</div>
                              )}

                            
                          </div>
                          
                        </div>



                        <div className="mb-3">
                            <label className="form-label" for="basic-icon-default-type">details</label>
                            <div className="input-group input-group-merge">
                              <span className="input-group-text"><i className="bx bx-envelope"></i></span>
                              <input
                                type="text"
                                name="details"
                                id="basic-icon-default-type"
                              className={`form-control ${formErrors.name ? 'is-invalid' : ''}`}

                                placeholder="john.doe"
                                aria-label="john.doe"
                                aria-describedby="basic-icon-default-type2"
                                onChange={details => setDetails(details.target.value)} value={details}
                              />
                               {formErrors.details && (
                                <div className="invalid-feedback">{formErrors.details}</div>
                              )}

                              
                            </div>
                            
                          </div>

                          {/* <button type="submit" className="btn btn-info">Update</button> */}

                          
                        <button type="submit" className="btn btn" style={{ backgroundColor: "rgb(127, 1, 75)", color: "#fff" }} disabled={loading}>
                  {loading ? (
                    <img src={Spinner} alt="spinner" style={{ width:"30px" }} />
                  ) : (
                    'Submit'
                  )}
                  </button>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Footer />
              <div className="content-backdrop fade"></div>
            </div>
          </div>
        </div>
        <div className="layout-overlay layout-menu-toggle"></div>
      </div>

      <div className="buy-now">
        <a
          href="https://themeselection.com/products/sneat-bootstrap-html-admin-template/"
          target="_blank"
          className="btn btn-danger btn-buy-now"
        >
          Upgrade to Pro
        </a>
      </div>
    </React.Fragment>
  );
}

export default LightingEdit;
