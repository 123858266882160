import React, { useState,useEffect } from 'react';
import Footer from '../Footer';
import Header from '../Header';
import Sidebar from '../Sidebar';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import  axiosInstance  from '../../axiosConfig';
import Dashboard from '../dashboard/Dashboard';
import Spinner from '../spinner.gif';

function ChefCreate() {
  const [name, setName] = useState('');
  const [type, setType] = useState('');
  const [image, setImage] = useState(null);
  const [contact, setContact] = useState('');
  const [address, setAddress] = useState('');
  const [speciality, setSpeciality] = useState('');
  const [details, setDetails] = useState('');
  const [userid, setUserid] = useState('');
  const [formErrors, setFormErrors] = useState({}); // Initialize form error state
  const [loading, setLoading] = useState(false);


  const token = localStorage.getItem('uid');
 
  function validateForm() {
    const errors = {};


    if (!speciality) {
      errors.speciality = 'speciality is required and should contain only letters';
    }

    if (!type) {
      errors.type = 'type is required ';
    }

    if (!name || !/^[a-zA-Z\s]+$/.test(name)) {
      errors.name = 'Name is required and should contain only letters';
    }

    if (!contact || !/^\d{10}$/.test(contact)) {
      errors.contact = 'Contact is required and should be a 10-digit number';
    }

    if (!address) {
      errors.address = 'Address is required';
    }

    if (!image) {
      errors.image = 'image Image is required';
    }

    


    setFormErrors(errors);

    return Object.keys(errors).length === 0;
  }

 



  function handleChange(e) {
    if (e.target.name === 'image') {
      setImage(e.target.files[0]);
    } else if (e.target.name === 'type') {
      setType(e.target.value);
    } else if (e.target.name === 'name') {
      setName(e.target.value);
    } else if (e.target.name === 'contact') {
      setContact(e.target.value);
    } else if (e.target.name === 'speciality') {
      setSpeciality(e.target.value);
    } else if (e.target.name === 'address') {
      setAddress(e.target.value);
    } else if (e.target.name === 'details') {
      setDetails(e.target.value);
    }
  }

  function handleSubmit(e) {
    e.preventDefault();

    
 if (validateForm()) {

  setLoading(true);

    const data = new FormData(); // Use FormData for file uploads
    data.append('name', name);
    data.append('type', type);
    data.append('image', image); // Append the file to FormData
    data.append('contact', contact);
    data.append('address', address);
    data.append('speciality', speciality);
    data.append('details', details);
    data.append('userid', userid);

    axios
      .post('https://api.bookmyfunctionhall.com/api/Chef', data, {
        headers: { 'Content-Type': 'multipart/form-data' ,'Authorization':"Bearer "+token}
      })
      .then((resp) => { 
        if (resp.data && resp.data[0].status === 'success') {
          //console.log('Data Added');
          toast.success('Data Added!!!');
          window.location.href = '/chefindex';
        } else {
          console.log('Error');
          toast.error('Error occurred while adding data');
        }
      })
      .catch((error) => {
        console.log('Error', error);
        toast.error('An error occurred while making the request');
      })
      .finally(() => {
        setLoading(false); // Stop loading
      });
  }
  }
  

  useEffect(() => {
    const idd = localStorage.getItem('id');
    if (idd) {
      setUserid(idd); 
    }
  }, []); 



  return (
    <React.Fragment>
       <ToastContainer />
      <div className="layout-wrapper layout-content-navbar">
        <div className="layout-container">
          {/* <Sidebar /> */}
          <Dashboard/>
          <div className="layout-page">
            <Header />
            <div className="content-wrapper">
              <div className="container-xxl flex-grow-1 container-p-y">
                <h4 className="fw-bold py-3 mb-4">
                  <span className="text-muted fw-light">Chef / ADD</span> 
                </h4>
                <div className="row">
                  <div className="col-xl">
                    <div className="card mb-4 col-md-6">
                      <div className="card-header d-flex justify-content-between align-items-center">
                        <h5 className="mb-0">Chef ADD</h5>
                        <small className="text-muted float-end">Chef ADD</small>
                      </div>
                      <div className="card-body">
                      <form>


                        
                      <div className="mb-3">
     
     <div className="input-group input-group-merge">
      
       <input
         type="hidden"
         name="userid"
        className='form-control'
         id="basic-icon-default-fullname"
         placeholder="John Doe"
         aria-label="John Doe"
         aria-describedby="basic-icon-default-fullname2"
         onChange={(event) => setUserid(event.target.value)}
         value={userid} // Set the initial value to the "userid" state
         readOnly
       />
     </div>
   </div>



                      <div className="mb-3">
                          <label className="form-label" for="basic-icon-default-fullname">Name</label>
                          <div className="input-group input-group-merge">
                            <span id="basic-icon-default-fullname2" className="input-group-text"
                              ><i className="bx bx-user"></i
                            ></span>
                            <input
                              type="text"
                              name="name"
                              className={`form-control ${formErrors.name ? 'is-invalid' : ''}`}
                              id="basic-icon-default-fullname"
                              placeholder="John Doe"
                              aria-label="John Doe"
                              aria-describedby="basic-icon-default-fullname2"
                              onChange={name=>setName(name.target.value)} value={name}
                            />
                            {formErrors.name && (
                                <div className="invalid-feedback">{formErrors.name}</div>
                              )}

                          </div>
                        </div>

                        <div className="mb-3">
                          <label className="form-label" for="basic-icon-default-fullname">type</label>
                          <div className="input-group input-group-merge">
                            <span id="basic-icon-default-fullname2" className="input-group-text"
                              ><i className="bx bx-user"></i
                            ></span>
                            <input
                              type="text"
                              name="type"
                              className={`form-control ${formErrors.type ? 'is-invalid' : ''}`}
                              id="basic-icon-default-fullname"
                              placeholder="John Doe"
                              aria-label="John Doe"
                              aria-describedby="basic-icon-default-fullname2"
                              onChange={type=>setType(type.target.value)} value={type}
                            />
                            {formErrors.type && (
                                <div className="invalid-feedback">{formErrors.type}</div>
                              )}

                          </div>
                        </div>

                        
                        <div className="mb-3">
                          <label className="form-label" for="basic-icon-default-email">Image</label>
                          <div className="input-group input-group-merge">
                            <span className="input-group-text"><i className="bx bx-envelope"></i></span>
                            <input
                              type="file"
                              name="image"
                              id="basic-icon-default-email"
                              className={`form-control ${formErrors.image ? 'is-invalid' : ''}`}
                              placeholder="john.doe"
                              aria-label="john.doe"
                              aria-describedby="basic-icon-default-image2"
                              onChange={handleChange}

                            />
                            {formErrors.image && (
                                <div className="invalid-feedback">{formErrors.image}</div>
                              )}
                            
                          </div>
                          
                        </div>

                    

                        <div className="mb-3">
                          <label className="form-label" for="basic-icon-default-company">contact</label>
                          <div className="input-group input-group-merge">
                            <span id="basic-icon-default-company2" className="input-group-text"
                              ><i className="bx bx-buildings"></i
                            ></span>
                            <input
                              type="text"
                              name='contact'
                              id="basic-icon-default-company"
                              className={`form-control ${formErrors.contact ? 'is-invalid' : ''}`}
                              placeholder="ACME Inc."
                              aria-label="ACME Inc."
                              aria-describedby="basic-icon-default-company2"
                              onChange={contact=>setContact(contact.target.value)} value={contact}
                            />
                            {formErrors.contact && (
                                <div className="invalid-feedback">{formErrors.contact}</div>
                              )}
                          </div>

                        </div>
                     
                        <div className="mb-3">
                          <label className="form-label" for="basic-icon-default-email">address</label>
                          <div className="input-group input-group-merge">
                            <span className="input-group-text"><i className="bx bx-envelope"></i></span>
                            <input
                              type="text"
                              name="address"
                              id="basic-icon-default-email"
                              className={`form-control ${formErrors.address ? 'is-invalid' : ''}`}
                              placeholder="john.doe"
                              aria-label="john.doe"
                              aria-describedby="basic-icon-default-address2"
                              onChange={address=>setAddress(address.target.value)} value={address}
                            />

                              {formErrors.address && (
                                <div className="invalid-feedback">{formErrors.address}</div>
                              )}
                            
                          </div>
                          
                        </div>

                        <div className="mb-3">
                          <label className="form-label" for="basic-icon-default-email">Speciality</label>
                          <div className="input-group input-group-merge">
                            <span className="input-group-text"><i className="bx bx-envelope"></i></span>
                            <input
                              type="text"
                              name="speciality"
                              id="basic-icon-default-email"
                              className={`form-control ${formErrors.speciality ? 'is-invalid' : ''}`}
                              placeholder="john.doe"
                              aria-label="john.doe"
                              aria-describedby="basic-icon-default-speciality2"
                              onChange={speciality=>setSpeciality(speciality.target.value)} value={speciality}

                            />
                            {formErrors.speciality && (
                                <div className="invalid-feedback">{formErrors.speciality}</div>
                              )}
                            
                          </div>
                          
                        </div>




                        <div className="mb-3">
                          <label className="form-label" for="basic-icon-default-email">details</label>
                          <div className="input-group input-group-merge">
                            <span className="input-group-text"><i className="bx bx-envelope"></i></span>
                            <input
                              type="text"
                              name="details"
                              id="basic-icon-default-email"
                              className='form-control'
                              placeholder="john.doe"
                              aria-label="john.doe"
                              aria-describedby="basic-icon-default-email2"
                              onChange={details=>setDetails(details.target.value)} value={details}

                            />
                            
                          </div>
                          
                        </div>




                        <button type="submit" onClick={handleSubmit} className="btn btn" style={{ backgroundColor: "rgb(127, 1, 75)", color: "#fff" }} disabled={loading}>
                  {loading ? (
                    <img src={Spinner} alt="spinner" style={{ width:"30px" }} />
                  ) : (
                    'Submit'
                  )}
                </button>


                        {/* <button type="submit" className="btn btn-primary" onClick={handleSubmit}>Send</button> */}
                      </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Footer />
              <div className="content-backdrop fade"></div>
            </div>
          </div>
        </div>
        <div className="layout-overlay layout-menu-toggle"></div>
      </div>

      <div className="buy-now">
        <a
          href="https://themeselection.com/products/sneat-bootstrap-html-admin-template/"
          target="_blank"
          className="btn btn-danger btn-buy-now"
        >
          Upgrade to Pro
        </a>
      </div>
    </React.Fragment>
  );
}

export default ChefCreate;
