import React, { useState, useEffect } from 'react';
import Footer from '../Footer';
import Header from '../Header';
import Sidebar from '../Sidebar';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom'; 
import  axiosInstance  from '../../axiosConfig';
import Dashboard from '../dashboard/Dashboard'; 
import Spinner from '../spinner.gif';

function CateringEdit() {
  const navigate = useNavigate(); 
  // const[name,setName]=useState('');

  const [type, setType] = useState('');
  const [address, setAddress] = useState('');
  const [contact, setContact] = useState('');
  const [details, setDetails] = useState('');
  const [caterer, setCaterer] = useState(''); // Fix typo
  const [email, setEmail] = useState(''); // Fix typo
  const [speciality, setSpeciality] = useState('');
  const [userid, setUserid] = useState('');
  const [formErrors, setFormErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const { id } = useParams();

  useEffect(() => {
    updatedata();
  }, []);

  function updatedata() {
    axiosInstance.get('https://api.bookmyfunctionhall.com/api/Catering/' + id)
      .then((res) => {
        const data = res.data;
        //console.log(data);

     
        setType(data.type);
        setAddress(data.address);
        setContact(data.contact);
        setDetails(data.details);
        setCaterer(data.caterer);
        setEmail(data.email);
        setSpeciality(data.speciality);
      })
      .catch((error) => {
        //console.log("Error", error);
      });
  }


  
  function validateForm() {
    const errors = {};
  

//  // Validate name using regex (allow only alphabets)
//  if (!name || !/^[a-zA-Z\s]+$/.test(name)) {
//   errors.name = 'Name is required and should contain only letters';
// }

    // Validate type using regex (allow only alphabets)
    if (!type || !/^[a-zA-Z\s]+$/.test(type)) {
      errors.type = 'type is required and should contain only letters';
    }
  

    
    // Validate address
    if (!address) {
      errors.address = 'Address is required';
    }

    if (!caterer) {
      errors.caterer = 'caterer is required';
    }



   
if (!contact || !/^\d{10}$/.test(contact)) {
  errors.contact = 'Contact is required and should be a 10-digit number';
}

  
  
    
 
  
    // Validate eventhistory
    if (!speciality) {
      errors.speciality = 'speciality is required';
    }
  
    setFormErrors(errors);
  
    return Object.keys(errors).length === 0;
  }
  



  function handleSubmit(event) {
    event.preventDefault();
    // if (!type || !address || !caterer || !contact ||!speciality ) {
    //   toast.error('Please fill in all required fields.', { autoClose: 2000 });
    //   return;
    // }
    
    // Create a data object with updated values
    if (validateForm()) {
      setLoading(true);
    
    
    
    
    const data = {
      
      type: type,
      address: address,
      contact: contact,
      details: details,
      caterer: caterer, // Fixed typo in variable name
      email: email, // Fixed typo in variable name
      speciality: speciality,
      userid:userid
    };

    axiosInstance.patch(`https://api.bookmyfunctionhall.com/api/Catering/${id}`, data)
      .then((res) => {
        toast.success('Data Updated!!!', {
          position: 'top-right',
          autoClose: 5000, // Display success toast for 5 seconds
          hideProgressBar: true,
          newestOnTop: false,
          closeOnClick: true,
          rtl: false,
          pauseOnFocusLoss: true,
          draggable: true,
          pauseOnHover: true,
        });        navigate('/cateringindex'); 
      })
      .catch((error) => {
        //console.log('Error', error);
        toast.error('Failed to update data'); // Use a toast to show error message
      })
      .finally(() => {
        setLoading(false);
      });
  }

  }
  useEffect(() => {
    const idd = localStorage.getItem('id');
    if (idd) {
      setUserid(idd); 
    }
  }, []); 

  return (
    <React.Fragment>
    <ToastContainer />
    <div className="layout-wrapper layout-content-navbar">
      <div className="layout-container">
        {/* <Sidebar /> */}
        <Dashboard/>
        <div className="layout-page">
          <Header />
          <div className="content-wrapper">
            <div className="container-xxl flex-grow-1 container-p-y">
              <h4 className="fw-bold py-3 mb-4">
                <span className="text-muted fw-light">Catering / Edit</span> 
              </h4>
              <div className="row">
                <div className="col-xl">
                  <div className="card mb-4 col-md-6">
                    <div className="card-header d-flex justify-content-between align-items-center">
                      
                    </div>
                    <div className="card-body">
                    <form onSubmit={handleSubmit}>
                      

{/* 
                      
                    <div className="mb-3">
                          <label className="form-label" for="basic-icon-default-fullname">Full Name</label>
                          <div className="input-group input-group-merge">
                            <span id="basic-icon-default-fullname2" className="input-group-text"
                              ><i className="bx bx-user"></i
                            ></span>
                            <input
                              type="text"
                              name="name"
                              className={`form-control ${formErrors.name ? 'is-invalid' : ''}`}
                              id="basic-icon-default-fullname"
                              placeholder="John Doe"
                              aria-label="John Doe"
                              aria-describedby="basic-icon-default-fullname2"
                              onChange={name=>setName(name.target.value)} value={name}
                              required
                            />

                            {formErrors.name && (
                                <div className="invalid-feedback">{formErrors.name}</div>
                              )}

                            

                          </div>
                        </div> */}



                        
                    <div className="mb-3">
     
     <div className="input-group input-group-merge">
      
       <input
         type="hidden"
         name="userid"
         className="form-control"
         id="basic-icon-default-fullname"
         placeholder="John Doe"
         aria-label="John Doe"
         aria-describedby="basic-icon-default-fullname2"
         onChange={(event) => setUserid(event.target.value)}
         value={userid} // Set the initial value to the "userid" state
         readOnly
       />
     </div>
                     </div>



                      <div className="mb-3">
                          <label className="form-label" for="basic-icon-default-fullname">Name</label>
                          <div className="input-group input-group-merge">
                            <span id="basic-icon-default-fullname2" className="input-group-text"
                              ><i className="bx bx-user"></i
                            ></span>
                            <input
                              type="text"
                              name="caterer"
                              className={`form-control ${formErrors.caterer ? 'is-invalid' : ''}`}

                              id="basic-icon-default-fullname"
                              placeholder="John Doe"
                              aria-label="John Doe"
                              aria-describedby="basic-icon-default-fullname2"
                              onChange={caterer=>setCaterer(caterer.target.value)} value={caterer}
                            />
                              {formErrors.caterer && (
                                <div className="invalid-feedback">{formErrors.caterer}</div>
                              )}

                          </div>
                        </div>

                        
                      <div className="mb-3">
                          <label className="form-label" for="basic-icon-default-fullname">email</label>
                          <div className="input-group input-group-merge">
                            <span id="basic-icon-default-fullname2" className="input-group-text"
                              ><i className="bx bx-user"></i
                            ></span>
                            <input
                              type="text"
                              name="email"
                              className="form-control"
                              id="basic-icon-default-fullname"
                              placeholder="John Doe"
                              aria-label="John Doe"
                              aria-describedby="basic-icon-default-fullname2"
                              onChange={email=>setEmail(email.target.value)} value={email}
                            />

                          </div>
                        </div>

                        <div className="mb-3">
                          <label className="form-label" for="basic-icon-default-fullname">speciality</label>
                          <div className="input-group input-group-merge">
                            <span id="basic-icon-default-fullname2" className="input-group-text"
                              ><i className="bx bx-user"></i
                            ></span>
                            <input
                              type="text"
                              name="speciality"
                              className={`form-control ${formErrors.speciality ? 'is-invalid' : ''}`}

                              id="basic-icon-default-fullname"
                              placeholder="John Doe"
                              aria-label="John Doe"
                              aria-describedby="basic-icon-default-fullname2"
                              onChange={speciality=>setSpeciality(speciality.target.value)} value={speciality}
                            />
                              {formErrors.speciality && (
                                <div className="invalid-feedback">{formErrors.speciality}</div>
                              )}

                          </div>
                        </div>






                        <div className="mb-3">
                          <label className="form-label" for="basic-icon-default-company">type</label>
                          <div className="input-group input-group-merge">
                            <span id="basic-icon-default-company2" className="input-group-text"
                              ><i className="bx bx-buildings"></i
                            ></span>
                            <input
                              type="text"
                              name='type'
                              id="basic-icon-default-company"
                              className={`form-control ${formErrors.type ? 'is-invalid' : ''}`}

                              placeholder="ACME Inc."
                              aria-label="ACME Inc."
                              aria-describedby="basic-icon-default-company2"
                              onChange={type=>setType(type.target.value)} value={type}
                            />
                             {formErrors.type && (
                                <div className="invalid-feedback">{formErrors.type}</div>
                              )}
                          </div>

                        </div>
                        <div className="mb-3">
                          <label className="form-label" for="basic-icon-default-type">address</label>
                          <div className="input-group input-group-merge">
                            <span className="input-group-text"><i className="bx bx-envelope"></i></span>
                            <input
                              type="text"
                              name="address"
                              id="basic-icon-default-type"
                              className={`form-control ${formErrors.address ? 'is-invalid' : ''}`}

                              placeholder="john.doe"
                              aria-label="john.doe"
                              aria-describedby="basic-icon-default-type2"
                              onChange={address=>setAddress(address.target.value)} value={address}
                            />
                               {formErrors.address && (
                                <div className="invalid-feedback">{formErrors.address}</div>
                              )}


                            
                          </div>
                          
                        </div>

                        <div className="mb-3">
                          <label className="form-label" for="basic-icon-default-type">contact</label>
                          <div className="input-group input-group-merge">
                            <span className="input-group-text"><i className="bx bx-envelope"></i></span>
                            <input
                              type="text"
                              name="contact"
                              id="basic-icon-default-type"
                              className={`form-control ${formErrors.contact ? 'is-invalid' : ''}`}
                              placeholder="john.doe"
                              aria-label="john.doe"
                              aria-describedby="basic-icon-default-type2"
                              onChange={contact=>setContact(contact.target.value)} value={contact}

                            />
                             {formErrors.contact && (
                                <div className="invalid-feedback">{formErrors.contact}</div>
                              )}
                            
                            
                          </div>
                          
                        </div>



                        <div className="mb-3">
                          <label className="form-label" for="basic-icon-default-type">details</label>
                          <div className="input-group input-group-merge">
                            <span className="input-group-text"><i className="bx bx-envelope"></i></span>
                            <input
                              type="text"
                              name="details"
                              id="basic-icon-default-type"
                              className="form-control"
                              placeholder="john.doe"
                              aria-label="john.doe"
                              aria-describedby="basic-icon-default-type2"
                              onChange={details=>setDetails(details.target.value)} value={details}

                            />
                            
                          </div>
                          
                        </div>


                       





                        <button type="submit" className="btn btn" style={{ backgroundColor: "rgb(127, 1, 75)", color: "#fff" }} disabled={loading}>
                  {loading ? (
                    <img src={Spinner} alt="spinner" style={{ width:"30px" }} />
                  ) : (
                    'Submit'
                  )}
                  </button>
                  
                  
                  
                                          </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Footer />
              <div className="content-backdrop fade"></div>
            </div>
          </div>
        </div>
        <div className="layout-overlay layout-menu-toggle"></div>
      </div>

      <div className="buy-now">
        <a
          href="https://themeselection.com/products/sneat-bootstrap-html-admin-template/"
          target="_blank"
          className="btn btn-danger btn-buy-now"
        >
          Upgrade to Pro
        </a>
      </div>
    </React.Fragment>
  );
}

export default CateringEdit;