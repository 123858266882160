import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Bar } from 'react-chartjs-2';
import { CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip, BarElement } from 'chart.js';

import Header from './Header';
import Dashboard from './dashboard/Dashboard';

function VendorNavSidebar() {
  const token = localStorage.getItem('uid');
  const usertype = localStorage.getItem('usertype');
  const idd = localStorage.getItem('id');
  const navigate = useNavigate();

  const [csvDatabase, setCsvData] = useState([]);
  const [chartData, setChartData] = useState({});

  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [availableYears, setAvailableYears] = useState([]);
  const [noofyear, setNoofyear] = useState([]);

  const [Showdata, setShowdata] = useState([]);
  const [selectedHallId, setSelectedHallId] = useState('');

  const handleHallChange = (event) => {
    const selectedHallId = event.target.value;
    setSelectedHallId(selectedHallId);

    fetchChartData(selectedYear, selectedHallId);
    fetchYears(selectedHallId);
    countnoyear(selectedHallId);
  };


  const fetchDataHall = () => {
    const token = localStorage.getItem('uid');
    const idd = localStorage.getItem('id');
    const usertype = localStorage.getItem('usertype');

    if (!token) {
      window.location.href = '/logintoken';
      return;
    }

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    if (usertype === 'vendor') {
      axios
        .get(`https://api.bookmyfunctionhall.com/api/hallvendorfetch/${idd}`, config)
        .then((res) => {
          const data = res.data;
          setShowdata(data);

          if (data.length > 0) {
            setSelectedHallId(data[0].id);
          }
        })
        .catch((error) => {
          //console.error(error);
        });
    } else {
      axios.get(`https://api.bookmyfunctionhall.com/api/Hall`, config)
        .then((res) => {
          const responseData = res.data;
          setShowdata(responseData);
        })
        .catch((error) => {
          //console.error("API request error:", error);
        });
    }
  };

  useEffect(() => {
    fetchDataHall();
  }, []);

  useEffect(() => {
    if (Showdata.length > 0) {
      setSelectedHallId(Showdata[0].id);
    }
  }, [Showdata]);

  useEffect(() => {
    fetchChartData(selectedYear, selectedHallId);
    fetchYears(selectedHallId);
    countnoyear(selectedHallId);
  }, [selectedYear, selectedHallId]);

  useEffect(() => {
    fetchData();
  }, [selectedYear, selectedHallId]);

  const fetchChartData = (year, hallId) => {
    const url = `https://api.bookmyfunctionhall.com/api/loadChartData/${hallId}/${year}`;

    axios.get(url, config)
      .then((res) => {
        const responseData = res.data;
        setChartData(responseData);
        setCsvData(Object.values(responseData));
      })
      .catch((error) => {
        //console.error('API request error:', error);
      });
  };

  useEffect(() => {
    countnoyear();
  }, [selectedYear, selectedHallId]);

  const fetchYears = () => {
    axios.get('https://api.bookmyfunctionhall.com/api/availableYears')
      .then((res) => {
        setAvailableYears(res.data);
      })
      .catch((error) => {
        //console.error('Error fetching available years:', error);
      });
  };

  const countnoyear = () => {
    axios.get(`https://api.bookmyfunctionhall.com/api/availableYearscount/${selectedHallId}/${selectedYear}`)
      .then((res) => {
        setNoofyear(res.data);
      })
      .catch((error) => {
        //console.error('Error No of years:', error);
      });
  };

  const fetchData = () => {
    if (!token) {
      window.location.href = '/logintoken';
      return;
    }

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const url = usertype === 'vendor'
      ? `https://api.bookmyfunctionhall.com/api/loadChartData/${idd}/${selectedYear}`
      : `https://api.bookmyfunctionhall.com/api/enquiry/${selectedYear}`;

    axios
      .get(url, config)
      .then((res) => {
        const responseData = res.data;
        setChartData(responseData);
        setCsvData(Object.values(responseData));
      })
      .catch((error) => {
        //console.error('API request error:', error);
      });
  };

  ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

  const labels = Array.from({ length: 7 }, (_, i) => {
    const date = new Date();
    date.setMonth(date.getMonth() - i);
    return date.toLocaleString('default', { month: 'long' });
  }).reverse();

  const data = {
    labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
    datasets: [
      {
        label: `No. of Enquiry  In ${selectedYear}`,
        data: Object.values(chartData),
        backgroundColor: [
          'rgba(255, 99, 132, 0.2)',
          'rgba(255, 159, 64, 0.2)',
          'rgba(255, 205, 86, 0.2)',
          'rgba(75, 192, 192, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(153, 102, 255, 0.2)',
          'rgba(201, 203, 207, 0.2)',
          'rgba(255, 99, 132, 0.2)',
          'rgba(255, 159, 64, 0.2)',
          'rgba(255, 205, 86, 0.2)',
          'rgba(75, 192, 192, 0.2)',
          'rgba(54, 162, 235, 0.2)',
        ],
        borderColor: [
          'rgb(255, 99, 132)',
          'rgb(255, 159, 64)',
          'rgb(255, 205, 86)',
          'rgb(75, 192, 192)',
          'rgb(54, 162, 235)',
          'rgb(153, 102, 255)',
          'rgb(201, 203, 207)',
          'rgb(255, 99, 132)',
          'rgb(255, 159, 64)',
          'rgb(255, 205, 86)',
          'rgb(75, 192, 192)',
          'rgb(54, 162, 235)',
        ],
        borderWidth: 1,
      },
    ],
  };

  const config = {
    type: 'bar',
    data: data,
    options: {
      scales: {
        x: {
          beginAtZero: true,
          title: {
            display: true,
            text: 'Months',
          },
          ticks: {
            callback: function (value, index, values) {
              return data.labels[index];
            },
          },
        },
        y: {
          beginAtZero: true,
          title: {
            display: true,
            text: 'No. of Enquiry ',
          },
          ticks: {
            stepSize: 5,
            max: 100,
          },
        },
      },
    },
  };

  const [selectedFormat, setSelectedFormat] = useState('');

  const handleDownload = () => {
    const canvas = document.querySelector('canvas');

    if (!canvas) {
      //console.error('Canvas element not found.');
      return;
    }

    if (selectedFormat === 'csv') {
      downloadCSV();
    } else if (selectedFormat === 'jpg') {
      downloadJPG(canvas);
    }
  };

  const downloadCSV = () => {
    //const csvData = 'Enquiry Enquiries of ' + selectedYear + '\nJanuary,February,March,April,May,June,July,August,September,Octomber,November,December\n' + csvDatabase;
    const csvData = `Enquiry Enquiries of ${selectedYear} - Hall ID: ${selectedHallId}\nJanuary,February,March,April,May,June,July,August,September,October,November,December\n${csvDatabase}`;

    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');

    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', 'data.csv');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      //console.error('CSV download not supported in this browser.');
    }
  };

  const downloadJPG = (canvas) => {
    const imgData = canvas.toDataURL('image/jpeg');

    const link = document.createElement('a');
    link.href = imgData;
    link.download = 'chart.jpg';

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <React.Fragment>
      <div className="layout-wrapper layout-content-navbar">
        <div className="layout-container">
          <Dashboard />

          <div className="layout-page">
            <Header />
            <div className='container p-5'>

              <div class="row mb-3">
                <div className='col-md-6'>
                  <div class="form-group">
                    <label htmlFor="yearSelect">Select Year</label>
                    <select
                      className="form-control"
                      id="yearSelect"
                      onChange={(e) => {
                        setSelectedYear(e.target.value);
                        fetchData();
                      }}
                      value={selectedYear}
                    >
                      {availableYears.map((year) => (
                        <option key={year} value={year}>
                          {year}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className='col-md-6'>
                  <div className="form-group">
                    <label htmlFor="hallSelect">Select Hall</label>
                    <select
                      className="form-control"
                      id="hallSelect"
                      onChange={handleHallChange}
                      value={selectedHallId}
                    >
                      {Showdata.map((row, index) => (
                        <option key={index} value={row.id}>
                          {row.hname}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>

              <div class="row mb-4">
                <div className='col-md-6'>
                  <div class="row d-flex">
                    <div class="card col-md-6">
                      <div className="card-body" style={{ height: "95px" }}>
                        <h5><b>{selectedYear}</b></h5>
                        <p>Total No of Enquiries - {noofyear.length > 0 ? noofyear[0].count : 0}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-3"></div>
                <div class="col-md-3">
                  <div class="form-group">
                    <label htmlFor="downloadFormat">Download As:</label>
                    <div class="d-flex">
                      <select
                        id="downloadFormat"
                        onChange={(e) => setSelectedFormat(e.target.value)}
                        value={selectedFormat}
                        className='form-control mr-2'
                      >
                        <option value="">Select Format</option>
                        <option value="csv">CSV</option>
                        <option value="jpg">JPG</option>
                      </select>
                      <button onClick={handleDownload} className="btn btn-primary mx-3">Download</button>
                    </div>
                  </div>
                </div>
              </div>

              <Bar options={config.options} data={data} />
            </div>
          </div>
        </div>
        <div className="buy-now">
          <a
            href="https://themeselection.com/products/sneat-bootstrap-html-admin-template/"
            target="_blank"
            className="btn btn-danger btn-buy-now"
          >
            Upgrade to Pro
          </a>
        </div>
      </div>
    </React.Fragment>
  );
}

export default VendorNavSidebar;
