import React, { useEffect, useState } from 'react';
import Footer from '../Footer';
import Header from '../Header';
import Sidebar from '../Sidebar';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Dashboard from '../dashboard/Dashboard';
import Spinner from '../spinner.gif';

function Create() {

  const [role, setRole] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [address, setAddress] = useState('');
  const [contact, setContact] = useState('');
  const [ratings, setRatings] = useState('');
  const [testimonial_img, setTestimonialImg] = useState(null); // Use null for initial value
  const [eventhistory, setEventhistory] = useState('');
  const [userid, setUserid] = useState('');
  const [formErrors, setFormErrors] = useState({}); // Initialize form error state
  const [loading, setLoading] = useState(false);


  const token = localStorage.getItem('uid');
 
  function handleChange(e) { 
    if (e.target.name === 'testimonial_img') {
      setTestimonialImg(e.target.files[0]);
    } else if (e.target.name === 'role') {
      setRole(e.target.value);
    } else if (e.target.name === 'email') {
      setEmail(e.target.value);
    } else if (e.target.name === 'name') {
      setName(e.target.value);
    } else if (e.target.name === 'contact') {
      setContact(e.target.value);
    } else if (e.target.name === 'ratings') {
      setRatings(e.target.value);
    } else if (e.target.name === 'address') {
      setAddress(e.target.value);
    } else if (e.target.name === 'eventhistory') {
      setEventhistory(e.target.value);
    }
  }
  

 
  function validateForm() {
    const errors = {};
  
    
    if (!role || !/^[a-zA-Z\s]+$/.test(role)) {
      errors.role = 'Role is required and should contain only letters';
    }
  
    
    if (!email || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
      errors.email = 'Email is required and should be a valid email address';
    }
  
    
    if (!name || !/^[a-zA-Z\s]+$/.test(name)) {
      errors.name = 'Name is required and should contain only letters';
    }
  
    
   
if (!contact || !/^\d{10}$/.test(contact)) {
  errors.contact = 'Contact is required and should be a 10-digit number';
}

  
    
    if (!address) {
      errors.address = 'Address is required';
    }
  
    
    if (ratings && isNaN(ratings)) {
      errors.ratings = 'Ratings should be a number';
    }
  
    
    if (!testimonial_img) {
      errors.testimonial_img = 'Testimonial Image is required';
    }
  
    
    if (!eventhistory) {
      errors.eventhistory = 'Event History is required';
    }
  
    setFormErrors(errors);
  
    return Object.keys(errors).length === 0;
  }
  





  function handleSubmit(e) {
    e.preventDefault();

 if (validateForm()) {

  setLoading(true);
    const data = new FormData(); // Use FormData for file uploads
    data.append('role', role);
    data.append('email', email);
    data.append('address', address);
    data.append('name', name);
    data.append('contact', contact);
    data.append('ratings', ratings);
    data.append('testimonial_img', testimonial_img); // Append the file to FormData
    data.append('eventhistory', eventhistory);
    data.append('userid', userid);
   
    
 


    
    axios
      .post('https://api.bookmyfunctionhall.com/api/AddOn', data, {
        headers: { 'Content-Type': 'multipart/form-data' ,'Authorization':"Bearer "+token}
      })
      .then((resp) => {
        if (resp.data && resp.data[0].status === 'success') {
          //console.log(resp.data);
         // console.log(resp);
         // console.log('Data Added');
          toast.success('Data Added!!!');
          window.location.href = '/AddOnIndex';
        } else {
         // console.log('Error');
          toast.error('Error occurred while adding data');
        }
      })
      .catch((error) => {
       // console.log('Error', error);
        toast.error('An error occurred while making the request');
      })
      .finally(() => {
        setLoading(false); // Stop loading
      });
  }
}

  useEffect(() => {
    const token = localStorage.getItem('uid');
    if (!token) {
      window.location.href="/login"
    }
    const idd = localStorage.getItem('id');
    if (idd) {
      setUserid(idd); 
    }
  }, []); 


  return (
    <React.Fragment>
      <ToastContainer />
      <div className="layout-wrapper layout-content-navbar">
        <div className="layout-container">
          {/* <Sidebar /> */}
          <Dashboard/>
          <div className="layout-page">
            <Header />
            <div className="content-wrapper">
              <div className="container-xxl flex-grow-1 container-p-y">
                <h4 className="fw-bold py-3 mb-4">
                  <span className="text-muted fw-light">ADD/</span> Addon
                </h4>
                <div className="row">
                  <div className="col-xl">
                    <div className="card mb-4 col-md-6">
                      <div className="card-header d-flex justify-content-between align-items-center">
                        <h5 className="mb-0">AddOn Add</h5>
                      </div>
                      <div className="card-body">
                        <form  onSubmit={handleSubmit}>


                          
                      <div className="mb-3">
     
     <div className="input-group input-group-merge">
      
       <input
         type="hidden"
         name="userid"
         className="form-control"
         id="basic-icon-default-fullname"
         placeholder="John Doe"
         aria-label="John Doe"
         aria-describedby="basic-icon-default-fullname2"
         onChange={(event) => setUserid(event.target.value)}
         value={userid} // Set the initial value to the "userid" state
         readOnly
       />
     </div>
   </div>


                          <div className="mb-3">
                            <label className="form-label" for="basic-icon-default-fullname">role</label>
                            <div className="input-group input-group-merge">
                              <span id="basic-icon-default-fullname2" className="input-group-text"
                              ><i className="bx bx-user"></i
                              ></span>
                              <input
                                type="text"
                                name="role"
                                className={`form-control ${formErrors.role ? 'is-invalid' : ''}`}

                                id="basic-icon-default-fullname"
                                placeholder="John Doe"
                                aria-label="John Doe"
                                aria-describedby="basic-icon-default-fullname2"
                                onChange={role => setRole(role.target.value)} value={role}
                                required 
                                  
                              />
                              {formErrors.role && (
                                <div className="invalid-feedback">{formErrors.role}</div>
                              )}

                            </div>
                            
                          </div>


                          <div className="mb-3">
                            <label className="form-label" for="basic-icon-default-fullname">email</label>
                            <div className="input-group input-group-merge">
                              <span id="basic-icon-default-fullname2" className="input-group-text"
                              ><i className="bx bx-user"></i
                              ></span>
                              <input
                                type="text"
                                name="email"
                                className={`form-control ${formErrors.email ? 'is-invalid' : ''}`}

                                id="basic-icon-default-fullname"
                                placeholder="John Doe"
                                aria-label="John Doe"
                                aria-describedby="basic-icon-default-fullname2"
                                onChange={email => setEmail(email.target.value)} value={email}
                              />
                              {formErrors.email && (
                                <div className="invalid-feedback">{formErrors.email}</div>
                              )}

                            </div>
                          </div>


                          <div className="mb-3">
                            <label className="form-label" for="basic-icon-default-fullname">Name</label>
                            <div className="input-group input-group-merge">
                              <span id="basic-icon-default-fullname2" className="input-group-text"
                              ><i className="bx bx-user"></i
                              ></span>
                              <input
                                type="text"
                                name="name"
                                className={`form-control ${formErrors.name ? 'is-invalid' : ''}`}
                                id="basic-icon-default-fullname"
                                placeholder="John Doe"
                                aria-label="John Doe"
                                aria-describedby="basic-icon-default-fullname2"
                                onChange={name => setName(name.target.value)} value={name}
                              />
                                {formErrors.name && (
                                <div className="invalid-feedback">{formErrors.name}</div>
                              )}
                            </div>
                          </div>



                          <div className="mb-3">
                            <label className="form-label" for="basic-icon-default-company">contact</label>
                            <div className="input-group input-group-merge">
                              <span id="basic-icon-default-company2" className="input-group-text"
                              ><i className="bx bx-buildings"></i
                              ></span>
                              <input
                                type="text"
                                name='contact'
                                id="basic-icon-default-company"
                                className={`form-control ${formErrors.contact ? 'is-invalid' : ''}`}
                                placeholder="ACME Inc."
                                aria-label="ACME Inc."
                                aria-describedby="basic-icon-default-company2"
                                onChange={contact => setContact(contact.target.value)} value={contact}
                              />
                              {formErrors.contact && (
                                <div className="invalid-feedback">{formErrors.contact}</div>
                              )}
                            </div>

                          </div>

                          <div className="mb-3">
                            <label className="form-label" for="basic-icon-default-email">address</label>
                            <div className="input-group input-group-merge">
                              <span className="input-group-text"><i className="bx bx-envelope"></i></span>
                              <input
                                type="text"
                                name="address"
                                id="basic-icon-default-email"
                                className={`form-control ${formErrors.address ? 'is-invalid' : ''}`}

                                placeholder="john.doe"
                                aria-label="john.doe"
                                aria-describedby="basic-icon-default-email2"
                                onChange={address => setAddress(address.target.value)} value={address}
                              />
                              {formErrors.address && (
                                <div className="invalid-feedback">{formErrors.address}</div>
                              )}
                             
                            </div>
                          </div>

                          <div className="mb-3">
                            <label className="form-label" for="basic-icon-default-email">rating</label>
                            <div className="input-group input-group-merge">
                              <span className="input-group-text"><i className="bx bx-envelope"></i></span>
                              <input
                                type="text"
                                name="ratings"
                                id="basic-icon-default-email"
                                className={`form-control ${formErrors.ratings ? 'is-invalid' : ''}`}
                                placeholder="john.doe"
                                aria-label="john.doe"
                                aria-describedby="basic-icon-default-email2"
                                onChange={ratings => setRatings(ratings.target.value)} value={ratings}

                              />  {formErrors.ratings && (
                                <div className="invalid-feedback">{formErrors.ratings}</div>
                              )}

                              
                              
                            </div>
                            
                          </div>



                          <div className="mb-3">
                            <label className="form-label" for="basic-icon-default-email">Testimonial Image</label>
                            <div className="input-group input-group-merge">
                              <span className="input-group-text"><i className="bx bx-envelope"></i></span>
                              <input
                                type="file"
                                name="testimonial_img"
                                id="basic-icon-default-email"
                                className={`form-control ${formErrors.testimonial_img ? 'is-invalid' : ''}`}
                                placeholder="john.doe"
                                aria-label="john.doe"
                                aria-describedby="basic-icon-default-email2"
                                onChange={handleChange}

                              />
                                {formErrors.testimonial_img && (
                                <div className="invalid-feedback">{formErrors.testimonial_img}</div>
                              )}
                              
                            </div>
                            
                          </div>


                          <div className="mb-3">
                            <label className="form-label" for="basic-icon-default-email">eventhistory</label>
                            <div className="input-group input-group-merge">
                              <span className="input-group-text"><i className="bx bx-envelope"></i></span>
                              <input
                                type="text"
                                name="eventhistory"
                                id="basic-icon-default-email"
                                className={`form-control ${formErrors.eventhistory ? 'is-invalid' : ''}`}
                                placeholder="john.doe"
                                aria-label="john.doe"
                                aria-describedby="basic-icon-default-email2"
                                onChange={eventhistory => setEventhistory(eventhistory.target.value)} value={eventhistory}

                              />
                                {formErrors.eventhistory && (
                                <div className="invalid-feedback">{formErrors.eventhistory}</div>
                              )}
                              
                            </div>
                            
                          </div>






                          <button type="submit" className="btn btn" style={{ backgroundColor: "rgb(127, 1, 75)", color: "#fff" }} disabled={loading}>
                  {loading ? (
                    <img src={Spinner} alt="spinner" style={{ width:"30px" }} />
                  ) : (
                    'Submit'
                  )}
                </button>
                          {/* <button type="submit" className="btn btn-primary" onClick={handleSubmit}>Send</button> */}
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Footer />
              <div className="content-backdrop fade"></div>
            </div>
          </div>
        </div>
        <div className="layout-overlay layout-menu-toggle"></div>
      </div>

      <div className="buy-now">
        <a href="https://themeselection.com/products/sneat-bootstrap-html-admin-template/"  className="btn btn-danger btn-buy-now">
          Upgrade to Pro
        </a>
      </div>
    </React.Fragment>
  );
}

export default Create;
