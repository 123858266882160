import React, { useState,useEffect } from 'react';
import Footer from '../Footer';
import Header from '../Header';
import Sidebar from '../Sidebar';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import  axiosInstance  from '../../axiosConfig';
import Dashboard from '../dashboard/Dashboard';
import Spinner from '../spinner.gif';

function EventmgmtCreate() {
  const [name, setName] = useState('');
  const [type, setType] = useState('');
  const [contact, setContact] = useState('');
  const [email, setEmail] = useState('');
  const [address, setAddress] = useState('');
  const [packages, setPackages] = useState('');
  const [details, setDetails] = useState('');
  const [userid, setUserid] = useState(null);
  const [formErrors, setFormErrors] = useState({}); // Initialize form error state
  const [loading, setLoading] = useState(false);


  function handleChange(e) {
    const { name, value, files } = e.target;
    if (name === 'name') {
      setName(value);
    } else if (name === 'type') {
      setType(files[0]);
    } else if (name === 'contact') {
      setContact(files[0]);
    } else if (name === 'email') {
      setEmail(files[0]);
    } else if (name === 'address') {
      setAddress(files[0]);
    } else if (name === 'packages') {
      setPackages(files[0]);
    }
    
  }
  
  
  function validateForm() {
    const errors = {};



    if (!type) {
      errors.type = 'type is required ';
    }

    if (!name || !/^[a-zA-Z\s]+$/.test(name)) {
      errors.name = 'Name is required and should contain only letters';
    }

    if (!contact || !/^\d{10}$/.test(contact)) {
      errors.contact = 'Contact is required and should be a 10-digit number';
    }

    if (!address) {
      errors.address = 'Address is required';
    }

   
    if (!packages) {
      errors.packages = 'packages is required';
    } else if (!/^\d+$/.test(packages)) {
      errors.packages = 'Please enter only numeric characters for packages';
    }
    



    setFormErrors(errors);

    return Object.keys(errors).length === 0;
  }



  function handleSubmit(e) {
    e.preventDefault();

   
     
 if (validateForm()) {

  setLoading(true);

    const data = new FormData();
    data.append('name', name);
    data.append('type', type);
    data.append('contact', contact);
    data.append('email', email);
    data.append('address', address);
    data.append('packages', packages);
    data.append('details', details);
    data.append('userid', userid);

    axiosInstance
      .post('https://api.bookmyfunctionhall.com/api/Eventmgmt', data, {
        headers: { 'X-Requested-With': 'XMLHttpRequest' },
      })
      .then((resp) => {
        if (resp.data && resp.data[0].status === 'success') {
          //console.log('Data Added');
          toast.success('Data Added!!!');
          window.location.href = '/eventmgmtindex';
        } else {
          //console.log('Error');
          toast.error('Error occurred while adding data');
        }
      })
      .catch((error) => {
        //console.log('Error', error);
        toast.error('An error occurred while making the request');
      });
  }
  }


  


  useEffect(() => {
    const idd = localStorage.getItem('id');
    if (idd) {
      setUserid(idd); 
    }
  }, []); 

  return (
    <React.Fragment>
       <ToastContainer />
      <div className="layout-wrapper layout-content-navbar">
        <div className="layout-container">
          {/* <Sidebar /> */}
          <Dashboard/>
          <div className="layout-page">
            <Header />
            <div className="content-wrapper">
              <div className="container-xxl flex-grow-1 container-p-y">
                <h4 className="fw-bold py-3 mb-4">
                  <span className="text-muted fw-light">Eventmgmt/</span> Create
                </h4>
                <div className="row">
                  <div className="col-xl">
                    <div className="card mb-4 col-md-6">
                      <div className="card-header d-flex justify-content-between align-items-center">
                        <h5 className="mb-0">Eventmgmt</h5>
                      </div>
                      <div className="card-body">
                      <form>






                      <div className="mb-3">
     
     <div className="input-group input-group-merge">
      
       <input
         type="hidden"
         name="userid"
         className="form-control"
         id="basic-icon-default-fullname"
         placeholder="John Doe"
         aria-label="John Doe"
         aria-describedby="basic-icon-default-fullname2"
         onChange={(event) => setUserid(event.target.value)}
         value={userid} // Set the initial value to the "userid" state
         readOnly
       />
     </div>
   </div>





                        <div className="mb-3">
                          <label className="form-label" for="basic-icon-default-fullname">Name</label>
                          <div className="input-group input-group-merge">
                            <span id="basic-icon-default-fullname2" className="input-group-text"
                              ><i className="bx bx-user"></i
                            ></span>
                            <input
                              type="text"
                              name="name"
                              className={`form-control ${formErrors.name ? 'is-invalid' : ''}`}
                              id="basic-icon-default-fullname"
                              placeholder="John Doe"
                              aria-label="John Doe"
                              aria-describedby="basic-icon-default-fullname2"
                              onChange={name=>setName(name.target.value)} value={name}
                            />
                             {formErrors.name && (
                                <div className="invalid-feedback">{formErrors.name}</div>
                              )}

                          </div>
                        </div>

                        <div className="mb-3">
                          <label className="form-label" for="basic-icon-default-fullname">Type</label>
                          <div className="input-group input-group-merge">
                            <span id="basic-icon-default-fullname2" className="input-group-text"
                              ><i className="bx bx-user"></i
                            ></span>
                            <input
                              type="text"
                              name="type"
                              className={`form-control ${formErrors.type ? 'is-invalid' : ''}`}
                              id="basic-icon-default-fullname"
                              placeholder="John Doe"
                              aria-label="John Doe"
                              aria-describedby="basic-icon-default-fullname2"
                              onChange={type=>setType(type.target.value)} value={type}
                            />
                              {formErrors.type && (
                                <div className="invalid-feedback">{formErrors.type}</div>
                              )}

                          </div>
                        </div>

                        <div className="mb-3">
                          <label className="form-label" for="basic-icon-default-fullname">Contact</label>
                          <div className="input-group input-group-merge">
                            <span id="basic-icon-default-fullname2" className="input-group-text"
                              ><i className="bx bx-user"></i
                            ></span>
                            <input
                              type="text"
                              name="contact"
                              className={`form-control ${formErrors.contact ? 'is-invalid' : ''}`}

                              id="basic-icon-default-fullname"
                              placeholder="John Doe"
                              aria-label="John Doe"
                              aria-describedby="basic-icon-default-fullname2"
                              onChange={contact=>setContact(contact.target.value)} value={contact}
                            />
                            {formErrors.contact && (
                                <div className="invalid-feedback">{formErrors.contact}</div>
                              )}
                          </div>
                        </div>

                        <div className="mb-3">
                          <label className="form-label" for="basic-icon-default-fullname">email</label>
                          <div className="input-group input-group-merge">
                            <span id="basic-icon-default-fullname2" className="input-group-text"
                              ><i className="bx bx-user"></i
                            ></span>
                            <input
                              type="email"
                              name="email"
                              className="form-control"
                              id="basic-icon-default-fullname"
                              placeholder="John Doe"
                              aria-label="John Doe"
                              aria-describedby="basic-icon-default-fullname2"
                              onChange={email=>setEmail(email.target.value)} value={email}
                            />

                          </div>
                        </div>

                        <div className="mb-3">
                          <label className="form-label" for="basic-icon-default-fullname">Address</label>
                          <div className="input-group input-group-merge">
                            <span id="basic-icon-default-fullname2" className="input-group-text"
                              ><i className="bx bx-user"></i
                            ></span>
                            <input
                              type="text"
                              name="address"
                              className={`form-control ${formErrors.address ? 'is-invalid' : ''}`}
                              id="basic-icon-default-fullname"
                              placeholder="John Doe"
                              aria-label="John Doe"
                              aria-describedby="basic-icon-default-fullname2"
                              onChange={address=>setAddress(address.target.value)} value={address}
                            />
                            {formErrors.address && (
                                <div className="invalid-feedback">{formErrors.address}</div>
                              )}

                          </div>
                        </div>

                        
                                               
                    


                        <div className="mb-3">
                          <label className="form-label" for="basic-icon-default-fullname">packages</label>
                          <div className="input-group input-group-merge">
                            <span id="basic-icon-default-fullname2" className="input-group-text"
                              ><i className="bx bx-user"></i
                            ></span>
                            <input
                              type="text"
                              name="packages"
                              className={`form-control ${formErrors.packages ? 'is-invalid' : ''}`}

                              id="basic-icon-default-fullname"
                              placeholder="John Doe"
                              aria-label="John Doe"
                              aria-describedby="basic-icon-default-fullname2"
                              onChange={packages=>setPackages(packages.target.value)} value={packages}
                            />
                            {formErrors.packages && (
                                <div className="invalid-feedback">{formErrors.packages}</div>
                              )}

                          </div>
                        </div>




                        <div className="mb-3">
                          <label className="form-label" for="basic-icon-default-fullname">Details</label>
                          <div className="input-group input-group-merge">
                            <span id="basic-icon-default-fullname2" className="input-group-text"
                              ><i className="bx bx-user"></i
                            ></span>
                            <input
                              type="text"
                              name="details"
                              className="form-control"
                              id="basic-icon-default-fullname"
                              placeholder="John Doe"
                              aria-label="John Doe"
                              aria-describedby="basic-icon-default-fullname2"
                              onChange={details=>setDetails(details.target.value)} value={details}
                            />

                          </div>
                        </div>



                        <button type="submit" onClick={handleSubmit} className="btn btn" style={{ backgroundColor: "rgb(127, 1, 75)", color: "#fff" }} disabled={loading}>
                  {loading ? (
                    <img src={Spinner} alt="spinner" style={{ width:"30px" }} />
                  ) : (
                    'Submit'
                  )}
                </button>

                        {/* <button type="submit" className="btn btn-primary" onClick={handleSubmit}>Send</button> */}
                      </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Footer />
              <div className="content-backdrop fade"></div>
            </div>
          </div>
        </div>
        <div className="layout-overlay layout-menu-toggle"></div>
      </div>

      <div className="buy-now">
        <a
          href="https://themeselection.com/products/sneat-bootstrap-html-admin-template/"
          target="_blank"
          className="btn btn-danger btn-buy-now"
        >
          Upgrade to Pro
        </a>
      </div>
    </React.Fragment>
  );
}

export default EventmgmtCreate;
