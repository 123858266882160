import React, { useState, useEffect } from 'react';
import Footer from '../Footer';
import Header from '../Header';
import Sidebar from '../Sidebar';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useParams } from 'react-router-dom';
import  axiosInstance  from '../../axiosConfig';
import Dashboard from '../dashboard/Dashboard';
import Spinner from '../spinner.gif';

function EventmgmtEdit() { 
  const [name, setName] = useState('');
  const [type, setType] = useState('');
  const [contact, setContact] = useState('');
  const [email, setEmail] = useState('');
  const [address, setAddress] = useState('');
  const [packages, setPackages] = useState('');
  const [details, setDetails] = useState('');
  const [userid, setUserid] = useState('');
  const [formErrors, setFormErrors] = useState({}); // Initialize form error state

  const [loading, setLoading] = useState(false);


  const { id } = useParams();

  function updatedata() {
    axiosInstance.get('https://api.bookmyfunctionhall.com/api/Eventmgmt/' + id)
      .then((res) => {
        const data = res.data;
        //console.log(data);

        setName(data.name);
        setType(data.type);
        setContact(data.contact);
        setEmail(data.email);
        setAddress(data.address);
        setPackages(data.packages);
        setDetails(data.details);
        setUserid(data.userid);
      })
      .catch((error) => {
        //console.log("Error", error);
      });
  }





  
  function validateForm() {
    const errors = {};



    if (!type) {
      errors.type = 'type is required ';
    }

    if (!name || !/^[a-zA-Z\s]+$/.test(name)) {
      errors.name = 'Name is required and should contain only letters';
    }

    if (!contact || !/^\d{10}$/.test(contact)) {
      errors.contact = 'Contact is required and should be a 10-digit number';
    }

    if (!address) {
      errors.address = 'Address is required';
    }

    if (!address) {
      errors.address = 'address is required';
    }
    if (!packages) {
      errors.packages = 'packages is required';
    } else if (!/^\d+$/.test(packages)) {
      errors.packages = 'Please enter only numeric characters for packages';
    }
    



    setFormErrors(errors);

    return Object.keys(errors).length === 0;
  }

  
  function updateCategory(e) {
    e.preventDefault(); // Prevent the default form submission
    // if (!name || !type || !contact ||!packages || !address) {
    //   toast.error('Please fill name, type,contact,image,video in all required fields.', { autoClose: 2000 });
    //   return;
    // }
    // setLoading(true);



    
     
 if (validateForm()) {

  setLoading(true);




    const datacode = {
      "name": name,
      "type": type,
      "contact": contact,
      "email": email,
      "address": address,
      "packages": packages,
      "details": details,
      "userid": userid,

    };

    //console.log(id);
    //console.log(datacode); // Check if the datacode object is correct

    axiosInstance.put('https://api.bookmyfunctionhall.com/api/Eventmgmt/' + id, datacode)
      .then((resp) => {
        const data = resp.data;
        //console.log(resp);
        if (data[0].status === "success") {
          //console.log("update");
          toast.success('Data Updated!!!'); // Use toast.success instead of alert
          window.location.href = '/eventmgmtindex';
        } else {
          //console.log("Error");
          toast.error('Error occurred while updating data');
        }
      })
      .catch((error) => {
        //console.log("Error", error);
        toast.error('An error occurred while making the request');
      })
      .finally(() => {
        setLoading(false); // Stop loading
      });
  }
  }
  useEffect(() => {
    updatedata();
  }, []);


  
  useEffect(() => {
    const idd = localStorage.getItem('id');
    if (idd) {
      setUserid(idd); 
    }
  }, []); 

  return (
    <React.Fragment>
    <ToastContainer />
    <div className="layout-wrapper layout-content-navbar">
      <div className="layout-container">
        {/* <Sidebar /> */}
        <Dashboard/>
        <div className="layout-page">
          <Header />
          <div className="content-wrapper">
            <div className="container-xxl flex-grow-1 container-p-y">
              <h4 className="fw-bold py-3 mb-4">
                <span className="text-muted fw-light">Eventmgmt /</span> Edit
              </h4>
              <div className="row">
                <div className="col-xl">
                  <div className="card mb-4 col-md-6">
                    <div className="card-header d-flex justify-content-between align-items-center">
                      <h5 className="mb-0">Eventmgmt</h5>
                    </div>
                    <div className="card-body">
                      <form onSubmit={updateCategory}>





                      <div className="mb-3">
     
     <div className="input-group input-group-merge">
      
       <input
         type="hidden"
         name="userid"
         className="form-control"
         id="basic-icon-default-fullname"
         placeholder="John Doe"
         aria-label="John Doe"
         aria-describedby="basic-icon-default-fullname2"
         onChange={(event) => setUserid(event.target.value)}
         value={userid} // Set the initial value to the "userid" state
         readOnly
       />
     </div>
   </div>





                        <div className="mb-3">
                          <label className="form-label" for="basic-icon-default-fullname">Name</label>
                          <div className="input-group input-group-merge">
                            <span id="basic-icon-default-fullname2" className="input-group-text">
                              <i className="bx bx-user"></i>
                            </span>
                            <input
                              type="text"
                              name="name"
                              className={`form-control ${formErrors.name ? 'is-invalid' : ''}`}

                              id="basic-icon-default-fullname"
                              placeholder="John Doe"
                              aria-label="John Doe"
                              aria-describedby="basic-icon-default-fullname2"
                              onChange={name => setName(name.target.value)} value={name}
                            />
                               {formErrors.name && (
                                <div className="invalid-feedback">{formErrors.name}</div>
                              )}
                          </div>
                        </div>
                        
                        <div className="mb-3">
                          <label className="form-label" for="basic-icon-default-company">Type</label>
                          <div className="input-group input-group-merge">
                            <span id="basic-icon-default-company2" className="input-group-text"
                              ><i className="bx bx-buildings"></i
                            ></span>
                            <input
                              type="text"
                              name='type'
                              id="basic-icon-default-company"
                              className={`form-control ${formErrors.type ? 'is-invalid' : ''}`}

                              placeholder="Type - wedding,Birthday,Part....etc"
                              aria-label="ACME Inc."
                              aria-describedby="basic-icon-default-company2"
                              onChange={type=>setType(type.target.value)} value={type}
                            />
                               {formErrors.type && (
                                <div className="invalid-feedback">{formErrors.type}</div>
                              )}
                          </div>
                        </div>

                        <div className="mb-3">
                          <label className="form-label" for="basic-icon-default-company">Contact</label>
                          <div className="input-group input-group-merge">
                            <span id="basic-icon-default-company2" className="input-group-text"
                              ><i className="bx bx-buildings"></i
                            ></span>
                            <input
                              type="text"
                              name='contact'
                              id="basic-icon-default-company"
                              className={`form-control ${formErrors.contact ? 'is-invalid' : ''}`}

                              placeholder="ACME Inc."
                              aria-label="ACME Inc."
                              aria-describedby="basic-icon-default-company2"
                              onChange={contact=>setContact(contact.target.value)} value={contact}
                            />
                            {formErrors.contact && (
                                <div className="invalid-feedback">{formErrors.contact}</div>
                              )}
                          </div>
                        </div>

                        <div className="mb-3">
                          <label className="form-label" for="basic-icon-default-company">Email</label>
                          <div className="input-group input-group-merge">
                            <span id="basic-icon-default-company2" className="input-group-text"
                              ><i className="bx bx-buildings"></i
                            ></span>
                            <input
                              type="text"
                              name='email'
                              id="basic-icon-default-company"
                              className="form-control"
                              placeholder="ACME Inc."
                              aria-label="ACME Inc."
                              aria-describedby="basic-icon-default-company2"
                              onChange={email=>setEmail(email.target.value)} value={email}
                            />
                          </div>
                        </div>

                        <div className="mb-3">
                          <label className="form-label" for="basic-icon-default-type">Address</label>
                          <div className="input-group input-group-merge">
                            <span className="input-group-text"><i className="bx bx-envelope"></i></span>
                            <input
                              type="text"
                              name="address"
                              id="basic-icon-default-type"
                              className={`form-control ${formErrors.address ? 'is-invalid' : ''}`}
                              placeholder="john.doe"
                              aria-label="john.doe"
                              aria-describedby="basic-icon-default-type2"
                              onChange={address=>setAddress(address.target.value)} value={address}
                            />
                              {formErrors.address && (
                                <div className="invalid-feedback">{formErrors.address}</div>
                              )}

                          </div>
                        </div>

                        <div className="mb-3">
                          <label className="form-label" for="basic-icon-default-type">Packages</label>
                          <div className="input-group input-group-merge">
                            <span className="input-group-text"><i className="bx bx-envelope"></i></span>
                            <input
                              type="text"
                              name="packages"
                              id="basic-icon-default-type"
                              className={`form-control ${formErrors.packages ? 'is-invalid' : ''}`}
                              placeholder="john.doe"
                              aria-label="john.doe"
                              aria-describedby="basic-icon-default-type2"
                              onChange={packages=>setPackages(packages.target.value)} value={packages}

                            />
                              {formErrors.packages && (
                                <div className="invalid-feedback">{formErrors.packages}</div>
                              )}
                            
                          </div>
                          
                        </div>






                        <div className="mb-3">
                          <label className="form-label" for="basic-icon-default-fullname">Details</label>
                          <div className="input-group input-group-merge">
                            <span id="basic-icon-default-fullname2" className="input-group-text"
                              ><i className="bx bx-user"></i
                            ></span>
                            <input
                              type="text"
                              name="details"
                              className="form-control"
                              id="basic-icon-default-fullname"
                              placeholder="John Doe"
                              aria-label="John Doe"
                              aria-describedby="basic-icon-default-fullname2"
                              onChange={details=>setDetails(details.target.value)} value={details}
                            />

                          </div>
                        </div>

                         
                        <button   type="submit" className="btn btn" style={{ backgroundColor: "rgb(127, 1, 75)", color: "#fff" }} disabled={loading}>
                  {loading ? (
                    <img src={Spinner} alt="spinner" style={{ width:"30px" }} />
                  ) : (
                    'Update'
                  )}
                  </button>

                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Footer />
              <div className="content-backdrop fade"></div>
            </div>
          </div>
        </div>
        <div className="layout-overlay layout-menu-toggle"></div>
      </div>

      <div className="buy-now">
        <a
          href="https://themeselection.com/products/sneat-bootstrap-html-admin-template/"
          target="_blank"
          className="btn btn-danger btn-buy-now"
        >
          Upgrade to Pro
        </a>
      </div>
    </React.Fragment>
  );
}

export default EventmgmtEdit;