import React, { useState,useEffect } from 'react';
import Footer from '../Footer';
import Header from '../Header';
import Sidebar from '../Sidebar';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axiosInstance from '../../axiosConfig';
import Dashboard from '../dashboard/Dashboard';

import Spinner from '../spinner.gif';





function AdvertiseAdd() {
  const [adprovider, setAdprovider] = useState('');
  const [adtitle, setAdtitle] = useState('');
  const [description, setDescription] = useState('');
  const [ad_duration, setDuration] = useState(''); 
  const [adtype, setType] = useState('');
  const [image, setImage] = useState(null); // Use null for initial value
  const [video, setVideo] = useState('');
  const [contract_duration, setContract] = useState('');
  const [userid, setUserid] = useState('');
  const [formErrors, setFormErrors] = useState({}); // Initialize form error state
  const [loading, setLoading] = useState(false);

  const token = localStorage.getItem('uid');

  
  function validateForm() {
    const errors = {};

    if (!adtitle) {
      errors.adtitle = 'adtitle is required';
    }

    if (!adprovider) {
      errors.adprovider = 'adprovider is required';
    }

    if (!ad_duration) {
      errors.ad_duration = 'ad_duration is required';
    }

    if (!contract_duration) {
      errors.contract_duration = 'contract_duration is required';
    }
    if(!image)
    {
      errors.image = 'image is required';
    }
    if(!video)
    {
      errors.video = 'video is required';

    }
   
    setFormErrors(errors);

    return Object.keys(errors).length === 0;
  }


  function handleChange(e) {
    if (e.target.name === 'image') {
      setImage(e.target.files[0]);
    } else if (e.target.name === 'video') {
      setVideo(e.target.value);
    } else if (e.target.name === 'adprovider') {
      setAdprovider(e.target.value);
    } else if (e.target.name === 'adtitle') {
      setAdtitle(e.target.value);
    } else if (e.target.name === 'contract_duration') {
      setContract(e.target.value);
    } else if (e.target.name === 'ad_duration') {
      setDuration(e.target.value);
    } else if (e.target.name === 'adtype') {
      setType(e.target.value);
    } else if (e.target.name === 'description') {
      setDescription(e.target.value);
    }
    else if (e.target.name === 'userid') {
      setUserid(e.target.value);
    }
  }
  


  function handleSubmit(e) {
    e.preventDefault();
    if (validateForm()) {
      setLoading(true);
    const data = new FormData(); // Use FormData for file uploads
    data.append('adprovider', adprovider);
    data.append('adtitle', adtitle);
    data.append('description', description);
    data.append('image', image);
    data.append('adtype', adtype);
    data.append('ad_duration', ad_duration);
    data.append('contract_duration', contract_duration);
    data.append('userid', userid);
    data.append('video', video);
  
   
  
    axios
      .post('https://api.bookmyfunctionhall.com/api/Advertise', data,{
        headers: { 'Content-Type': 'multipart/form-data' ,'Authorization':"Bearer "+token},
      })
      .then((resp) => {
        if (resp.data && resp.data[0].status === 'success') {
          //console.log('Data Added');
          toast.success('Data Added!!!');
          window.location.href = '/advertiseIndex';
        } else {
          //console.log('Error');
          toast.error('Error occurred while adding data');
        }
      })
      .catch((error) => {
        //console.log('Error', error);
        toast.error('An error occurred while making the request');
      })
       .finally(() => {
        setLoading(false); // Stop loading
      });
  }
}

  useEffect(() => {
    const token = localStorage.getItem('uid');
    if (!token) {
      window.location.href="/login"
    }
  }, []); 

  
  useEffect(() => {
    const idd = localStorage.getItem('id');
    if (idd) {
      setUserid(idd); 
    }
  }, []); 


  return (
    <React.Fragment>
       <ToastContainer />
      <div className="layout-wrapper layout-content-navbar">
        <div className="layout-container">
          {/* <Sidebar /> */}
          <Dashboard/>
          <div className="layout-page">
            <Header />
            <div className="content-wrapper">
              <div className="container-xxl flex-grow-1 container-p-y">
                <h4 className="fw-bold py-3 mb-4">
                  <span className="text-muted fw-light">Advertise /</span>Advertise Add
                </h4>
                <div className="row">
                  <div className="col-xl">
                    <div className="card mb-4 col-md-6">
                      <div className="card-header d-flex justify-content-between align-items-center">
                        <h5 className="mb-0">Advertise</h5>

                      </div>
                      <div className="card-body">
                      <form >



                      <div className="mb-3">
     
      <div className="input-group input-group-merge">
       
        <input
          type="hidden"
          name="userid"
          className="form-control"
          id="basic-icon-default-fullname"
          placeholder="John Doe"
          aria-label="John Doe"
          aria-describedby="basic-icon-default-fullname2"
          onChange={(event) => setUserid(event.target.value)}
          value={userid} // Set the initial value to the "userid" state
          readOnly
        />
        
      </div>
    </div>

                        <div className="mb-3">
                          <label className="form-label" for="basic-icon-default-fullname">ADD Provider Name</label>
                          <div className="input-group input-group-merge">
                            <span id="basic-icon-default-fullname2" className="input-group-text"
                              ><i className="bx bx-user"></i
                            ></span>
                            <input
                              type="text"
                              name="adprovider"
                              className={`form-control ${formErrors.adprovider ? 'is-invalid' : ''}`}
                              required
                              id="basic-icon-default-fullname"
                              placeholder="John Doe"
                              aria-label="John Doe"
                              aria-describedby="basic-icon-default-fullname2"
                              onChange={adprovider=>setAdprovider(adprovider.target.value)} value={adprovider}
                            />
                             {formErrors.adprovider && (
                                <div className="invalid-feedback">{formErrors.adprovider}</div>
                              )}

                          </div>
                        </div>

                        
                        <div className="mb-3">
                          <label className="form-label" for="basic-icon-default-fullname">adtitle</label>
                          <div className="input-group input-group-merge">
                            <span id="basic-icon-default-fullname2" className="input-group-text"
                              ><i className="bx bx-user"></i
                            ></span>
                            <input
                              type="text"
                              name="adtitle"
                              className={`form-control ${formErrors.adtitle ? 'is-invalid' : ''}`}
                              required
                              id="basic-icon-default-fullname"
                              placeholder="John Doe"
                              aria-label="John Doe"
                              aria-describedby="basic-icon-default-fullname2"
                              onChange={adtitle=>setAdtitle(adtitle.target.value)} value={adtitle}
                            />
                             {formErrors.adtitle && (
                                <div className="invalid-feedback">{formErrors.adtitle}</div>
                              )}

                          </div>
                        </div>

                        
                        <div className="mb-3">
                          <label className="form-label" for="basic-icon-default-fullname">contract_duration</label>
                          <div className="input-group input-group-merge">
                            <span id="basic-icon-default-fullname2" className="input-group-text"
                              ><i className="bx bx-user"></i
                            ></span>
                            <input
                              type="text"
                              name="contract_duration"
                              className={`form-control ${formErrors.contract_duration ? 'is-invalid' : ''}`}
                              required
                              id="basic-icon-default-fullname"
                              placeholder="John Doe"
                              aria-label="John Doe"
                              aria-describedby="basic-icon-default-fullname2"
                              onChange={contract_duration=>setContract(contract_duration.target.value)} value={contract_duration}
                            />
                             {formErrors.contract_duration && (
                                <div className="invalid-feedback">{formErrors.contract_duration}</div>
                              )}

                          </div>
                        </div>



                        <div className="mb-3">
                          <label className="form-label" for="basic-icon-default-company">ad_duration</label>
                          <div className="input-group input-group-merge">
                            <span id="basic-icon-default-company2" className="input-group-text"
                              ><i className="bx bx-buildings"></i
                            ></span>
                            <input
                              type="text"
                              name='ad_duration'
                              id="basic-icon-default-company"
                              className={`form-control ${formErrors.ad_duration ? 'is-invalid' : ''}`}
                              required
                              placeholder="ACME Inc."
                              aria-label="ACME Inc."
                              aria-describedby="basic-icon-default-company2"
                              onChange={ad_duration=>setDuration(ad_duration.target.value)} value={ad_duration}
                            />
                             {formErrors.ad_duration && (
                                <div className="invalid-feedback">{formErrors.ad_duration}</div>
                              )}
                          </div>

                        </div>
                     
                        <div className="mb-3">
                          <label className="form-label" for="basic-icon-default-adtitle">description</label>
                          <div className="input-group input-group-merge">
                            <span className="input-group-text"><i className="bx bx-envelope"></i></span>
                            <input
                              type="text"
                              name="description"
                              id="basic-icon-default-adtitle"
                              className="form-control"
                              placeholder="john.doe"
                              aria-label="john.doe"
                              aria-describedby="basic-icon-default-adtitle2"
                              onChange={description=>setDescription(description.target.value)} value={description}
                            />
                           

                           
                          </div>
                          
                        </div>

                        <div className="mb-3">
                          <label className="form-label" for="basic-icon-default-adtitle">adtype</label>
                          <div className="input-group input-group-merge">
                            <span className="input-group-text"><i className="bx bx-envelope"></i></span>
                            <input
                              type="text"
                              name="adtype"
                              id="basic-icon-default-adtitle"
                              className={`form-control ${formErrors.adtype ? 'is-invalid' : ''}`}
                              required
                              placeholder="john.doe"
                              aria-label="john.doe"
                              aria-describedby="basic-icon-default-adtitle2"
                              onChange={adtype=>setType(adtype.target.value)} value={adtype}

                            />
                             {formErrors.adtype && (
                                <div className="invalid-feedback">{formErrors.adtype}</div>
                              )}

                          
                          </div>
                        </div>












                        <div className="mb-3">
                          <label className="form-label" for="basic-icon-default-adtitle">video Link</label>
                          <div className="input-group input-group-merge">
                            <span className="input-group-text"><i className="bx bx-envelope"></i></span>
                            <input
                              type="text"
                              name="video"
                              className={`form-control ${formErrors.video ? 'is-invalid' : ''}`}

                              id="basic-icon-default-adtitle"
                              placeholder="john.doe"
                              aria-label="john.doe"
                              aria-describedby="basic-icon-default-adtitle2"
                              onChange={video=>setVideo(video.target.value)} value={video}

                            />

                            {formErrors.video && (
                                <div className="invalid-feedback">{formErrors.video}</div>
                              )}
                           
                          </div>
                          
                        </div>



                        <div className="mb-3">
                          <label className="form-label" for="basic-icon-default-adtitle">Advertisement Image</label>
                          <div className="input-group input-group-merge">
                            <span className="input-group-text"><i className="bx bx-envelope"></i></span>
                            <input
                              type="file"
                              name="image"
                              className={`form-control ${formErrors.image ? 'is-invalid' : ''}`}

                              id="basic-icon-default-adtitle"
                              placeholder="john.doe"
                              aria-label="john.doe"
                              aria-describedby="basic-icon-default-adtitle2"
                              onChange={handleChange}
                             
                            />
                              {formErrors.image && (
                                <div className="invalid-feedback">{formErrors.image}</div>
                              )}
                           
                          </div>
                          
                        </div>



                       

   

                        <button onClick={handleSubmit} type="submit" className="btn btn" style={{ backgroundColor: "rgb(127, 1, 75)", color: "#fff" }} disabled={loading}>
                  {loading ? (
                    <img src={Spinner} alt="spinner" style={{ width:"30px" }} />
                  ) : (
                    'Submit'
                  )}
                </button>


                        {/* <button type="submit" className="btn btn-primary" >Send</button> */}
                      </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Footer />
              <div className="content-backdrop fade"></div>
            </div>
          </div>
        </div>
        <div className="layout-overlay layout-menu-toggle"></div>
      </div>

      <div className="buy-now">
        <a
          href="https://themeselection.com/products/sneat-bootstrap-html-admin-template/"
          target="_blank"
          className="btn btn-danger btn-buy-now"
        >
          Upgrade to Pro
        </a>
      </div>
    </React.Fragment>
  );
}

export default AdvertiseAdd;
