import React, {  useState,useEffect} from 'react'
import Footer from '../Footer';
import Header from '../Header';
import Sidebar from '../Sidebar';
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Dashboard from '../dashboard/Dashboard';
//import { useNavigate } from 'react-router-dom';
import Spinner from '../spinner.gif';

function TentAdd() {
  const[name,setName]=useState('');
  const[type,setType] = useState('');
  const[address,setAddress] = useState('');
  const[contact,setContact] = useState('');
  const[details,setDetails] = useState('');     
  const [userid, setUserid] = useState('');
  const [video, setVideo] = useState('');
 // const navigate = useNavigate();
   
 const [formErrors, setFormErrors] = useState({}); // Initialize form error state
 const [loading, setLoading] = useState(false);
  
 function validateForm() {
  const errors = {};

  
  if (!type || !/^[a-zA-Z\s]+$/.test(type)) {
    errors.type = 'Type is required and should contain only letters';
  }

  
  
  if (!name || !/^[a-zA-Z\s]+$/.test(name)) {
    errors.name = 'Name is required and should contain only letters';
  }
 
if (!contact || !/^\d{10}$/.test(contact)) {
errors.contact = 'Contact is required and should be a 10-digit number';
}

if (!address) {
    errors.address = 'Address is required';
  }

  
  if (!video) {
    errors.video = 'Video is required';
  }

  
  if (!details) {
    errors.details = 'Details is required';
  }

  setFormErrors(errors);

  return Object.keys(errors).length === 0;
}

  const handleSubmit = async () => 
  {
    if (validateForm()) {

      setLoading(true);
            const data={
          "name":name,
          "type":type,
          "address":address,
          "contact":contact,
          "details":details,
          "userid":userid,
          "video":video
         
       
      };
      
      const token = localStorage.getItem('uid');
      const idd = localStorage.getItem('id');
      //console.log("id",idd);
      if (!token) {          
        window.location.href = '/logintoken';
        return;
      }
      //console.log("token data",token);
      const config = {
        headers: {
          Authorization: `Bearer ${token}`
        }
      };

      
      //console.log(data);
      axios.post('https://api.bookmyfunctionhall.com/api/Tent', data,config)
      .then((resp)=>{
        const data = resp.data;
        //console.log(resp);
        if(data[0].status === "success")
        {
            //console.log("Data Added");
            toast.success('Data Added!!!');
            window.location.href='/tentindex';
        }
        else
        {
          toast.err('soryy Added!!!');

            //console.log("Error");
        }
    });





  }
      
  }


  const destroyToken = () => {
    localStorage.removeItem('uid');
    // Redirect to login route or perform any other desired action
    // Redirecting to the login page after destroying the token
    window.location.href="/logintoken"
  };




  useEffect(() => {
    const idd = localStorage.getItem('id');
    if (idd) {
      setUserid(idd); 
    }
  }, []); 



  return (
    <React.Fragment>
       <ToastContainer />
      <div className="layout-wrapper layout-content-navbar">
        <div className="layout-container">
          {/* <Sidebar /> */}
          <Dashboard/>
          <div className="layout-page">
            <Header />
            <div className="content-wrapper">
              <div className="container-xxl flex-grow-1 container-p-y">
                <h4 className="fw-bold py-3 mb-4">
                  <span className="text-muted fw-light">Tent </span> Add
                </h4>
                <div className="row">
                  <div className="col-xl">
                    <div className="card mb-4 col-md-6">
                      <div className="card-header d-flex justify-content-between align-items-center">
                        <h5 className="mb-0">Tent Add</h5>
                        <small className="text-muted float-end">Merged input group</small>
                      </div>
                      <div className="card-body">
                      <form>

                      
                 


                      <div className="mb-3">

                      <div className="input-group input-group-merge">

                      <input
                      type="hidden"
                      name="userid"
                      className="form-control"
                      id="basic-icon-default-fullname"
                      placeholder="John Doe"
                      aria-label="John Doe"
                      aria-describedby="basic-icon-default-fullname2"
                      onChange={(event) => setUserid(event.target.value)}
                      value={userid} // Set the initial value to the "userid" state
                      readOnly
                      />
                      </div>
                      </div>



                      <div className="mb-3">
                          <label className="form-label" for="basic-icon-default-fullname">Video</label>
                          <div className="input-group input-group-merge">
                            <span id="basic-icon-default-fullname2" className="input-group-text">
                              <i className="bx bx-user"></i>
                            </span>
                            <input
                              type="text"
                              name="video"
                              className={`form-control ${formErrors.video ? 'is-invalid' : ''}`}
                              id="basic-icon-default-fullname"
                              placeholder="John Doe"
                              aria-label="John Doe"
                              aria-describedby="basic-icon-default-fullname2"
                              onChange={video => setVideo(video.target.value)} value={video}
                            />
                             {formErrors.video && (
                                <div className="invalid-feedback">{formErrors.video}</div>
                              )}
                          </div>
                        </div>

                        <div className="mb-3">
                          <label className="form-label" for="basic-icon-default-fullname">Full Name</label>
                          <div className="input-group input-group-merge">
                            <span id="basic-icon-default-fullname2" className="input-group-text">
                              <i className="bx bx-user"></i>
                            </span>
                            <input
                              type="text"
                              name="name"
                              className={`form-control ${formErrors.name ? 'is-invalid' : ''}`}
                              id="basic-icon-default-fullname"
                              placeholder="John Doe"
                              aria-label="John Doe"
                              aria-describedby="basic-icon-default-fullname2"
                              onChange={name => setName(name.target.value)} value={name}
                            />
                             {formErrors.name && (
                                <div className="invalid-feedback">{formErrors.name}</div>
                              )}
                          </div>
                        </div>
                        <div className="mb-3">
                          <label className="form-label" for="basic-icon-default-company">type</label>
                          <div className="input-group input-group-merge">
                            <span id="basic-icon-default-company2" className="input-group-text"
                              ><i className="bx bx-buildings"></i
                            ></span>
                            <input
                              type="text"
                              name='type'
                              id="basic-icon-default-company"
                              className={`form-control ${formErrors.type ? 'is-invalid' : ''}`}
                              placeholder="ACME Inc."
                              aria-label="ACME Inc."
                              aria-describedby="basic-icon-default-company2"
                              onChange={type=>setType(type.target.value)} value={type}
                            />
                             {formErrors.type && (
                                <div className="invalid-feedback">{formErrors.type}</div>
                              )}
                          </div>

                        </div>
                        <div className="mb-3">
                          <label className="form-label" for="basic-icon-default-type">address</label>
                          <div className="input-group input-group-merge">
                            <span className="input-group-text"><i className="bx bx-envelope"></i></span>
                            <input
                              type="text"
                              name="address"
                              id="basic-icon-default-type"
                              className={`form-control ${formErrors.address ? 'is-invalid' : ''}`}
                              placeholder="john.doe"
                              aria-label="john.doe"
                              aria-describedby="basic-icon-default-type2"
                              onChange={address=>setAddress(address.target.value)} value={address}
                            />
                            {formErrors.address && (
                                <div className="invalid-feedback">{formErrors.address}</div>
                              )}
                           
                          </div>
                          <div className="form-text">You can use letters, numbers & periods</div>
                        </div>

                        <div className="mb-3">
                          <label className="form-label" for="basic-icon-default-type">contact</label>
                          <div className="input-group input-group-merge">
                            <span className="input-group-text"><i className="bx bx-envelope"></i></span>
                            <input
                              type="text"
                              name="contact"
                              id="basic-icon-default-type"
                              className={`form-control ${formErrors.contact ? 'is-invalid' : ''}`}
                              placeholder="john.doe"
                              aria-label="john.doe"
                              aria-describedby="basic-icon-default-type2"
                              onChange={contact=>setContact(contact.target.value)} value={contact}

                            />
                             {formErrors.contact && (
                                <div className="invalid-feedback">{formErrors.contact}</div>
                              )}
                           
                          </div>
                          <div className="form-text">You can use letters, numbers & periods</div>
                        </div>



                        <div className="mb-3">
                            <label className="form-label" for="basic-icon-default-type">details</label>
                            <div className="input-group input-group-merge">
                              <span className="input-group-text"><i className="bx bx-envelope"></i></span>
                              <input
                                type="text"
                                name="details"
                                id="basic-icon-default-type"
                                className={`form-control ${formErrors.details ? 'is-invalid' : ''}`}
                                placeholder="john.doe"
                                aria-label="john.doe"
                                aria-describedby="basic-icon-default-type2"
                                onChange={details => setDetails(details.target.value)} value={details}
                              />
                               {formErrors.details && (
                                <div className="invalid-feedback">{formErrors.details}</div>
                              )}
                             
                            </div>
                            <div className="form-text">You can use letters, numbers & periods</div>
                          </div>

                          <button type="submit" onClick={handleSubmit} className="btn btn" style={{ backgroundColor: "rgb(127, 1, 75)", color: "#fff" }} disabled={loading}>
                  {loading ? (
                    <img src={Spinner} alt="spinner" style={{ width:"30px" }} />
                  ) : (
                    'Submit'
                  )}
                  </button>



</form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Footer />
              <div className="content-backdrop fade"></div>
            </div>
          </div>
        </div>
        <div className="layout-overlay layout-menu-toggle"></div>
      </div>

      <div className="buy-now">
        <a
          href="https://themeselection.com/products/sneat-bootstrap-html-admin-template/"
          target="_blank"
          className="btn btn-danger btn-buy-now"
        >
          Upgrade to Pro
        </a>
      </div>
    </React.Fragment>
  );
}

export default TentAdd;
