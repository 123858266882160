import React, { useEffect, useState } from 'react';
import Footer from '../Footer';
import Header from '../Header';
import Sidebar from '../Sidebar';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useParams } from 'react-router-dom';
import axiosInstance from '../../axiosConfig';
import Dashboard from '../dashboard/Dashboard';
import Spinner from '../spinner.gif';

function AdvertiseEdit() {
  const { id } = useParams();
  const [adprovider, setAdprovider] = useState('');
  const [adtitle, setAdtitle] = useState('');
  const [description, setDescription] = useState('');
  const [ad_duration, setAdDuration] = useState('');
  const [adtype, setAdtype] = useState('');
  const [image, setImage] = useState(null);
  const [video, setVideo] = useState('');
  const [contract_duration, setContractDuration] = useState('');
  const [userid, setUserid] = useState('');
  const [loading, setLoading] = useState(false);
  const [formErrors, setFormErrors] = useState({}); // Initialize form error state


  function fetchAdvertiseData() {
    axiosInstance
      .get(`https://api.bookmyfunctionhall.com/api/Advertise/${id}`)
      .then((res) => {
        const data = res.data;
        setAdprovider(data.adprovider);
        setAdtitle(data.adtitle);
        setDescription(data.description);
        setAdDuration(data.ad_duration);
        setAdtype(data.adtype);
        setImage(data.image);
        setVideo(data.video);
        setUserid(data.userid);
        setContractDuration(data.contract_duration);
      })
      .catch((error) => {
        //console.error('Error fetching data:', error);
      });
  }
  


  function validateForm() {
    const errors = {};

    if (!adtitle) {
      errors.adtitle = 'adtitle is required';
    }

    if (!adprovider) {
      errors.adprovider = 'adprovider is required';
    }

    if (!ad_duration) {
      errors.ad_duration = 'ad_duration is required';
    }

    if (!contract_duration) {
      errors.contract_duration = 'contract_duration is required';
    }
    if(!image)
    {
      errors.image = 'image is required';
    }
    if(!video)
    {
      errors.video = 'video is required';

    }
   
    setFormErrors(errors);

    return Object.keys(errors).length === 0;
  }




  function handleImageChange(e) {
    setImage(e.target.files[0]);
  }

  function handleVideoChange(e) {
    setVideo(e.target.files[0]);
  }
  function updateAdvertise(e) {
    e.preventDefault();
  
    // if (!adprovider || !adtitle || !ad_duration || !contract_duration  ) {
    //   toast.error('Please fill in all required fields.', { autoClose: 2000 });
    //   return;
    // }
    // setLoading(true);
    if (validateForm()) {
      setLoading(true);

    const formData = new FormData();
    formData.append('_method', 'PUT');
    formData.append('adprovider', adprovider);
    formData.append('adtitle', adtitle);
    formData.append('description', description);
    formData.append('ad_duration', ad_duration);
    formData.append('adtype', adtype);
    formData.append('contract_duration', contract_duration);
    formData.append('image', image);
    formData.append('video', video);
    formData.append('userid', userid);
  
  
    axiosInstance
      .post(`https://api.bookmyfunctionhall.com/api/Advertise/${id}`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
      .then((resp) => {
        const data = resp.data;
        if (data.status === 'success') {
          console.log('Data Updated');
          toast.success('Data Updated!!!', { autoClose: 2000 });
          // Use React Router's history object to navigate
         // history.push('/advertiseIndex');
         window.location.href='/advertiseIndex'
        } else {
          //console.log('Error data:', resp.data);
          toast.error('Error occurred while updating data', { autoClose: 2000 });
        }
      })
      .catch((error) => {
        //console.log('Errorrr:', error);
        toast.error('An error occurred while making the request', { autoClose: 2000 });
      })
      .finally(() => {
        setLoading(false); // Stop loading
      });
  }
}
  useEffect(() => {
    fetchAdvertiseData();
    const idd = localStorage.getItem('id');
    if (idd) {
      setUserid(idd); 
    }
  }, []);



  return (
    <React.Fragment>
       <ToastContainer />
      <div className="layout-wrapper layout-content-navbar">
        <div className="layout-container">
          {/* <Sidebar /> */}
          <Dashboard/>
          <div className="layout-page">
            <Header />
            <div className="content-wrapper">
              <div className="container-xxl flex-grow-1 container-p-y">
                <h4 className="fw-bold py-3 mb-4">
                  <span className="text-muted fw-light">Advertise/</span> Advertise Edit
                </h4>
                <div className="row">
                  <div className="col-xl">
                    <div className="card mb-4 col-md-6">
                      <div className="card-header d-flex justify-content-between align-items-center">
                        <h5 className="mb-0">Advertise</h5>
                      </div>
                      <div className="card-body">
                      <form onSubmit={updateAdvertise}>

                      <div className="mb-3">
     
     <div className="input-group input-group-merge">
      
       <input
         type="hidden"
         name="userid"
         className="form-control"
         id="basic-icon-default-fullname"
         placeholder="John Doe"
         aria-label="John Doe"
         aria-describedby="basic-icon-default-fullname2"
         onChange={(event) => setUserid(event.target.value)}
         value={userid} // Set the initial value to the "userid" state
         readOnly
       />
     </div>
   </div>


                        <div className="mb-3">
                        <label className="form-label" htmlFor="basic-icon-default-fullname">adprovider</label>
                          <div className="input-group input-group-merge">
                            <span id="basic-icon-default-fullname2" className="input-group-text"
                              ><i className="bx bx-user"></i
                            ></span>
                            
                            <input
                              type="text"
                              name="adprovider"
                              className={`form-control ${formErrors.adprovider ? 'is-invalid' : ''}`}

                              id="basic-icon-default-fullname"
                              placeholder="John Doe"
                              aria-label="John Doe"
                              aria-describedby="basic-icon-default-fullname2"
                              onChange={adprovider=>setAdprovider(adprovider.target.value)} value={adprovider}
                            />
                             {formErrors.adprovider && (
                                <div className="invalid-feedback">{formErrors.adprovider}</div>
                              )}

                          </div>
                        </div>

                        
                        <div className="mb-3">
                        <label className="form-label" htmlFor="basic-icon-default-fullname">adtitle</label>
                          <div className="input-group input-group-merge">
                            <span id="basic-icon-default-fullname2" className="input-group-text"
                              ><i className="bx bx-user"></i
                            ></span>
                            <input
                              type="text"
                              name="adtitle"
                              className={`form-control ${formErrors.adtitle ? 'is-invalid' : ''}`}

                              id="basic-icon-default-fullname"
                              placeholder="John Doe"
                              aria-label="John Doe"
                              aria-describedby="basic-icon-default-fullname2"
                              onChange={adtitle=>setAdtitle(adtitle.target.value)} value={adtitle}
                            />
                             {formErrors.adtitle && (
                                <div className="invalid-feedback">{formErrors.adtitle}</div>
                              )}

                          </div>
                        </div>

                    


                        <div className="mb-3">
                          <label className="form-label" for="basic-icon-default-company">description</label>
                          <div className="input-group input-group-merge">
                            <span id="basic-icon-default-company2" className="input-group-text"
                              ><i className="bx bx-buildings"></i
                            ></span>
                            <input
                              type="text"
                              name='description'
                              id="basic-icon-default-company"
                              className="form-control"
                              placeholder="ACME Inc."
                              aria-label="ACME Inc."
                              aria-describedby="basic-icon-default-company2"
                              onChange={description=>setDescription(description.target.value)} value={description}
                            />
                          </div>

                        </div>
                     
                        <div className="mb-3">
                          <label className="form-label" for="basic-icon-default-adtitle">adtype</label>
                          <div className="input-group input-group-merge">
                            <span className="input-group-text"><i className="bx bx-envelope"></i></span>
                            <input
                              type="text"
                              name="adtype"
                              id="basic-icon-default-adtitle"
                              className={`form-control ${formErrors.adtype ? 'is-invalid' : ''}`}

                              placeholder="john.doe"
                              aria-label="john.doe"
                              aria-describedby="basic-icon-default-adtitle2"
                              onChange={adtype=>setAdtype(adtype.target.value)} value={adtype}
                            />
                             {formErrors.adtype && (
                                <div className="invalid-feedback">{formErrors.adtype}</div>
                              )}


                            
                          </div>
                          
                        </div>

                        <div className="mb-3">
                          <label className="form-label" for="basic-icon-default-adtitle">contract_duration</label>
                          <div className="input-group input-group-merge">
                            <span className="input-group-text"><i className="bx bx-envelope"></i></span>
                            <input
                              type="text"
                              name="contract_duration"
                              id="basic-icon-default-adtitle"
                              className={`form-control ${formErrors.contract_duration ? 'is-invalid' : ''}`}

                              placeholder="john.doe"
                              aria-label="john.doe"
                              aria-describedby="basic-icon-default-adtitle2"
                              onChange={contract_duration=>setContractDuration(contract_duration.target.value)} value={contract_duration}

                            />
                              {formErrors.contract_duration && (
                                <div className="invalid-feedback">{formErrors.contract_duration}</div>
                              )}
                            
                          </div>
                          
                        </div>


                        <div className="mb-3">
                          <label className="form-label" for="basic-icon-default-adtitle">ad_duration</label>
                          <div className="input-group input-group-merge">
                            <span className="input-group-text"><i className="bx bx-envelope"></i></span>
                            <input
                              type="text"
                              name="ad_duration"
                              id="basic-icon-default-adtitle"
                              className={`form-control ${formErrors.ad_duration ? 'is-invalid' : ''}`}

                              placeholder="john.doe"
                              aria-label="john.doe"
                              aria-describedby="basic-icon-default-adtitle2"
                              onChange={ad_duration=>setAdDuration(ad_duration.target.value)} value={ad_duration}

                            />
                             {formErrors.ad_duration && (
                                <div className="invalid-feedback">{formErrors.ad_duration}</div>
                              )}
                            
                          </div>
                          
                        </div>






                      

                        <div className="mb-3">
        <label className="form-label" htmlFor="basic-icon-default-images">
          Advertisement Image
        </label>
        <div className="input-group input-group-merge">
          <span className="input-group-text">
            <i className="bx bx-envelope"></i>
          </span>
          <input
            type="file"
            name="image"
            id="images"  
            className={`form-control ${formErrors.image ? 'is-invalid' : ''}`}

            accept="image/*" // Accept only image files
            onChange={handleImageChange}
          />
           {formErrors.image && (
                                <div className="invalid-feedback">{formErrors.image}</div>
                              )}
          <span id="basic-icon-default-images2" className="input-group-text">
            @example.com
          </span>
        </div>
        
      </div>

   

      <div className="mb-3">
                          <label className="form-label" for="basic-icon-default-adtitle">video</label>
                          <div className="input-group input-group-merge">
                            <span className="input-group-text"><i className="bx bx-envelope"></i></span>
                            <input
                              type="text"
                              name="video"
                              id="basic-icon-default-adtitle"
                              className={`form-control ${formErrors.video ? 'is-invalid' : ''}`}

                              placeholder="john.doe"
                              aria-label="john.doe"
                              aria-describedby="basic-icon-default-adtitle2"
                              onChange={video=>setVideo(video.target.value)} value={video}

                            />
                             {formErrors.video && (
                                <div className="invalid-feedback">{formErrors.video}</div>
                              )}
                            
                          </div>
                          
                        </div>
   





                        <button onClick={updateAdvertise} type="submit" className="btn btn" style={{ backgroundColor: "rgb(127, 1, 75)", color: "#fff" }} disabled={loading}>
                  {loading ? (
                    <img src={Spinner} alt="spinner" style={{ width:"30px" }} />
                  ) : (
                    'Submit'
                  )}
                  </button>
                        {/* <button type="button" class="btn btn-info"  onClick={updateAdvertise}>Update</button> */}
                      </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Footer />
              <div className="content-backdrop fade"></div>
            </div>
          </div>
        </div>
        <div className="layout-overlay layout-menu-toggle"></div>
      </div>

      <div className="buy-now">
        <a
          href="https://themeselection.com/products/sneat-bootstrap-html-admin-template/"
          target="_blank"
          className="btn btn-danger btn-buy-now"
        >
          Upgrade to Pro
        </a>
      </div>
    </React.Fragment>
  );
}

export default AdvertiseEdit;
