import React, { useEffect, useState } from 'react';
import Footer from '../Footer';
import Header from '../Header';
import Sidebar from '../Sidebar';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useParams } from 'react-router-dom';
import Dashboard from '../dashboard/Dashboard';
import Spinner from '../spinner.gif';

function MarketingEdit(props) {
  const [client, setClient] = useState('');
  const [email, setEmail] = useState('');
  const [contact, setContact] = useState('');
  const [adtitle, setAdtitle] = useState('');
  const [description, setDescription] = useState('');
  const [length, setLength] = useState('');
  const [contract, setContract] = useState('');
  const [status, setStatus] = useState('');
  const [image, setImage] = useState(null);
  const [video, setVideo] = useState(null);
  const [userid, setUserid] = useState('');
  const [formErrors, setFormErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const { id } = useParams();
  const token = localStorage.getItem('uid');
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${token}`,
    },
  };

  function fetchAddOnData() {
    axios
      .get(`https://api.bookmyfunctionhall.com/api/Marketing/${id}`, config)
      .then((res) => {
        const data = res.data;
        console.log("editfetch:",data);
        setClient(data.client);
        setEmail(data.email);
        setAdtitle(data.adtitle);
        setDescription(data.description);
        setContract(data.contract);
        setImage(data.image);
        setLength(data.length);
        setStatus(data.status);
        setUserid(data.userid);
        setVideo(data.video);
        setContact(data.contact);
      })
      .catch((error) => {
        console.error('Error', error);
      });
  }

  

  function validateForm() {
    const errors = {};
  
    if (!client || !/^[a-zA-Z\s]+$/.test(client)) {
      errors.client = 'Client is required and should contain only letters';
    }
  
  
    if (!adtitle || !/^[a-zA-Z\s]+$/.test(adtitle)) {
      errors.adtitle = 'Adtitle is required and should contain only letters';
    }
  
    if (!email || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
      errors.email = 'Email is required and should be a valid email address';
    }

if (!contact || !/^\d{10}$/.test(contact)) {
  errors.contact = 'Contact is required and should be a 10-digit number';
}

if (!contract) {
      errors.contract = 'Contract is required';
    }
  
if (!length) {
      errors.length = 'Length is required';
    }

if (!description) {
      errors.description = 'Description is required';
    }
 
  
   
    if (!image) {
      errors.image = 'Image is required';
    }
   
    if (!video) {
      errors.video = 'Video is required';
    }
 
    setFormErrors(errors);
  
    return Object.keys(errors).length === 0;
  }

  function handleImageChange(e) {
    setImage(e.target.files[0]);
  }

  function updateMarketingData(e) {
    e.preventDefault();

    if (validateForm()) {
      setLoading(true);

      const formData = new FormData();
      formData.append('_method', 'put');
      formData.append('client', client);
      formData.append('email', email);
      formData.append('adtitle', adtitle);
      formData.append('description', description);
      formData.append('contract', contract);
      formData.append('contact', contact);
      formData.append('image', image);
      formData.append('length', length);
      formData.append('status', status);
      formData.append('userid', userid);
      formData.append('video', video);

      axios
        .post(`https://api.bookmyfunctionhall.com/api/Marketing/${id}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${token}`,
          },
        })
        .then((resp) => {
          const data = resp.data;
          if (data.status === 'success') {
            toast.success('Data Updated!!!', { autoClose: 2000 });
            window.location.href = '/MarketingIndex';
          } else {
            toast.error('Error occurred while updating data', { autoClose: 2000 });
          }
        })
        .catch((error) => {
          console.error('Error:', error);
          toast.error('An error occurred while making the request', { autoClose: 2000 });
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }

  useEffect(() => {
    fetchAddOnData();
    const idd = localStorage.getItem('id');
    if (idd) {
      setUserid(idd);
    }
  }, []);

  return (
    <React.Fragment>
      <ToastContainer />
      <div className="layout-wrapper layout-content-navbar">
        <div className="layout-container">
          <Dashboard />
          <div className="layout-page">
            <Header />
            <div className="content-wrapper">
              <div className="container-xxl flex-grow-1 container-p-y">
                <h4 className="fw-bold py-3 mb-4">
                  <span className="text-muted fw-light">Marketing /</span>Edit
                </h4>
                <div className="row">
                  <div className="col-xl">
                    <div className="card mb-4 col-md-6">
                      <div className="card-header d-flex justify-content-between align-items-center">
                        <h5 className="mb-0">AddOn Edits</h5>
                      </div>
                      <div className="card-body">
                      <form encType="multipart/form-data">

                           
<div className="mb-3">
    <div className="input-group input-group-merge">
      <input
        type="hidden"
        name="userid"
        className="form-control"
        id="basic-icon-default-fullname"
        placeholder="userid Name"
        aria-label="userid Name"
        aria-describedby="basic-icon-default-fullname2"
        onChange={(event) => setUserid(event.target.value)}
        value={userid} // Set the initial value to the "userid" state
        readOnly
      />
    </div>
  </div>



  <div className="mb-3">
    <label className="form-label" htmlFor="basic-icon-default-fullname">Client Name</label>
    <div className="input-group input-group-merge">
      <span className="input-group-text"><i className="bx bx-user"></i></span>
      <input
        type="text"
        name="client"
           className={`form-control ${formErrors.role ? 'is-invalid' : ''}`}

        id="basic-icon-default-fullname"
        placeholder="Client Name"
        aria-label="Client Name"
        aria-describedby="basic-icon-default-fullname2"
        onChange={client=>setClient(client.target.value)} value={client}
      />
      {formErrors.client && (
           <div className="invalid-feedback">{formErrors.client}</div>
        )}

    </div>
  </div>




  <div className="mb-3">
                          <label className="form-label" for="basic-icon-default-company">contact</label>
                          <div className="input-group input-group-merge">
                            <span id="basic-icon-default-company2" className="input-group-text"
                              ><i className="bx bx-buildings"></i
                            ></span>
                            <input
                              type="text"
                              name='contact'
                              id="basic-icon-default-company"
                              className={`form-control ${formErrors.contact ? 'is-invalid' : ''}`}
                              placeholder="ACME Inc."
                              aria-label="ACME Inc."
                              aria-describedby="basic-icon-default-company2"
                              onChange={contact=>setContact(contact.target.value)} value={contact}
                            />
                            {formErrors.contact && (
                                <div className="invalid-feedback">{formErrors.contact}</div>
                              )}
                          </div>

                        </div>



  <div className="mb-3">
    <label className="form-label" htmlFor="basic-icon-default-fullname">email</label>
    <div className="input-group input-group-merge">
      <span className="input-group-text"><i className="bx bx-user"></i></span>
      <input
        type="text"
        name="email"
           className={`form-control ${formErrors.email ? 'is-invalid' : ''}`}

        id="basic-icon-default-fullname"
        placeholder="email Name"
        aria-label="email Name"
        aria-describedby="basic-icon-default-fullname2"
          onChange={email=>setEmail(email.target.value)} value={email}
        
      />
    </div>
  </div>



  <div className="mb-3">
    <label className="form-label" htmlFor="basic-icon-default-fullname">adtitle</label>
    <div className="input-group input-group-merge">
      <span className="input-group-text"><i className="bx bx-user"></i></span>
      <input
        type="text"
        name="adtitle"
           className={`form-control ${formErrors.adtitle ? 'is-invalid' : ''}`}

        id="basic-icon-default-fullname"
        placeholder="adtitle Name"
        aria-label="adtitle Name"
        aria-describedby="basic-icon-default-fullname2"
          onChange={adtitle=>setAdtitle(adtitle.target.value)} value={adtitle}
        
      />
    </div>
  </div>






  <div className="mb-3">
    <label className="form-label" htmlFor="basic-icon-default-fullname">description</label>
    <div className="input-group input-group-merge">
      <span className="input-group-text"><i className="bx bx-user"></i></span>
      <input
        type="text"
        name="description"
           className={`form-control ${formErrors.description ? 'is-invalid' : ''}`}

        id="basic-icon-default-fullname"
        placeholder="description Name"
        aria-label="description Name"
        aria-describedby="basic-icon-default-fullname2"
          onChange={description=>setDescription(description.target.value)} value={description}
        
      />
    </div>
  </div>



  <div className="mb-3">
    <label className="form-label" htmlFor="basic-icon-default-fullname">video</label>
    <div className="input-group input-group-merge">
      <span className="input-group-text"><i className="bx bx-user"></i></span>
      <input
        type="text"
        name="video"
           className={`form-control ${formErrors.video ? 'is-invalid' : ''}`}

        id="basic-icon-default-fullname"
        placeholder="video Name"
        aria-label="video Name"
        aria-describedby="basic-icon-default-fullname2"
          onChange={video=>setVideo(video.target.value)} value={video}
        
      />
    </div>
  </div>




  <div className="mb-3">
    <label className="form-label" htmlFor="basic-icon-default-fullname">length</label>
    <div className="input-group input-group-merge">
      <span className="input-group-text"><i className="bx bx-user"></i></span>
      <input
        type="text"
        name="length"
           className={`form-control ${formErrors.length ? 'is-invalid' : ''}`}

        id="basic-icon-default-fullname"
        placeholder="length Name"
        aria-label="length Name"
        aria-describedby="basic-icon-default-fullname2"
          onChange={length=>setLength(length.target.value)} value={length}
        
      />
    </div>
  </div>



  <div className="mb-3">
    <label className="form-label" htmlFor="basic-icon-default-fullname">contract</label>
    <div className="input-group input-group-merge">
      <span className="input-group-text"><i className="bx bx-user"></i></span>
      <input
        type="text"
        name="contract"
           className={`form-control ${formErrors.contract ? 'is-invalid' : ''}`}

        id="basic-icon-default-fullname"
        placeholder="contract Name"
        aria-label="contract Name"
        aria-describedby="basic-icon-default-fullname2"
          onChange={contract=>setContract(contract.target.value)} value={contract}
        
      />
    </div>
  </div>


  


{/* 
  <div className="mb-3">
                          <label className={`form-control ${formErrors.status ? 'is-invalid' : ''}`} for="basic-icon-default-fullname">Status</label>
                          <select class="form-control" name="status" onChange={status=>setStatus(status.target.value)} value={status}>
                                             <option value="active">Active</option>
                                             <option value="Inactive">Inactive</option>
                                           </select>
                        </div>    */}


<div class="form-group">
                              <label for="">Status</label>
                              <select class="custom-select form-control" name="" id="" onChange={status=>setStatus(status.target.value)} value={status}>
                                <option value="active" >Active</option>
                                <option value="inactive">Inactive</option>

                              </select>
                            </div>




  <div className="mb-3">
    <label className="form-label" htmlFor="basic-icon-default-images">Image</label>
    <div className="input-group input-group-merge">
      <span className="input-group-text"><i className="bx bx-envelope"></i></span>
      <input
        type="file"
        name="image"
        id="basic-icon-default-images"
           className={`form-control ${formErrors.image ? 'is-invalid' : ''}`}

        placeholder="Image File"
        aria-label="Image File"
        aria-describedby="basic-icon-default-images2"
        onChange={handleImageChange}
      />
    </div>
    
  </div>
  <button type="submit" onClick={updateMarketingData} className="btn btn" style={{ backgroundColor: "rgb(127, 1, 75)", color: "#fff" }} disabled={loading}>
                  {loading ? (
                    <img src={Spinner} alt="spinner" style={{ width:"30px" }} />
                  ) : (
                    'Update'
                  )}
                  </button>
</form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Footer />
              <div className="content-backdrop fade"></div>
            </div>
          </div>
        </div>
        <div className="layout-overlay layout-menu-toggle"></div>
      </div>

      <div className="buy-now">
        <a
          href="https://themeselection.com/products/sneat-bootstrap-html-admin-template/"
          target="_blank"
          className="btn btn-danger btn-buy-now"
        >
          Upgrade to Pro
        </a>
      </div>
    </React.Fragment>
  );
}

export default MarketingEdit;
