import React, { useState,useEffect } from 'react';
import Footer from '../Footer';
import Header from '../Header';
import Sidebar from '../Sidebar';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import  axiosInstance  from '../../axiosConfig';
import Dashboard from '../dashboard/Dashboard';
import Spinner from '../spinner.gif';
function LawnAdd() {
  const [name, setName] = useState('');
  const [hallFor, setHallFor] = useState(''); // Renamed 'hallFor' to 'hallFor'
  const [type, setType] = useState('');
  const [address, setAddress] = useState('');
  const [contact, setContact] = useState('');
  const [userid, setUserid] = useState('');
  const [capacity, setCapacity] = useState('');
  const [parkingcap, setParkingcap] = useState('');
  const [kitchen, setKitchen] = useState('');
  const [details, setDetails] = useState('');
 
  const [image, setImages] = useState(null);
  const [videos, setVideos] = useState(null);
  const [formErrors, setFormErrors] = useState({}); // Initialize form error state
  const [loading, setLoading] = useState(false);

  const token = localStorage.getItem('uid');


  function handleChange(e) {
    const { name, value, files } = e.target;
    if (name === 'name') {
      setName(value);
    }
    else if (name === 'for') {
      setHallFor(value);
    }  else if (name === 'type') {
      setType(value);
    }    else if (name === 'address') {
      setAddress(value);
    }  else if (name === 'capacity') {
      setCapacity(value);
    }  else if (name === 'parkingcap') {
      setParkingcap(value);
    }  else if (name === 'kitchen') {
      setKitchen(value);
    }   else if (name === 'contact') {
      setContact(value);
    }  
    else if (name === 'userid') {
      setContact(value);
    }  
    
           
    
    else if (name === 'image') {
      setImages(files[0]);
    }   
  }


  function validateForm() {
    const errors = {};



    if (!type) {
      errors.type = 'type is required ';
    }

    if (!videos) {
      errors.videos = 'videos is required ';
    }

    if (!name || !/^[a-zA-Z\s]+$/.test(name)) {
      errors.name = 'Name is required and should contain only letters';
    }

    if (!contact || !/^\d{10}$/.test(contact)) {
      errors.contact = 'Contact is required and should be a 10-digit number';
    }

    if (!address) {
      errors.address = 'Address is required';
    }

  
    if (!hallFor) {
      errors.hallFor = 'Description is required';
    }
    if (!image) {
      errors.image = 'image is required';
    }
  
  if (!capacity) {
    errors.capacity = 'capacity is required';
  }




    setFormErrors(errors);

    return Object.keys(errors).length === 0;
  }







  function handleSubmit(e) {
    e.preventDefault();


      
    if (validateForm()) {

      setLoading(true);
    const data = new FormData();
    data.append('name', name);
    data.append('for', hallFor);
    // Use the correct variable name here (it was mistakenly named "hallFor" in the provided code)
    data.append('type', type);
    data.append('address', address);
    data.append('capacity', capacity);
    data.append('parking', parkingcap);
    data.append('kitchen', kitchen);
    data.append('contact', contact);
    data.append('details', details);
    data.append('image', image); // Use the correct variable name here (it was mistakenly named "videos" in the provided code)
    data.append('video', videos); // Use the correct variable name here (it was mistakenly named "image" in the provided code)
    data.append('userid',userid ); 
   
    axios
      .post('https://api.bookmyfunctionhall.com/api/Lawn', data, {
        headers: { 'Content-Type': 'multipart/form-data' ,'Authorization':"Bearer "+token}
      })
      .then((resp) => {
        if (resp.data && resp.data[0].status === 'success') {
         // console.log('Data Added');
          toast.success('Data Added!!!');
          window.location.href = '/lawnindex';
        } else {
         // console.log('Error');
          toast.error('Error occurred while adding data');
        }
      })
      .catch((error) => {
       // console.log('Error', error);
        toast.error('An error occurred while making the request');
      })
      .finally(() => {
        setLoading(false); // Stop loading
      });
}

  }

 
useEffect(() => {
  const idd = localStorage.getItem('id');
  if (idd) {
    setUserid(idd); 
  }
}, []); 
  
  return (
    <React.Fragment>
      <ToastContainer />
      <div className="layout-wrapper layout-content-navbar">
        <div className="layout-container">
          {/* <Sidebar /> */}
          <Dashboard/>
          <div className="layout-page">
            <Header />
            <div className="content-wrapper">
              <div className="container-xxl flex-grow-1 container-p-y">
                <h4 className="fw-bold py-3 mb-4">
                  <span className="text-muted fw-light">Lawn</span>
                </h4>
                <div className="row">
                  <div className="col-xl">
                    <div className="card mb-4 col-md-6">
                      <div className="card-header d-flex justify-content-between align-items-center">
                        <h5 className="mb-0">Lawn Add</h5>
                      </div>
                      <div className="card-body">






                      <form>






                      <div className="mb-3">
     
     <div className="input-group input-group-merge">
      
       <input
         type="hidden"
         name="userid"
         className="form-control"
         id="basic-icon-default-fullname"
         placeholder="John Doe"
         aria-label="John Doe"
         aria-describedby="basic-icon-default-fullname2"
         onChange={(event) => setUserid(event.target.value)}
         value={userid} // Set the initial value to the "userid" state
         readOnly
       />
     </div>
   </div>



                        <div className="mb-3">
                          <label className="form-label" for="basic-icon-default-fullname">Name</label>
                          <div className="input-group input-group-merge">
                            <span id="basic-icon-default-fullname2" className="input-group-text"
                              ><i className="bx bx-user"></i
                            ></span>
                            <input
                              type="text"
                              name="name"
                              className={`form-control ${formErrors.name ? 'is-invalid' : ''}`}

                              id="basic-icon-default-fullname"
                              placeholder="John Doe"
                              aria-label="John Doe"
                              aria-describedby="basic-icon-default-fullname2"
                              onChange={name=>setName(name.target.value)} value={name}
                            />
                            {formErrors.name && (
                                <div className="invalid-feedback">{formErrors.name}</div>
                              )}

                          </div>
                        </div>




                        
                        <div className="mb-3">
                          <label className="form-label" for="basic-icon-default-fullname">For</label>
                          <div className="input-group input-group-merge">
                            <span id="basic-icon-default-fullname2" className="input-group-text"
                              ><i className="bx bx-user"></i
                            ></span>
                            <input
              type="text"
              name="for"
              className={`form-control ${formErrors.hallFor ? 'is-invalid' : ''}`}
              id="basic-icon-default-fullname"
              placeholder="John Doe"
              aria-label="John Doe"
              aria-describedby="basic-icon-default-fullname2"
              onChange={(e) => setHallFor(e.target.value)} // Use setHallFor to update the "for" state
              value={hallFor} // Use hallFor instead of hallFor for value
            />
            {formErrors.hallFor && (
                                <div className="invalid-feedback">{formErrors.hallFor}</div>
                              )}
                          </div>
                        </div>










                        


  
                        <div className="mb-3">
                          <label className="form-label" for="basic-icon-default-fullname">type</label>
                          <div className="input-group input-group-merge">
                            <span id="basic-icon-default-fullname2" className="input-group-text"
                              ><i className="bx bx-user"></i
                            ></span>
                            <input
                              type="text"
                              name="type"
                              className={`form-control ${formErrors.type ? 'is-invalid' : ''}`}
                              id="basic-icon-default-fullname"
                              placeholder="John Doe"
                              aria-label="John Doe"
                              aria-describedby="basic-icon-default-fullname2"
                              onChange={type=>setType(type.target.value)} value={type}
                            />
                              {formErrors.type && (
                                <div className="invalid-feedback">{formErrors.type}</div>
                              )}

                          </div>
                        </div>









                  







                        <div className="mb-3">
                          <label className="form-label" for="basic-icon-default-fullname">parking</label>
                          <div className="input-group input-group-merge">
                            <span id="basic-icon-default-fullname2" className="input-group-text"
                              ><i className="bx bx-user"></i
                            ></span>
                            <input
                              type="text"
                              name="parking"
                              className={`form-control ${formErrors.parkingcap ? 'is-invalid' : ''}`}

                              id="basic-icon-default-fullname"
                              placeholder="John Doe"
                              aria-label="John Doe"
                              aria-describedby="basic-icon-default-fullname2"
                              onChange={parkingcap=>setParkingcap(parkingcap.target.value)} value={parkingcap}
                            />
                              {formErrors.parkingcap && (
                                <div className="invalid-feedback">{formErrors.parkingcap}</div>
                              )}

                          </div>
                        </div>





                        <div className="mb-3">
                          <label className="form-label" for="basic-icon-default-fullname">address</label>
                          <div className="input-group input-group-merge">
                            <span id="basic-icon-default-fullname2" className="input-group-text"
                              ><i className="bx bx-user"></i
                            ></span>
                            <input
                              type="text"
                              name="address"
                              className={`form-control ${formErrors.address ? 'is-invalid' : ''}`}
                              id="basic-icon-default-fullname"
                              placeholder="John Doe"
                              aria-label="John Doe"
                              aria-describedby="basic-icon-default-fullname2"
                              onChange={address=>setAddress(address.target.value)} value={address}
                            />
                             {formErrors.address && (
                                <div className="invalid-feedback">{formErrors.address}</div>
                              )}

                          </div>
                        </div>


                        <div className="mb-3">
                          <label className="form-label" for="basic-icon-default-fullname">capacity</label>
                          <div className="input-group input-group-merge">
                            <span id="basic-icon-default-fullname2" className="input-group-text"
                              ><i className="bx bx-user"></i
                            ></span>
                            <input
                              type="text"
                              name="capacity"
                              className={`form-control ${formErrors.capacity ? 'is-invalid' : ''}`}
                              id="basic-icon-default-fullname"
                              placeholder="John Doe"
                              aria-label="John Doe"
                              aria-describedby="basic-icon-default-fullname2"
                              onChange={capacity=>setCapacity(capacity.target.value)} value={capacity}
                            />
                             {formErrors.capacity && (
                                <div className="invalid-feedback">{formErrors.capacity}</div>
                              )}

                          </div>
                        </div>


                     


  
                        <div className="mb-3">
                          <label className="form-label" for="basic-icon-default-fullname">kitchen</label>
                          <div className="input-group input-group-merge">
                            <span id="basic-icon-default-fullname2" className="input-group-text"
                              ><i className="bx bx-user"></i
                            ></span>
                            <input
                              type="text"
                              name="kitchen"
                              className="form-control"
                              id="basic-icon-default-fullname"
                              placeholder="Type Available or Not Available"
                              aria-label="John Doe"
                              aria-describedby="basic-icon-default-fullname2"
                              onChange={kitchen=>setKitchen(kitchen.target.value)} value={kitchen}
                            />

                          </div>
                        </div>   

  

                        <div className="mb-3">
                          <label className="form-label" for="basic-icon-default-fullname">contact</label>
                          <div className="input-group input-group-merge">
                            <span id="basic-icon-default-fullname2" className="input-group-text"
                              ><i className="bx bx-user"></i
                            ></span>
                            <input
                              type="text"
                              name="contact"
                              className={`form-control ${formErrors.contact ? 'is-invalid' : ''}`}
                              id="basic-icon-default-fullname"
                              placeholder="John Doe"
                              aria-label="John Doe"
                              aria-describedby="basic-icon-default-fullname2"
                              onChange={contact=>setContact(contact.target.value)} value={contact}
                            />
                             {formErrors.contact && (
                                <div className="invalid-feedback">{formErrors.contact}</div>
                              )}

                          </div>
                        </div>     









                             

                        




                        <div className="mb-3">
                          <label className="form-label" for="basic-icon-default-fullname">details</label>
                          <div className="input-group input-group-merge">
                            <span id="basic-icon-default-fullname2" className="input-group-text"
                              ><i className="bx bx-user"></i
                            ></span>
                            <input
                              type="text"
                              name="details"
                              className="form-control"
                              id="basic-icon-default-fullname"
                              placeholder="John Doe"
                              aria-label="John Doe"
                              aria-describedby="basic-icon-default-fullname2"
                              onChange={details=>setDetails(details.target.value)} value={details}
                            />

                          </div>
                        </div>     



                    

                        <div className="mb-3">
                          <label className="form-label" for="basic-icon-default-fullname">video</label>
                          <div className="input-group input-group-merge">
                            <span id="basic-icon-default-fullname2" className="input-group-text"
                              ><i className="bx bx-user"></i
                            ></span>
                            <input
                              type="text"
                              name="video"
                              className={`form-control ${formErrors.videos ? 'is-invalid' : ''}`}
                              id="basic-icon-default-fullname"
                              placeholder="John Doe"
                              aria-label="John Doe"
                              aria-describedby="basic-icon-default-fullname2"
                              onChange={videos=>setVideos(videos.target.value)} value={videos}
                            />
                             {formErrors.videos && (
                                <div className="invalid-feedback">{formErrors.videos}</div>
                              )}

                          </div>
                        </div>     












                        
                                               
                     
                        {/* <div className="mb-3">
          <label className="form-label" htmlFor="basic-icon-default-images">
            videos
          </label>
          <div className="input-group input-group-merge">
            <span className="input-group-text">
              <i className="bx bx-envelope"></i>
            </span>
            <input
              type="file"
              accept="video/*"
              name="video"
              id="basic-icon-default-images"
              className="form-control"
              onChange={handleChange}
            />
            <span id="basic-icon-default-images2" className="input-group-text">
              @example.com
            </span>
          </div>
          
        </div> */}


                        <div className="mb-3">
                          <label className="form-label" for="basic-icon-default-images"> Image</label>
                          <div className="input-group input-group-merge">
                            <span className="input-group-text"><i className="bx bx-envelope"></i></span>
                            <input
                              type="file"
                              name="image"
                              id="basic-icon-default-images"
                              className={`form-control ${formErrors.image ? 'is-invalid' : ''}`}
                              placeholder="john.doe"
                              aria-label="john.doe"
                              aria-describedby="basic-icon-default-images2"
                              onChange={handleChange}

                            />
                             {formErrors.image && (
                                <div className="invalid-feedback">{formErrors.image}</div>
                              )}
                            
                          </div>
                          
                        </div>






                        <button type="submit" onClick={handleSubmit} className="btn btn" style={{ backgroundColor: "rgb(127, 1, 75)", color: "#fff" }} disabled={loading}>
                  {loading ? (
                    <img src={Spinner} alt="spinner" style={{ width:"30px" }} />
                  ) : (
                    'Submit'
                  )}
                </button>
                      </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Footer />
              <div className="content-backdrop fade"></div>
            </div>
          </div>
        </div>
        <div className="layout-overlay layout-menu-toggle"></div>
      </div>

      <div className="buy-now">
        <a
          href="https://themeselection.com/products/sneat-bootstrap-html-admin-template/"
          target="_blank"
          className="btn btn-danger btn-buy-now"
        >
          Upgrade to Pro
        </a>
      </div>
    </React.Fragment>
  );
}

export default LawnAdd;
