import axios from 'axios';
import React, { useState } from 'react';   
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';

function LoginSanctum() {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = {
      email: email,
      password: password
    };
    //console.log(data);

    try {
      const response = await axios.post('https://api.bookmyfunctionhall.com/api/loginsanctum', data);
      const token = response.data.token;
      const usertype = response.data.usertype; 
      const name = response.data.name;
      const userEmail = response.data.email;
      const userid = response.data.id;

      //console.log(response)
      if (token) {
        localStorage.setItem('uid', token);
        localStorage.setItem('usertype', usertype);
        localStorage.setItem('name', name);
        localStorage.setItem('email', userEmail);
        localStorage.setItem('id', userid);

        if (usertype === 'admin') {
          navigate('/dashboard'); 
          toast.success('Admin Login Successfully', { autoClose: 2000 });
        } else if (usertype === 'vendor') {
          // navigate('/vendor');
          navigate('/vendor-dashboard'); 
          
          toast.success('Vendor Login Successfully', { autoClose: 2000 });

        } else {
          //console.error("Invalid usertype:", usertype);
        }
      } else {
        //console.error("Token not found ");
      }
    } catch (error) {
      //console.error(error);
    }

  
  };

  return (
    <React.Fragment>
      

      








      <div id='loginsec'>
      <div className="container " >
  <div className="card">
    <h2>Login Form</h2>
    <form onSubmit={handleSubmit}>
      <label for="Email">Email</label>
      <input type="text" id="Email" placeholder="Enter your Email"  onChange={(e) => setEmail(e.target.value)}
              value={email} required/>

      <label for="password">Password</label>
      <input type="password" id="password" placeholder="Enter your password"  onChange={(e) => setPassword(e.target.value)}
              value={password} required/>

      <button type="submit">Login</button>
      {/* <div className="switch">Don't have an account? <span className='text-primary'><a href='/'> Register here</a> </span></div> */}

      <button type="submit"  className='mt-2'><a href='/registration' style={{ color:"white" }}>New Vendor Registration</a></button>
      {/* <button type="submit" className='mt-2'><a href="/addadmin" style={{ color:"white" }}>Register For Admin</a></button> */}
    </form>
  </div>

  </div>
</div>
    </React.Fragment>
  );
}

export default LoginSanctum;
