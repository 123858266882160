import React, { useEffect,useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../axiosConfig';
import Sidebar from './Sidebar';

function Index() {
  const navigate = useNavigate();
  const token = localStorage.getItem('uid');
  const usertype = localStorage.getItem('usertype');
   const name = localStorage.getItem('name');


   const [image, setImage] = useState(null);



   const email1 = localStorage.getItem('email');
 
 
   function profilefetch() {
    axiosInstance.get('https://api.bookmyfunctionhall.com/api/fetchuser/'+email1)
      .then((res) => {
        //console.log(token);
        const data = res.data;
       // console.log("profile data here",data);
        //console.log(data[0].name);
      
        setImage(data.image);
      })
      .catch((error) => {
        console.error("error here",error);
      });
  }
 
 
 useEffect(()=>{
  profilefetch();
 },[])
  // const email = localStorage.getItem('email');
 // console.log(name);

  useEffect(() => {
    if (!token) {
      navigate('/login');
    } 
    // else if (usertype === 'admin') {
    //   navigate('/dashboard');
    //   window.location.href="/dashboard"
    // } 
    
    
    else if (usertype === 'vendor') {
      navigate('/vendor');
      window.location.href="/vendor"
    } else {
      console.error("Invalid usertype:", usertype);
    }
  }, [token, usertype, navigate]);

  const destroyToken = () => {
    localStorage.removeItem('uid');
    localStorage.removeItem('usertype');
    navigate('/login');
  };

  
  return (
    <React.Fragment>
            <div className="layout-wrapper layout-content-navbar">
      <div className="layout-container">
  
<Sidebar/>



<div className="layout-page">
         
     
     
        

       
      
     </div>


     
      <div className="layout-overlay layout-menu-toggle"></div>
    </div>
   

    <div className="buy-now">
      <a
        href="https://themeselection.com/products/sneat-bootstrap-html-admin-template/"
        target="_blank"
        className="btn btn-danger btn-buy-now"
        >Upgrade to Pro</a
      >
    </div>
    </div>

    </React.Fragment>
  )
}

export default Index